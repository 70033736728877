import styled, { css } from 'styled-components/macro'
import PropTypes from 'prop-types'
import { themeGet } from '@styled-system/theme-get'

import { all, propTypes } from 'styled/lib/system'

const CARET_SIZE = '5px'

const size =
  props =>
  ({ size }) => {
    return css`
      line-height: ${themeGet(`lineHeights.button.${size}`)};
      padding-left: ${themeGet(`space.${size}`)};
      padding-right: calc(
        ${themeGet(`space.${size}`)} + ${themeGet('space.base')}
      );
    `
  }

const Select = styled.select`
  appearance: none;
  border: 1px solid ${themeGet('colors.border')};
  border-radius: ${themeGet('radii.base')};
  font-size: ${themeGet('fontSizes.s')};
  font-weight: ${themeGet('fontWeights.bold')};
  display: block;
  width: ${props => props.width};

  ${size}
  ${all}
`

const SelectContainer = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => props.width};

  &::after {
    border-bottom: ${CARET_SIZE} solid transparent;
    border-left: ${CARET_SIZE} solid transparent;
    border-right: ${CARET_SIZE} solid transparent;
    border-top: ${CARET_SIZE} solid ${themeGet('colors.fg')};
    content: '';
    display: inline-block;
    height: 0;
    pointer-events: none;
    position: absolute;
    right: ${themeGet('space.base')};
    top: calc(50% - ${CARET_SIZE} / 2);
    width: 0;
  }

  ${all}
`

const SelectOption = styled.option`
  ${all}
`

Select.propTypes = {
  ...propTypes,
  size: PropTypes.string,
}

SelectContainer.propTypes = {
  ...propTypes,
  width: PropTypes.string,
}

SelectOption.propTypes = {
  ...propTypes,
}

Select.defaultProps = {
  size: 'base', // NOTE: This needs to match the <Button>!
  width: 'auto',
}

SelectContainer.defaultProps = {
  width: 'auto',
}

Select.Container = SelectContainer
Select.Option = SelectOption

export default Select
