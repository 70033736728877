import React from 'react'
import PropTypes from 'prop-types'

import { CURRENT_USER_QUERY } from 'lib/queries'
import { DefaultProvider } from 'providers'

function CurrentUserProvider(props = {}) {
  return (
    <DefaultProvider
      query={CURRENT_USER_QUERY}
      ErrorComponent={props.ErrorComponent}
      LoadingComponent={props.LoadingComponent}
    >
      {({ data }) => props.children(data)}
    </DefaultProvider>
  )
}

CurrentUserProvider.propTypes = {
  children: PropTypes.func,
  ErrorComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  LoadingComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
}

export default CurrentUserProvider
