import React from 'react'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { GET_SALESFORCE_QUERY } from 'lib/queries'
import { DefaultProvider } from 'providers'
import Loader from 'components/Loader'

function SalesforceProvider(props = {}) {
  return (
    <DefaultProvider
      query={GET_SALESFORCE_QUERY}
      ErrorComponent={props.ErrorComponent}
      LoadingComponent={() => <Loader fullscreen />}
      {...pick(props, ['passThrough', 'variables'])}
    >
      {({ data }) => props.children(data)}
    </DefaultProvider>
  )
}

SalesforceProvider.propTypes = {
  ...omit(DefaultProvider.propTypes, ['query']),
}

SalesforceProvider.defaultProps = {
  variables: {
    redirectUrl: `https://${window.location.host}${window.location.pathname}`,
  },
}

export default SalesforceProvider
