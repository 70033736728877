import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Box from 'styled/Box'
import FormLabel from 'styled/FormLabel'
import StyledTextField from 'styled/TextField'

function TextField(props = {}) {
  const [value, setValue] = useState(props.value || '')
  const showToolbar = props.renderInToolbar && props.renderInToolbar() !== null
  const getTextFieldProps = () => ({
    ...props,
    // We only want to override the `value` here if the `limit`
    // prop has been specified to allow for external control.
    value: props.limit ? value : props.value,
    onChange: handleChange,
    maxLength: props.limit ? String(props.limit) : null,
    'data-testid': 'textField-input',
    renderInToolbar: showToolbar ? true : false,
  })

  function handleChange(event) {
    setValue(event.target.value)
    props.onChange && props.onChange(event)
  }

  if (props.label) {
    return (
      <Box position="relative">
        <FormLabel required={props.required} htmlFor={props.id} mb="s">
          {props.label}
        </FormLabel>
        {showToolbar ? (
          <StyledTextField.Bar>{props.renderInToolbar()}</StyledTextField.Bar>
        ) : null}
        <StyledTextField {...getTextFieldProps()} />
        {props.message && props.message()}
        <Limiter limit={props.limit} value={value} />
      </Box>
    )
  }

  return (
    <Box position="relative">
      {showToolbar ? (
        <StyledTextField.Bar>{props.renderInToolbar()}</StyledTextField.Bar>
      ) : null}
      <StyledTextField {...getTextFieldProps()} />
      {props.message && props.message()}
      <Limiter limit={props.limit} value={value} />
    </Box>
  )
}

function Limiter(props = {}) {
  if (!props.limit) return null
  const count = props.value.length
  return (
    <Box fontSize="s" mt="xs">
      <Box as="span" color={count >= props.limit ? 'error' : 'inherit'}>
        {count}
      </Box>
      /{props.limit} characters
    </Box>
  )
}

Limiter.propTypes = {
  limit: PropTypes.number,
  value: PropTypes.string,
}

TextField.propTypes = {
  error: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  limit: PropTypes.number,
  message: PropTypes.func,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  renderInToolbar: PropTypes.func,
  required: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
}

TextField.defaultProps = {
  required: false,
  type: 'text',
}

export default TextField
