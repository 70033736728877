import gql from 'graphql-tag'

export const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent(
    $subject: String!
    $startDateTime: String!
    $endDateTime: String!
    $contactId: ID
    $description: String!
    $communicationType: String
    $activityResult: String
    $visitType: String
    $confirmationStatus: String
    $whatId: String
  ) {
    createEvent(
      subject: $subject
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      contactId: $contactId
      description: $description
      communicationType: $communicationType
      activityResult: $activityResult
      visitType: $visitType
      confirmationStatus: $confirmationStatus
      whatId: $whatId
    ) {
      message
      data {
        id
        success
        errors
      }
      error {
        code
        text
        status
        message
      }
    }
  }
`

export const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEvent(
    $id: ID!
    $subject: String
    $startDateTime: String
    $endDateTime: String
    $contactId: ID
    $description: String
    $communicationType: String
    $activityResult: String
    $visitType: String
    $confirmationStatus: String
  ) {
    updateEvent(
      id: $id
      subject: $subject
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      contactId: $contactId
      description: $description
      communicationType: $communicationType
      activityResult: $activityResult
      visitType: $visitType
      confirmationStatus: $confirmationStatus
    ) {
      message
      data {
        id
        code
      }
      error {
        code
        text
        status
        message
      }
    }
  }
`
