import styled from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'

import { all, propTypes } from 'styled/lib/system'
import Button from 'styled/Button'

const LinkButton = styled(Button)`
  background-color: transparent;
  border: 0;
  font-weight: ${themeGet('fontWeights.semibold')};
  text-decoration: underline;

  ${all}
`

LinkButton.propTypes = {
  ...propTypes,
}

export default LinkButton
