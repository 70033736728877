import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import get from 'lodash/get'
import omit from 'lodash/omit'
import { useAuth0 } from 'react-auth0rize'
import { isPast, addHours } from 'date-fns'

import routes from 'config/routes'
import {
  CurrentSalesforceUserProvider,
  SalesforceProvider,
  AppSettingsProvider,
} from 'providers'
import MaintenanceScreen from 'screens/MaintenanceScreen'

// Salesforce sessions are set to expire every 4 hours, and this is set at
// the Big Ass Fans organization level.
const SALESFORCE_SESSION_EXPIRATION = 4

const ProtectedRoute = ({ component: Component, redirect, ...rest }) => {
  const { authenticated } = useAuth0()

  const isValidSalesforceSession = data => {
    const salesforceUserId = get(data, 'Salesforce.salesforceUserId')
    const expiryDate = new Date(get(data, 'Salesforce.updatedAt'))
    const salesforceExpiry = addHours(expiryDate, SALESFORCE_SESSION_EXPIRATION)
    if (!salesforceUserId) return false
    if (isPast(salesforceExpiry)) return false
    return true
  }

  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <SalesforceProvider>
            {data =>
              isValidSalesforceSession(data) ? (
                <CurrentSalesforceUserProvider
                  value={{ userId: get(data, 'Salesforce.salesforceUserId') }}
                >
                  <AppSettingsProvider>
                    {({
                      data: {
                        AppSettings: { isUnderMaintenance },
                      },
                    }) =>
                      isUnderMaintenance ? (
                        <MaintenanceScreen />
                      ) : (
                        <Component {...omit(props, ['component'])} />
                      )
                    }
                  </AppSettingsProvider>
                </CurrentSalesforceUserProvider>
              ) : (
                <Redirect to={routes.salesforceAuthentication} />
              )
            }
          </SalesforceProvider>
        ) : (
          <Redirect to={redirect} />
        )
      }
    />
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.any,
  redirect: PropTypes.string,
}

ProtectedRoute.defaultProps = {
  redirect: routes.login,
}

export default ProtectedRoute
