import gql from 'graphql-tag'

export const CREATE_ENGINEERED_APPLICATION_MUTATION = gql`
  mutation CreateEngineeredApplication(
    $account: ID!
    $opportunity: ID!
    $recordTypeId: ID!
    $salesPoC: ID!
    $notesFromSubmitter: String!
    $expediteNeeded: Boolean
    $reasonForExpedite: String
    $neededForCustomerDeadline: String
    $outsideSalesPointOfContact: ID
    $fanGoal: String
  ) {
    createEngineeredApplication(
      account: $account
      opportunity: $opportunity
      recordTypeId: $recordTypeId
      salesPoC: $salesPoC
      notesFromSubmitter: $notesFromSubmitter
      expediteNeeded: $expediteNeeded
      reasonForExpedite: $reasonForExpedite
      neededForCustomerDeadline: $neededForCustomerDeadline
      outsideSalesPointOfContact: $outsideSalesPointOfContact
      fanGoal: $fanGoal
    ) {
      message
      data {
        id
        success
        errors
      }
      error {
        code
        text
        status
        message
      }
    }
  }
`
