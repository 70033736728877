import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import Loader from './Loader'
import StyledImageGrid from 'styled/ImageGrid'

import FlexGrid from 'styled/FlexGrid'
import Box from 'styled/Box'

const cfdData = gql`
  query Version($versionId: ID!) {
    Version(id: $versionId) {
      cfd {
        URLs {
          id
          url
          isArchived
          fileExtension
          fileProps
          timestamp
          lastModified
        }
        internalType
      }
    }
  }
`

const getCFDDataByType = (type, data) => {
  // this is the Version.cfd resolver return order types
  // [cooling, destrat, heating, unitHeating]
  switch (type) {
    case 'cooling':
      return data[0]
    case 'destrat':
      return data[1]
    case 'heating':
      const heating = data.find(d => d.internalType === 'radiantHeat')
      const unitHeating = data.find(d => d.internalType === 'unitHeating')

      const heatingURLs = get(heating, 'URLs', [])
      const unitHeatingURLs = get(unitHeating, 'URLs', []).filter(u => {
        return !u.id.includes('UftByMin_')
      })

      return { ...heating, URLs: [...heatingURLs, ...unitHeatingURLs] }
    default:
      return data[0]
  }
}

// TODO: Add heights for metric
const isSuggested = (image, type) => {
  switch (type) {
    case 'cooling':
      return (
        image.includes('Fine') ||
        image.includes('_z43') ||
        image.includes('_z67')
      )
    case 'destrat':
      return image.includes('destrat')
    case 'heating':
      return (
        image.includes('T_z43') ||
        image.includes('T_z67') ||
        image.includes('intensity')
      )
    default:
      return true
  }
}

const TypedCFDImages = props => {
  const { versionId, type, selectedIds, typeLabel } = props
  const { data, loading, error } = useQuery(cfdData, {
    variables: { versionId },
  })
  if (loading) return <Loader />
  if (error) return <>Error</>

  const { URLs } = getCFDDataByType(type, get(data, 'Version.cfd', []))
  let images = URLs.filter(u => u.fileExtension === 'png').map(u => u.url)

  const cleanImages = imgs =>
    imgs.map(img => img.replace(/c_.*,h_.*,w_.*\//g, ''))
  const _images = cleanImages(images)

  return (
    <>
      <Box as="h2" fontSize="l" fontWeight="semibold" mb="base">
        Suggested {typeLabel}
      </Box>
      <FlexGrid>
        {_images
          .filter(i => isSuggested(i, type))
          .map((image, idx) => (
            <FlexGrid.Item key={idx} flexBasis={`33.33%`} mb="base">
              <StyledImageGrid.Overlay
                active={selectedIds.includes(image)}
                number={
                  selectedIds.includes(image) && selectedIds.indexOf(image) + 1
                }
              >
                <StyledImageGrid.Image
                  src={image}
                  alt={image}
                  onClick={event =>
                    props.handleImageSelection(image, event, type)
                  }
                  height="100%"
                  width="100%"
                />
              </StyledImageGrid.Overlay>
            </FlexGrid.Item>
          ))}
      </FlexGrid>
      <Box as="h2" fontSize="l" fontWeight="semibold" mb="base">
        All {typeLabel}
      </Box>
      <FlexGrid mt="base">
        {_images.map((image, idx) => (
          <FlexGrid.Item key={idx} flexBasis={`33.33%`}>
            <StyledImageGrid.Overlay
              active={selectedIds.includes(image)}
              number={
                selectedIds.includes(image) && selectedIds.indexOf(image) + 1
              }
            >
              <StyledImageGrid.Image
                src={image}
                alt={image}
                onClick={event =>
                  props.handleImageSelection(image, event, type)
                }
                height="100%"
                width="100%"
              />
            </StyledImageGrid.Overlay>
          </FlexGrid.Item>
        ))}
      </FlexGrid>
    </>
  )
}

TypedCFDImages.propTypes = {
  emptyMessage: PropTypes.string,
  handleImageSelection: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  versionId: PropTypes.string,
  type: PropTypes.string,
  selectedIds: PropTypes.array,
  typeLabel: PropTypes.string,
}

TypedCFDImages.defaultProps = {
  showLoader: true,
}

export default TypedCFDImages
