import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

function getValidComfortZones(versionData) {
  const zones = get(versionData, 'Version.data.comfortZones')

  const arrayOfZones = Object.values(zones)
  if (arrayOfZones.length === 0) return false

  const zonesWithMetrics = arrayOfZones.filter(zone => !isEmpty(zone.metrics))
  const allZones = arrayOfZones.map(zone =>
    isEmpty(zone.metrics) ? { ...zone, metrics: false } : zone
  )

  // Gets initialization array for metrics based on height and type
  const zonesByHeight = []
  allZones.forEach(zone => {
    if (get(zone, 'metrics')) {
      if (get(zone, 'metrics.cooling'))
        zonesByHeight.push({
          id: zone.id,
          height: 'standing',
          goal: 'cooling',
          includeSpeed: true,
          includeAirTemp: true,
          coolingCoverage: true,
        })
      else if (get(zone, 'metrics.destrat'))
        zonesByHeight.push({
          id: zone.id,
          height: 'standing',
          goal: 'destrat',
          includeSpeed: true,
          coolingCoverage: true,
          coolingEffect: true,
        })
    }
  })

  return { allZones, zonesWithMetrics, zonesByHeight }
}

export default getValidComfortZones
