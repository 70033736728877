import gql from 'graphql-tag'

export const GET_SITE_VISIT_QUERY = gql`
  query getSiteVisit($id: ID!) {
    getSiteVisit(id: $id) {
      status
      completionDate
      notesFromRep
    }
  }
`
