// https://blueprintjs.com/docs/#icons
// https://github.com/palantir/blueprint/tree/develop/resources/icons/20px

const icons = {
  arrowLeft:
    'M18,9H4.41l4.29-4.29C8.89,4.53,9,4.28,9,4c0-0.55-0.45-1-1-1 C7.72,3,7.47,3.11,7.29,3.29l-6,6C1.11,9.47,1,9.72,1,10c0,0.28,0.11,0.53,0.29,0.71l6,6C7.47,16.89,7.72,17,8,17 c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71L4.41,11H18c0.55,0,1-0.45,1-1C19,9.45,18.55,9,18,9z',
  arrowRight:
    'M18.71,9.29l-6-6C12.53,3.11,12.28,3,12,3c-0.55,0-1,0.45-1,1 c0,0.28,0.11,0.53,0.29,0.71L15.59,9H2c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1h13.59l-4.29,4.29C11.11,15.47,11,15.72,11,16 c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29l6-6C18.89,10.53,19,10.28,19,10C19,9.72,18.89,9.47,18.71,9.29z',
  building:
    'M19,6h-5V1c0-0.55-0.45-1-1-1H1C0.45,0,0,0.45,0,1v18c0,0.55,0.45,1,1,1h4v-6h4 v6h10c0.55,0,1-0.45,1-1V7C20,6.45,19.55,6,19,6z M6,12H2V8h4V12z M6,6H2V2h4V6z M12,12H8V8h4V12z M12,6H8V2h4V6z M18,17h-4v-3h4 V17z M18,12h-4V8h4V12z',
  calendar:
    'M15,5 C15.6,5 16,4.6 16,4 L16,2 C16,1.5 15.6,1 15,1 C14.4,1 14,1.5 14,2 L14,4 C14,4.6 14.4,5 15,5 Z M5,5 C5.6,5 6,4.6 6,4 L6,2 C6,1.5 5.6,1 5,1 C4.4,1 4,1.5 4,2 L4,4 C4,4.6 4.4,5 5,5 Z M18,3 L17,3 L17,4 C17,5.1 16.1,6 15,6 C13.9,6 13,5.1 13,4 L13,3 L7,3 L7,4 C7,5.1 6.1,6 5,6 C3.9,6 3,5.1 3,4 L3,3 L2,3 C1.5,3 1,3.5 1,4 L1,18 C1,18.5 1.5,19 2,19 L18,19 C18.5,19 19,18.5 19,18 L19,4 C19,3.5 18.5,3 18,3 Z M7,17 L3,17 L3,13 L7,13 L7,17 Z M7,12 L3,12 L3,8 L7,8 L7,12 Z M12,17 L8,17 L8,13 L12,13 L12,17 Z M12,12 L8,12 L8,8 L12,8 L12,12 Z M17,17 L13,17 L13,13 L17,13 L17,17 Z M17,12 L13,12 L13,8 L17,8 L17,12 Z',
  chat: 'M19,0H7C6.45,0,6,0.45,6,1v10c0,0.55,0.45,1,1,1h5.59l3.71,3.71 C16.47,15.89,16.72,16,17,16c0.55,0,1-0.45,1-1v-3h1c0.55,0,1-0.45,1-1V1C20,0.45,19.55,0,19,0z M7,13c-1.1,0-2-0.9-2-2V4H1 C0.45,4,0,4.45,0,5v10c0,0.55,0.45,1,1,1h1v3c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29L7.41,16H13c0.55,0,1-0.45,1-1v-0.17 L12.17,13H7z',
  check:
    'M17,4c-0.28,0-0.53,0.11-0.71,0.29L7,13.59l-3.29-3.29C3.53,10.11,3.28,10,3,10 c-0.55,0-1,0.45-1,1c0,0.28,0.11,0.53,0.29,0.71l4,4C6.47,15.89,6.72,16,7,16s0.53-0.11,0.71-0.29l10-10C17.89,5.53,18,5.28,18,5 C18,4.45,17.55,4,17,4z',
  checkCircle:
    'M10,20C4.48,20,0,15.52,0,10S4.48,0,10,0s10,4.48,10,10S15.52,20,10,20z M15,6 c-0.28,0-0.53,0.11-0.71,0.29L8,12.59l-2.29-2.3C5.53,10.11,5.28,10,5,10c-0.55,0-1,0.45-1,1c0,0.28,0.11,0.53,0.29,0.71l3,3 C7.47,14.89,7.72,15,8,15c0.28,0,0.53-0.11,0.71-0.29l7-7C15.89,7.53,16,7.28,16,7C16,6.45,15.55,6,15,6z',
  chevronDown:
    'M16,6c-0.28,0-0.53,0.11-0.71,0.29L10,11.59L4.71,6.29C4.53,6.11,4.28,6,4,6 C3.45,6,3,6.45,3,7c0,0.28,0.11,0.53,0.29,0.71l6,6C9.47,13.89,9.72,14,10,14s0.53-0.11,0.71-0.29l6-6C16.89,7.53,17,7.28,17,7 C17,6.45,16.55,6,16,6z',
  circle:
    'M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0L10,0z M10,18 c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S14.4,18,10,18L10,18z',
  clock:
    'M11,9.59V4c0-0.55-0.45-1-1-1S9,3.45,9,4v6c0,0.28,0.11,0.53,0.29,0.71l3,3 C12.47,13.89,12.72,14,13,14c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71L11,9.59z M10,0C4.48,0,0,4.48,0,10 c0,5.52,4.48,10,10,10s10-4.48,10-10C20,4.48,15.52,0,10,0z M10,18c-4.42,0-8-3.58-8-8s3.58-8,8-8s8,3.58,8,8S14.42,18,10,18z',
  cloud:
    'M15,7c-0.12,0-0.24,0.03-0.36,0.04C13.83,4.69,11.62,3,9,3C5.69,3,3,5.69,3,9 c0,0.05,0.01,0.09,0.01,0.14C1.28,9.58,0,11.13,0,13c0,2.21,1.79,4,4,4h11c2.76,0,5-2.24,5-5S17.76,7,15,7z',
  column:
    'M5,0H1C0.45,0,0,0.45,0,1v18c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1V1 C6,0.45,5.55,0,5,0z M19.71,9.29l-3-3C16.53,6.11,16.28,6,16,6c-0.55,0-1,0.45-1,1v6c0,0.55,0.45,1,1,1 c0.28,0,0.53-0.11,0.71-0.29l3-3C19.89,10.53,20,10.28,20,10C20,9.72,19.89,9.47,19.71,9.29z M12,0H8C7.45,0,7,0.45,7,1v18 c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1V1C13,0.45,12.55,0,12,0z',
  copy: 'M15,4H1C0.45,4,0,4.45,0,5v14c0,0.55,0.45,1,1,1h14c0.55,0,1-0.45,1-1V5 C16,4.45,15.55,4,15,4z M14,18H2V6h12V18z M19,0H5C4.45,0,4,0.45,4,1v2h2V2h12v12h-1v2h2c0.55,0,1-0.45,1-1V1 C20,0.45,19.55,0,19,0z',
  cross:
    'M11.41,10l4.29-4.29C15.89,5.53,16,5.28,16,5c0-0.55-0.45-1-1-1 c-0.28,0-0.53,0.11-0.71,0.29L10,8.59L5.71,4.29C5.53,4.11,5.28,4,5,4C4.45,4,4,4.45,4,5c0,0.28,0.11,0.53,0.29,0.71L8.59,10 l-4.29,4.29C4.11,14.47,4,14.72,4,15c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29L10,11.41l4.29,4.29 C14.47,15.89,14.72,16,15,16c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71L11.41,10z',
  dashboard:
    'M6,5C5.45,5,5,5.45,5,6c0,0.55,0.45,1,1,1s1-0.45,1-1C7,5.45,6.55,5,6,5z M4,9 c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1s1-0.45,1-1C5,9.45,4.55,9,4,9z M10,5c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1S9,3.45,9,4 C9,4.55,9.45,5,10,5z M10,0C4.48,0,0,4.48,0,10c0,5.52,4.48,10,10,10s10-4.48,10-10C20,4.48,15.52,0,10,0z M10,18 c-4.42,0-8-3.58-8-8s3.58-8,8-8s8,3.58,8,8S14.42,18,10,18z M16,9c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1s1-0.45,1-1 C17,9.45,16.55,9,16,9z M8,14c0,1.1,0.9,2,2,2s2-0.9,2-2c0-0.33-2-8-2-8S8,13.67,8,14z M14,5c-0.55,0-1,0.45-1,1 c0,0.55,0.45,1,1,1s1-0.45,1-1C15,5.45,14.55,5,14,5z',
  disk: 'M14,1h-3v5h3V1z M19.71,3.29l-3-3C16.53,0.11,16.28,0,16,0h-1v7H5V0H1 C0.45,0,0,0.45,0,1v18c0,0.55,0.45,1,1,1h18c0.55,0,1-0.45,1-1V4C20,3.72,19.89,3.47,19.71,3.29z M17,19H3v-8c0-0.55,0.45-1,1-1 h12c0.55,0,1,0.45,1,1V19z',
  document:
    'M11.98,0h-8c-0.55,0-1,0.45-1,1v18c0,0.55,0.45,1,1,1h13c0.55,0,1-0.45,1-1V6 L11.98,0z M15.98,18h-11V2h6v5h5V18z',
  dollar:
    'M15.57,11.19c-0.27-0.51-0.63-0.93-1.07-1.26c-0.44-0.33-0.95-0.6-1.51-0.79 c-0.56-0.2-1.14-0.36-1.72-0.5c-0.6-0.14-1.19-0.26-1.75-0.38C8.95,8.13,8.45,7.99,8.01,7.82c-0.44-0.17-0.8-0.38-1.07-0.63 c-0.27-0.25-0.41-0.59-0.41-1c0-0.33,0.09-0.6,0.28-0.81C7,5.17,7.23,5.02,7.5,4.91C7.77,4.8,8.07,4.73,8.38,4.69 c0.31-0.04,0.58-0.06,0.8-0.06c0.71,0,1.35,0.14,1.9,0.41c0.55,0.27,0.91,0.81,1.06,1.62h3.36c-0.09-0.84-0.32-1.56-0.69-2.16 c-0.37-0.6-0.83-1.08-1.38-1.45c-0.56-0.37-1.18-0.64-1.86-0.81c-0.19-0.05-0.38-0.07-0.57-0.1V1c0-0.55-0.45-1-1-1h-1 c-0.55,0-1,0.45-1,1v1.1c-0.22,0.03-0.43,0.05-0.66,0.1C6.61,2.33,5.95,2.57,5.36,2.91C4.76,3.25,4.27,3.71,3.89,4.26 C3.5,4.82,3.31,5.51,3.31,6.34c0,0.76,0.13,1.41,0.4,1.93c0.26,0.52,0.62,0.95,1.06,1.28c0.44,0.33,0.94,0.6,1.5,0.79 c0.55,0.2,1.13,0.36,1.74,0.5c0.58,0.14,1.16,0.26,1.72,0.38c0.56,0.12,1.07,0.26,1.51,0.43c0.44,0.17,0.8,0.39,1.09,0.66 c0.28,0.27,0.43,0.63,0.45,1.06c0.02,0.43-0.08,0.78-0.3,1.04c-0.22,0.26-0.49,0.47-0.83,0.6c-0.34,0.14-0.7,0.23-1.09,0.28 c-0.39,0.05-0.73,0.07-1.03,0.07c-0.87,0-1.61-0.2-2.23-0.59c-0.62-0.39-0.98-1.08-1.07-2.06H3c0.02,0.9,0.19,1.68,0.52,2.34 c0.33,0.66,0.78,1.21,1.35,1.65c0.57,0.44,1.25,0.77,2.03,0.98c0.35,0.1,0.71,0.16,1.08,0.21V19c0,0.55,0.45,1,1,1h1 c0.55,0,1-0.45,1-1v-1.13c0.25-0.04,0.5-0.07,0.76-0.13c0.77-0.18,1.47-0.46,2.1-0.85c0.63-0.39,1.14-0.9,1.54-1.53 c0.4-0.63,0.59-1.39,0.59-2.29C15.98,12.32,15.84,11.7,15.57,11.19z',
  envelope:
    'M0,4.01v11.91l6.27-6.27L0,4.01z M18.91,2.98H1.09L10,10.97L18.91,2.98z M13.73,9.64L20,15.92V4.01L13.73,9.64z M10.5,12.54L10.5,12.54c-0.13,0.12-0.31,0.19-0.5,0.19s-0.37-0.07-0.5-0.19l0,0 l-2.11-1.89l-6.33,6.33h17.88l-6.33-6.33L10.5,12.54z',
  error:
    'M10,0C4.48,0,0,4.48,0,10c0,5.52,4.48,10,10,10s10-4.48,10-10 C20,4.48,15.52,0,10,0z M11,16H9v-2h2V16z M11,13H9V4h2V13z',
  feed: 'M2.5,15C1.12,15,0,16.12,0,17.5C0,18.88,1.12,20,2.5,20S5,18.88,5,17.5C5,16.12,3.88,15,2.5,15z M3,10c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1c2.76,0,5,2.24,5,5c0,0.55,0.45,1,1,1s1-0.45,1-1C10,13.13,6.87,10,3,10z M3,0C2.45,0,2,0.45,2,1c0,0.55,0.45,1,1,1c8.28,0,15,6.72,15,15c0,0.55,0.45,1,1,1s1-0.45,1-1C20,7.61,12.39,0,3,0z M3,5C2.45,5,2,5.45,2,6c0,0.55,0.45,1,1,1c5.52,0,10,4.48,10,10c0,0.55,0.45,1,1,1s1-0.45,1-1C15,10.37,9.63,5,3,5z',
  filter:
    'M15,2c0-0.55-0.45-1-1-1H1C0.45,1,0,1.45,0,2c0,0.28,0.11,0.53,0.29,0.71L5,7.41 V16c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29l3-3C9.89,13.53,10,13.28,10,13V7.41l4.71-4.71C14.89,2.53,15,2.28,15,2z M11,10 c0,0.55,0.45,1,1,1h7c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1h-7C11.45,9,11,9.45,11,10z M19,17h-7c-0.55,0-1,0.45-1,1 c0,0.55,0.45,1,1,1h7c0.55,0,1-0.45,1-1C20,17.45,19.55,17,19,17z M19,13h-7c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1h7 c0.55,0,1-0.45,1-1C20,13.45,19.55,13,19,13z',
  info: 'M10,0C4.48,0,0,4.48,0,10c0,5.52,4.48,10,10,10s10-4.48,10-10 C20,4.48,15.52,0,10,0z M9,4h2v2H9V4z M13,16H7v-1h2V8H8V7h3v8h2V16z',
  logout:
    'M19.71,9.29l-5-5C14.53,4.11,14.28,4,14,4c-0.55,0-1,0.45-1,1 c0,0.28,0.11,0.53,0.29,0.71L16.59,9H6c-0.55,0-1,0.45-1,1s0.45,1,1,1h10.59l-3.29,3.29C13.11,14.47,13,14.72,13,15 c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29l5-5C19.89,10.53,20,10.28,20,10C20,9.72,19.89,9.47,19.71,9.29z M9,18H2V2h7 c0.55,0,1-0.45,1-1S9.55,0,9,0H1C0.45,0,0,0.45,0,1v18c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1S9.55,18,9,18z',
  more: 'M5.3,10.4c0,1.5-1.2,2.6-2.6,2.6S0,11.8,0,10.4s1.2-2.6,2.6-2.6S5.3,8.9,5.3,10.4z M17.4,7.7c-1.5,0-2.6,1.2-2.6,2.6 s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S18.8,7.7,17.4,7.7z M10,7.7c-1.5,0-2.6,1.2-2.6,2.6S8.5,13,10,13s2.6-1.2,2.6-2.6S11.5,7.7,10,7.7 z',
  pencil:
    'M4.59,12.59l2.83,2.83l7.65-7.65l-2.83-2.83L4.59,12.59z M2,18l4.41-1.59 l-2.81-2.79L2,18z M16,2c-0.55,0-1.05,0.22-1.41,0.59l-1.65,1.65l2.83,2.83l1.65-1.65C17.78,5.05,18,4.55,18,4C18,2.9,17.1,2,16,2 z',
  person:
    'M19.61,17.91c-0.57-1.32-3.35-2.19-5.19-3.01c-1.85-0.82-1.59-1.31-1.66-1.99 c-0.01-0.09-0.01-0.19-0.02-0.29c0.63-0.56,1.15-1.33,1.49-2.22c0,0,0.02-0.05,0.02-0.06c0.07-0.19,0.13-0.39,0.19-0.6 c0.42-0.09,0.67-0.55,0.76-0.98c0.1-0.17,0.29-0.6,0.25-1.08c-0.06-0.62-0.31-0.91-0.59-1.03c0-0.04,0-0.07,0-0.11 c0-0.79-0.07-1.93-0.22-2.68c-0.03-0.21-0.08-0.42-0.14-0.62c-0.27-0.92-0.84-1.74-1.6-2.32C12.11,0.32,11,0,10.01,0 s-2.1,0.32-2.89,0.92C6.36,1.5,5.79,2.32,5.52,3.24c-0.06,0.2-0.11,0.41-0.14,0.62C5.24,4.61,5.16,5.75,5.16,6.54 c0,0.03,0,0.06,0,0.1c-0.29,0.11-0.55,0.4-0.61,1.04C4.51,8.16,4.7,8.59,4.8,8.76C4.9,9.2,5.15,9.67,5.59,9.74 c0.05,0.21,0.12,0.41,0.19,0.6c0,0.01,0.01,0.03,0.01,0.04L5.8,10.4c0.34,0.91,0.87,1.69,1.52,2.25c0,0.09-0.01,0.18-0.02,0.26 c-0.07,0.68,0.13,1.17-1.72,1.99s-4.62,1.69-5.19,3.01C-0.18,19.23,0.05,20,0.05,20h19.9C19.95,20,20.18,19.23,19.61,17.91z',
  phone:
    'M19.91,15.51c-0.08-0.08-4.21-2.5-4.35-2.57c-0.14-0.07-0.27-0.1-0.4-0.1 c-0.19,0-0.42,0.13-0.71,0.4c-0.28,0.27-1.17,1.49-1.43,1.76c-0.26,0.27-0.48,0.4-0.65,0.4c-0.08,0-0.19-0.02-0.32-0.07 c-0.13-0.05-1.45-0.73-4.2-3.15c-2.75-2.42-3.11-4-3.13-4.44c0-0.17,0.13-0.39,0.4-0.65C5.4,6.84,5.69,6.58,6.01,6.35 c0.32-0.24,0.61-0.5,0.88-0.78c0.27-0.28,0.4-0.52,0.4-0.71c0-0.13-0.03-0.27-0.1-0.4C7.12,4.32,4.62,0.19,4.53,0.1 C4.34-0.08,3.61,0,3.24,0.2C0.25,1.82,0,4,0.05,4.86c0.05,0.89,0.61,5.58,5.2,9.93c5.7,5.41,9.66,5.2,9.92,5.2 c0.87,0,3.52-0.48,4.65-3.19C19.98,16.42,20.13,15.73,19.91,15.51z',
  plus: 'M16,9h-5V4c0-0.55-0.45-1-1-1S9,3.45,9,4v5H4c-0.55,0-1,0.45-1,1 c0,0.55,0.45,1,1,1h5v5c0,0.55,0.45,1,1,1s1-0.45,1-1v-5h5c0.55,0,1-0.45,1-1C17,9.45,16.55,9,16,9z',
  pulse:
    'M19,10h-2.38l-1.72-3.45l-0.01,0C14.72,6.23,14.39,6,14,6c-0.43,0-0.79,0.28-0.93,0.66l-0.01,0l-2.75,7.57L7.98,1.82 l-0.02,0C7.88,1.36,7.49,1,7,1C6.56,1,6.2,1.29,6.06,1.69l-0.01,0L3.28,10H1c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1h3 c0.44,0,0.8-0.29,0.94-0.69l0.01,0l1.78-5.34l2.29,12.21l0.02,0C9.12,18.64,9.51,19,10,19c0.43,0,0.79-0.28,0.93-0.66l0.01,0 l3.21-8.82l0.96,1.92l0.01,0C15.28,11.77,15.61,12,16,12h3c0.55,0,1-0.45,1-1C20,10.45,19.55,10,19,10z',
  refresh:
    'M19,1c-0.55,0-1,0.45-1,1v2.06C16.18,1.61,13.29,0,10,0C4.48,0,0,4.48,0,10 c0,0.55,0.45,1,1,1s1-0.45,1-1c0-4.42,3.58-8,8-8c2.52,0,4.76,1.18,6.22,3H15c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1h4 c0.55,0,1-0.45,1-1V2C20,1.45,19.55,1,19,1z M19,9c-0.55,0-1,0.45-1,1c0,4.42-3.58,8-8,8c-2.52,0-4.76-1.18-6.22-3H5 c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1H1c-0.55,0-1,0.45-1,1v4c0,0.55,0.45,1,1,1s1-0.45,1-1v-2.06C3.82,18.39,6.71,20,10,20 c5.52,0,10-4.48,10-10C20,9.45,19.55,9,19,9z',
  search:
    'M19.56,17.44l-4.94-4.94C15.49,11.21,16,9.67,16,8c0-4.42-3.58-8-8-8S0,3.58,0,8 s3.58,8,8,8c1.67,0,3.21-0.51,4.5-1.38l4.94,4.94C17.71,19.83,18.09,20,18.5,20c0.83,0,1.5-0.67,1.5-1.5 C20,18.09,19.83,17.71,19.56,17.44z M8,14c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S11.31,14,8,14z',
  settings:
    'M4,1c0-0.55-0.45-1-1-1S2,0.45,2,1v5h2V1z M2,19c0,0.55,0.45,1,1,1s1-0.45,1-1 v-6H2V19z M11,1c0-0.55-0.45-1-1-1S9,0.45,9,1v8h2V1z M18,1c0-0.55-0.45-1-1-1s-1,0.45-1,1v3h2V1z M9,19c0,0.55,0.45,1,1,1 s1-0.45,1-1v-3H9V19z M18,5h-2c-0.55,0-1,0.45-1,1v3c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1V6C19,5.45,18.55,5,18,5z M16,19 c0,0.55,0.45,1,1,1s1-0.45,1-1v-8h-2V19z M4,7H2C1.45,7,1,7.45,1,8v3c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1V8C5,7.45,4.55,7,4,7z M11,10H9c-0.55,0-1,0.45-1,1v3c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-3C12,10.45,11.55,10,11,10z',
  status:
    'M15.363961,5.89949494 C15.1080387,5.89949494 14.8521164,5.80186386 14.6568542,5.60660172 L12.5355339,3.48528137 C12.1450096,3.09475708 12.1450096,2.4615921 12.5355339,2.07106781 C12.9260582,1.68054352 13.5592232,1.68054352 13.9497475,2.07106781 L13.9497475,2.07106781 L15.363961,3.48528137 L18.1923882,0.656854249 L18.1923882,0.656854249 C18.5829124,0.266329958 19.2160774,0.266329958 19.6066017,0.656854249 L19.6066017,0.656854249 L19.6066017,0.656854249 C19.997126,1.04737854 19.997126,1.68054352 19.6066017,2.07106781 L16.0710678,5.60660172 C15.8758057,5.80186386 15.6198833,5.89949494 15.363961,5.89949494 Z M11.7800713,0.157963713 C11.5440096,0.289890269 11.3219875,0.456187114 11.1213203,0.656854249 C10.7333913,1.04478332 10.4739126,1.51252121 10.3428842,2.00721562 C10.2291872,2.00242129 10.1148763,2 10,2 C5.581722,2 2,5.581722 2,10 C2,14.418278 5.581722,18 10,18 C14.418278,18 18,14.418278 18,10 C18,8.96271993 17.802586,7.97154835 17.4432801,7.0620073 C17.4573948,7.04843155 17.471396,7.03470064 17.4852814,7.02081528 L18.9572143,5.54888235 C19.6246377,6.88938345 20,8.40085644 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C10.6075334,0 11.2024131,0.0541771462 11.7800713,0.157963713 Z M11,16 L9,16 L9,14 L11,14 L11,16 Z M11,13 L9,13 L9,4 L11,4 L11,13 Z',
  trash:
    'M17,1h-5c0-0.55-0.45-1-1-1H9C8.45,0,8,0.45,8,1H3C2.45,1,2,1.45,2,2v1h16V2 C18,1.45,17.55,1,17,1z M17.5,4h-15C2.22,4,2,4.22,2,4.5C2,4.78,2.22,5,2.5,5H3v14c0,0.55,0.45,1,1,1h12c0.55,0,1-0.45,1-1V5h0.5 C17.78,5,18,4.78,18,4.5C18,4.22,17.78,4,17.5,4z M7,16c0,0.55-0.45,1-1,1s-1-0.45-1-1V8c0-0.55,0.45-1,1-1s1,0.45,1,1V16z M11,16 c0,0.55-0.45,1-1,1s-1-0.45-1-1V8c0-0.55,0.45-1,1-1s1,0.45,1,1V16z M15,16c0,0.55-0.45,1-1,1s-1-0.45-1-1V8c0-0.55,0.45-1,1-1 s1,0.45,1,1V16z',
}

export default icons
