import React, { Fragment, useEffect } from 'react'

import { useServiceWorker } from 'serviceWorkerProvider'
import { AppProvider } from 'providers'
import ErrorBoundary from 'components/ErrorBoundary'
import Routes from 'components/Routes'
import GlobalStyles from 'styled/GlobalStyles'

function App() {
  const serviceWorker = useServiceWorker()
  useEffect(() => {
    if (serviceWorker.assetsUpdateReady) {
      serviceWorker.updateAssets()
    }
  }, [serviceWorker])

  return (
    <AppProvider>
      <Fragment>
        <GlobalStyles />
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </Fragment>
    </AppProvider>
  )
}

export default App
