const isEnv = env => {
  if (env === 'test') return process.env.NODE_ENV === env
  return process.env.REACT_APP_ENV === env
}

const dev = isEnv('development')
const test = isEnv('test')
const staging = isEnv('staging')
const prod = isEnv('production')

export { dev, test, staging, prod }
