import SalesforceDataProtection from 'components/SalesforceDataProtection'
import { LIST_SITE_VISTITS_QUERY } from 'lib/queries'
import { omit, pick } from 'lodash'
import React from 'react'
import DefaultProvider from './DefaultProvider'

function SiteVisitProvider(props = {}) {
  return (
    <DefaultProvider
      query={LIST_SITE_VISTITS_QUERY}
      ErrorComponent={props.ErrorComponent}
      LoadingComponent={props.LoadingComponent}
      {...pick(props, ['passThrough', 'variables'])}
    >
      {result => (
        <SalesforceDataProtection type="opportunities" result={result}>
          {props.children(result)}
        </SalesforceDataProtection>
      )}
    </DefaultProvider>
  )
}

SiteVisitProvider.propTypes = {
  ...omit(DefaultProvider.propTypes, ['query']),
}

export default SiteVisitProvider
