const suggestedImageData = (units = 'IMPERIAL') => {
  const INCHES_TO_CENTIMETERS = 2.54
  const getUnitsFromInches = inchesValue => {
    if (units === 'IMPERIAL') return `${inchesValue}"`
    const inchesToCentimeters = Math.floor(inchesValue * INCHES_TO_CENTIMETERS)
    return `${inchesToCentimeters}cm`
  }

  return {
    'UftByMin_z43inch.png': {
      title: `${getUnitsFromInches(43)} Overhead Airflow`,
      description: `Airflow at ${getUnitsFromInches(43)} above floor`,
    },
    'UftByMin_z67inch.png': {
      title: `${getUnitsFromInches(67)} Overhead Airflow`,
      description: `Airflow at ${getUnitsFromInches(67)} above floor`,
    },
    'streamlinesFine.png': {
      title: 'Streamline (Fine)',
      description:
        '3D view of air movement (20 streamlines) throughout the space',
    },
    UftByMin_destrat: {
      title: 'Destratification',
      description: 'Destratification airflow at roof level',
    },
  }
}

function getSuggestedCfdImages(cfdImages, units = 'IMPERIAL') {
  const suggestedImageKeys = Object.keys(suggestedImageData(units))
  const images = {
    suggested: [],
    other: [],
  }

  cfdImages.forEach(image => {
    let matchFound = false
    let matchKey = null
    suggestedImageKeys.forEach(key => {
      if (image.url.includes(key)) {
        matchFound = true
        matchKey = key
      }
    })
    matchFound
      ? images.suggested.push({
          ...image,
          ...suggestedImageData(units)[matchKey],
        })
      : images.other.push(image)
  })
  return images
}

export default getSuggestedCfdImages
