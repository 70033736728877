import React from 'react'
import PropTypes from 'prop-types'
import { Query } from '@apollo/react-components'

function DefaultProvider(props = {}) {
  return (
    <Query
      query={props.query}
      variables={props.variables}
      pollInterval={props.pollInterval}
    >
      {({ loading, error, data }) => {
        if (!props.passThrough) {
          if (loading) return <props.LoadingComponent />
          if (error) return <props.ErrorComponent error={error} />
        }

        return props.children({ loading, error, data })
      }}
    </Query>
  )
}

DefaultProvider.propTypes = {
  children: PropTypes.func,
  ErrorComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  LoadingComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  passThrough: PropTypes.bool,
  pollInterval: PropTypes.number,
  query: PropTypes.object.isRequired,
  variables: PropTypes.object,
}

DefaultProvider.defaultProps = {
  ErrorComponent: () => null,
  LoadingComponent: () => null,
  passThrough: false,
  variables: null,
}

export default DefaultProvider
