import gql from 'graphql-tag'

export const ADD_CONTACT_MUTATION = gql`
  mutation addContact($entryId: ID!, $contactId: ID!) {
    contact: addContact(entryId: $entryId, contactId: $contactId) {
      id
    }
  }
`

export const CREATE_CONTACT_MUTATION = gql`
  mutation createContact(
    $firstName: String!
    $lastName: String!
    $email: String!
    $mailingStreet: String!
    $mailingPostalCode: String!
    $mailingState: String!
    $mailingCountry: String!
    $accountId: String!
    $title: String
    $phone: String
  ) {
    contact: createContact(
      firstName: $firstName
      lastName: $lastName
      email: $email
      mailingStreet: $mailingStreet
      mailingPostalCode: $mailingPostalCode
      mailingState: $mailingState
      mailingCountry: $mailingCountry
      accountId: $accountId
      title: $title
      phone: $phone
    ) {
      message
      data {
        id
        success
        errors
      }
      error {
        code
        text
        status
        message
      }
    }
  }
`

export const DELETE_CONTACT_MUTATION = gql`
  mutation deleteContact($entryId: ID!, $contactId: ID!) {
    deleteContact(entryId: $entryId, contactId: $contactId) {
      id
      contactId
      entryId
    }
  }
`
