import { useState } from 'react'
import {
  format,
  subMonths,
  addMonths,
  subWeeks,
  addWeeks,
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isThisMonth,
  isThisWeek,
} from 'date-fns'
import flatten from 'lodash/flatten'

function getDays({
  ctx,
  startDate = startOfWeek(ctx.currentMonth),
  startOnSunday = true,
  dayFormat = 'dddd',
}) {
  const days = []
  const start = startOnSunday ? 1 : 0
  const end = startOnSunday ? 8 : 7
  for (let i = start; i < end; i++) {
    days.push(format(addDays(startDate, i), dayFormat))
  }
  return days
}

const monthView = ctx => ({
  key: 'month',
  title: `${format(ctx.currentMonth, 'MMMM')} ${format(
    ctx.currentMonth,
    'YYYY'
  )}`,
  dayTitle: day => day.substring(0, 3),
  days: () => getDays({ ctx }),
  cells: () => {
    const monthStart = startOfMonth(ctx.currentMonth)
    const monthEnd = endOfMonth(monthStart)
    const startDate = startOfWeek(monthStart)
    const endDate = endOfWeek(monthEnd)
    const rows = []
    let days = []
    // This is so the week starts on Monday
    // If you want Sunday, just do `startDate`.
    let day = addDays(startDate, 1)
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        days.push(day)
        day = addDays(day, 1)
      }
      rows.push(days)
      days = []
    }
    return flatten(rows)
  },
  handlePrev: () => {
    const prevMonth = subMonths(ctx.currentMonth, 1)
    ctx.setCurrentMonth(prevMonth)
  },
  handleNext: () => {
    const nextMonth = addMonths(ctx.currentMonth, 1)
    ctx.setCurrentMonth(nextMonth)
  },
  showToday: !isThisMonth(ctx.currentMonth),
})

const weekView = ctx => ({
  key: 'week',
  title: format(ctx.currentDay, 'MMMM DD, YYYY'),
  dayTitle: day => day,
  days: () =>
    getDays({
      ctx,
      startDate: ctx.currentDay,
      startOnSunday: false,
      dayFormat: 'ddd (DD)',
    }),
  cells: () =>
    getDays({
      ctx,
      startDate: ctx.currentDay,
      startOnSunday: false,
      dayFormat: '',
    }),
  handlePrev: () => {
    const prevWeek = subWeeks(ctx.currentDay, 1)
    ctx.setCurrentDay(prevWeek)
  },
  handleNext: () => {
    const nextWeek = addWeeks(ctx.currentDay, 1)
    ctx.setCurrentDay(nextWeek)
  },
  showToday: !isThisWeek(ctx.currentDay),
})

function useCalendar({ initialDate = new Date(), initialView }) {
  const [view, setView] = useState(initialView)
  const [currentDay, setCurrentDay] = useState(initialDate)
  const [currentMonth, setCurrentMonth] = useState(initialDate)
  const context = {
    currentDay,
    setCurrentDay,
    currentMonth,
    setCurrentMonth,
  }
  const views = {
    month: monthView(context),
    week: weekView(context),
  }
  const currentView = views[view]

  function handleSelectDay(event, day) {
    event.preventDefault()
    setCurrentDay(day)
  }

  function handleSelectToday(event) {
    event.preventDefault()
    setCurrentDay(initialDate)
    setCurrentMonth(initialDate)
  }

  function handlePrev(event) {
    event.preventDefault()
    currentView.handlePrev()
  }

  function handleNext(event) {
    event.preventDefault()
    currentView.handleNext()
  }

  return {
    currentDay,
    currentMonth,
    view,
    setView,
    handleSelectDay,
    handleSelectToday,
    handleNext,
    handlePrev,
    days: currentView.days,
    cells: currentView.cells,
    title: currentView.title,
    dayTitle: currentView.dayTitle,
    showToday: currentView.showToday,
  }
}

export default useCalendar
