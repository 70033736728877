import cloudinary from 'config/cloudinary'

function getCloudinaryImageUrl(
  image,
  defaultSizes = {
    width: 200,
    height: 200,
  }
) {
  const { cropData, cloudinaryId } = image

  // If the image has no cloudinaryId, its a CFD image, we already have the url
  if (!cloudinaryId) return image.url

  if (cropData) {
    return cloudinary.url(cloudinaryId, {
      transformation: [
        {
          width: cropData.width,
          height: cropData.height,
          x: cropData.x,
          y: cropData.y,
          crop: 'crop',
          secure: true,
        },
        {
          height: defaultSizes.height * 2,
          crop: 'limit',
          secure: true,
        },
      ],
    })
  }

  return cloudinary.url(cloudinaryId, {
    width: defaultSizes.width * 2,
    height: defaultSizes.height * 2,
    crop: 'fill',
    secure: true,
  })
}

export default getCloudinaryImageUrl
