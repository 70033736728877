import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { useModalDispatch, hideModal } from 'providers/ModalProvider'
import Box from 'styled/Box'
import FlexGrid from 'styled/FlexGrid'
import Icon from 'components/Icon'
import StyledModal from 'styled/Modal'

function Modal(props = {}) {
  const modalDispatch = useModalDispatch()

  function handleClose(event) {
    event.preventDefault()
    modalDispatch(hideModal())
  }

  return (
    <Fragment>
      <StyledModal.Overlay onClick={handleClose} />
      <StyledModal {...omit(props, ['height'])}>
        <StyledModal.Header>
          <FlexGrid>
            <FlexGrid.Item flex="1">
              <Box as="h2" mb="0">
                {props.title}
              </Box>
            </FlexGrid.Item>
            <FlexGrid.Item>
              <Box as="a" href="#0" onClick={handleClose}>
                <Icon name="cross" color="neutrals.6" size="30" />
              </Box>
            </FlexGrid.Item>
          </FlexGrid>
        </StyledModal.Header>
        <StyledModal.Content {...pick(props, ['height', 'variant'])}>
          {props.children}
        </StyledModal.Content>
        {props.footer ? (
          <StyledModal.Footer>{props.footer(handleClose)}</StyledModal.Footer>
        ) : null}
      </StyledModal>
    </Fragment>
  )
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  footer: PropTypes.func,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['docked']),
  width: PropTypes.string,
}

Modal.defaultProps = {
  width: '600px',
}

export default Modal
