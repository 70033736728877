import styled, { css } from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'
import PropTypes from 'prop-types'

import { all, propTypes } from 'styled/lib/system'

const variant =
  props =>
  ({ active, variant }) => {
    if (variant === 'raised') {
      return css`
        box-shadow: ${themeGet('shadows.base')};
      `
    }

    if (variant === 'flat') {
      if (active) {
        return css`
          border: 1px solid ${themeGet('colors.primary')};
        `
      }

      return css`
        border: 1px solid ${themeGet('colors.border')};
      `
    }
  }

const Card = styled.div`
  background-color: ${themeGet('colors.white')};
  border-radius: ${themeGet('radii.base')};
  padding: ${themeGet('space.base')};

  ${variant}
  ${all}
`

Card.propTypes = {
  ...propTypes,
  active: PropTypes.bool,
  variant: PropTypes.oneOf(['raised', 'flat']),
}

Card.defaultProps = {
  active: false,
  variant: 'raised',
}

export default Card
