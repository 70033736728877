import ComfortZonesModal from 'components/ComfortZonesModal'
import CommentsModal from 'components/CommentsModal'
import CreateContactModal from 'components/CreateContactModal'
import DefaultModal from 'components/DefaultModal'
import DetailedViewModal from 'components/DetailedViewModal'
import EventModal from 'components/EventModal'
import ImagesModal from 'components/ImagesModal'
import InstallInfoModal from 'components/InstallInfoModal'
import ObjectInventoryModal from 'components/ObjectInventoryModal'
import PDFPreviewModal from 'components/PDFPreviewModal'
import SnapshotsModal from 'components/SnapshotsModal'
import WhatsNewModal from 'components/WhatsNewModal'
import ReleaseNotesModal from 'components/ReleaseNotesModal'
import TypedImagesModal from 'components/TypedImagesModal'

const modals = [
  {
    title: 'ComfortZones',
    component: ComfortZonesModal,
  },
  {
    title: 'Comments',
    component: CommentsModal,
  },
  {
    title: 'CreateContact',
    component: CreateContactModal,
  },
  {
    title: 'Default',
    component: DefaultModal,
  },
  {
    title: 'DetailedView',
    component: DetailedViewModal,
  },
  {
    title: 'Event',
    component: EventModal,
  },
  {
    title: 'Images',
    component: ImagesModal,
  },
  {
    title: 'TypedImages',
    component: TypedImagesModal,
  },
  {
    title: 'InstallInfo',
    component: InstallInfoModal,
  },
  {
    title: 'ObjectInventory',
    component: ObjectInventoryModal,
  },
  {
    title: 'PDFPreview',
    component: PDFPreviewModal,
  },
  {
    title: 'Snapshots',
    component: SnapshotsModal,
  },
  {
    title: 'WhatsNew',
    component: WhatsNewModal,
  },
  {
    title: 'ReleaseNotes',
    component: ReleaseNotesModal,
  },
]

export default modals
