import styled, { css } from 'styled-components/macro'
import PropTypes from 'prop-types'
import { themeGet } from '@styled-system/theme-get'

import { all, propTypes } from 'styled/lib/system'

const fixed =
  props =>
  ({ fixed }) => {
    if (fixed) {
      return css`
        bottom: ${themeGet('space.l')};
        left: 50%;
        position: fixed;
        transform: translateX(-50%);
        z-index: ${themeGet('zIndices.alert')};
      `
    }
  }

const type =
  props =>
  ({ type }) => {
    if (type !== 'info') {
      return css`
        path {
          fill: ${themeGet(`colors.${type}`)};
        }
      `
    }

    return css`
      path {
        fill: ${themeGet('colors.border')};
      }
    `
  }

const Alert = styled.div`
  background-color: ${themeGet('colors.black')};
  border-radius: ${themeGet('radii.base')};
  color: ${themeGet('colors.white')};
  font-size: ${themeGet('fontSizes.l')};
  padding: ${themeGet('space.base')};

  ${fixed}
  ${type}
  ${all}
`

Alert.propTypes = {
  ...propTypes,
  fixed: PropTypes.bool,
  type: PropTypes.oneOf(['info', 'warning', 'success', 'error']),
}

export default Alert
