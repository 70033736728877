import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import { parseGraphQLErrorObject } from 'lib/utils'
import Box from 'styled/Box'
import CodeBlock from 'styled/CodeBlock'
import Icon from 'components/Icon'
import StyledErrorMessage from 'styled/ErrorMessage'

function ErrorMessage(props) {
  const [showError, setShowError] = useState(false)
  const errorObject = parseGraphQLErrorObject(props.error)
  const error = errorObject ? errorObject : props.error
  const isDev =
    process.env.REACT_APP_ENV === 'development' ||
    process.env.NODE_ENV === 'development'
  return (
    <StyledErrorMessage>
      <Box as="h3" mb="xs">
        <Icon
          name="info"
          color="primary"
          size="24"
          mr="s"
          position="relative"
          top="-2px"
        />
        An error occurred
      </Box>
      <Box as="p" mb="s">
        {props.message}
      </Box>
      {isDev ? (
        <Fragment>
          {!showError ? (
            <StyledErrorMessage.Button onClick={() => setShowError(true)}>
              Show error message
            </StyledErrorMessage.Button>
          ) : null}
          {showError && (
            <CodeBlock>
              {error.graphQLError && error.graphQLError.map(e => e.message)}
              {error.networkError && error.networkError.map(e => e.message)}
              {error.errors && error.errors.map(e => e.message)}
              {error.message && error.message}
            </CodeBlock>
          )}
        </Fragment>
      ) : null}
    </StyledErrorMessage>
  )
}

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  message: PropTypes.string,
}

ErrorMessage.defaultProps = {
  message: 'There was an error',
}

export default ErrorMessage
