import styled, { css } from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'

import { all, propTypes } from 'styled/lib/system'

const withBar =
  props =>
  ({ renderInToolbar }) => {
    if (renderInToolbar) {
      return css`
        padding-top: ${themeGet('space.xl')(props)};
      `
    }
  }

const error =
  props =>
  ({ error }) => {
    if (error) {
      return css`
        border-color: ${themeGet('colors.error')(props)};
      `
    }
  }

const TextField = styled.input`
  border: 1px solid ${themeGet('colors.border')};
  border-radius: ${themeGet('radii.s')};
  font-size: ${themeGet('fontSizes.base')};
  padding: ${themeGet('space.s')};
  resize: vertical;
  transition: box-shadow 0.2s ease-in-out;
  width: 100%;

  &:focus {
    border-color: ${themeGet('colors.neutrals.5')};
    box-shadow: ${themeGet('shadows.input')};
    outline: none;
  }

  ${withBar}
  ${error}
  ${all}
`

const TextFieldBar = styled.div`
  align-items: center;
  background-color: ${themeGet('colors.white')};
  border-bottom: 1px solid ${themeGet('colors.border')};
  display: flex;
  padding: ${themeGet('space.s')};
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
`

TextField.propTypes = {
  ...propTypes,
}

TextField.Bar = TextFieldBar

export default TextField
