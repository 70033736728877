import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import auth0Client from './auth0Client'

const httpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
})

const authLink = setContext(async context => {
  try {
    const { idToken } = await auth0Client.renewSession()
    return {
      headers: {
        ...context.headers,
        authorization: idToken ? `Bearer ${idToken}` : null,
      },
    }
  } catch (err) {
    return {
      headers: context.headers,
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }
  // TODO: Figure out how to properly refresh the authentication
  // token within the middleware rather than here.
  if (networkError && networkError.statusCode === 401) {
    auth0Client.logout()
  }
})

const middleware = [errorLink, authLink, httpLink]
const link = ApolloLink.from(middleware)

export default new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: true,
    dataIdFromObject: o => {
      if (o.__typename && o.id) return `${o.__typename}:${o.id}`
      return defaultDataIdFromObject(o)
    },
  }),
})
