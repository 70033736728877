import React from 'react'
import PropTypes from 'prop-types'

import { usePDF } from 'lib/hooks'
import ErrorMessage from 'components/ErrorMessage'
import Modal from 'components/Modal'
import Loader from 'components/Loader'
import PreviewPDF from 'styled/PreviewPDF'

function PDFPreviewModal(props = {}) {
  const pdf = usePDF(props.pdf)
  return (
    <Modal
      title="PDF Preview"
      footer={props.footer}
      // height="800px"
      // width="1000px"
      variant="docked"
    >
      {pdf.loading ? (
        <Loader />
      ) : pdf.error ? (
        <ErrorMessage error={pdf.error} />
      ) : (
        <PreviewPDF title="exportPDF" src={pdf.url} />
      )}
    </Modal>
  )
}

PDFPreviewModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  pdf: PropTypes.object,
}

export default PDFPreviewModal
