import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import FlexGrid from 'styled/FlexGrid'
import LinkButton from 'styled/LinkButton'
import Modal from 'components/Modal'
import Snapshots from 'components/Snapshots'

function SnapshotsModal(props = {}) {
  const [selectedSnapshot, setSelectedSnapshot] = useState(null)

  const handleSnapshotSelection = snapshot => event => {
    event.preventDefault()
    const snapshotSelection = {
      id: snapshot.id,
      url: snapshot.data.url,
      naturalHeight: snapshot.data.height,
      naturalWidth: snapshot.data.width,
      aspectRatio: snapshot.data.width / snapshot.data.height,
    }
    setSelectedSnapshot(snapshotSelection)
  }

  const isSnapshotSelected = snapshot => {
    if (!selectedSnapshot) return false
    return selectedSnapshot.id === snapshot.id
  }

  return (
    <Modal
      title="Snapshots"
      variant="docked"
      footer={close => (
        <>
          <Button
            onClick={event => {
              props.handleSnapshotSelection(selectedSnapshot)
              close(event)
            }}
            variant="primary"
          >
            Select snapshot
          </Button>
          <LinkButton onClick={close}>Cancel</LinkButton>
        </>
      )}
    >
      <FlexGrid>
        <Snapshots
          versionId={props.versionId}
          handleSnapshotSelection={handleSnapshotSelection}
          isSnapshotSelected={isSnapshotSelected}
        />
      </FlexGrid>
    </Modal>
  )
}

SnapshotsModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  versionId: PropTypes.string,
  handleSnapshotSelection: PropTypes.func.isRequired,
}

export default SnapshotsModal
