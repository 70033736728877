import React from 'react'
import PropTypes from 'prop-types'

import BlankSlateMessage from 'components/BlankSlateMessage'
import FlexGrid from 'styled/FlexGrid'
import StyledImageGrid from 'styled/ImageGrid'

function SelectableImageGrid(props = {}) {
  return props.images.length !== 0 ? (
    <FlexGrid>
      {props.images.map(image => (
        <FlexGrid.Item key={image.id} flexBasis="33.333333333%" mb="base">
          <StyledImageGrid.Overlay
            active={props.isSelected(image)}
            disabled={!props.isSelected(image) && !props.canAddImage()}
            number={props.isSelected(image) && props.getImageNumber(image)}
          >
            <StyledImageGrid.Image
              src={props.setImageUrl ? props.setImageUrl(image) : image.url}
              alt={image.id}
              onClick={event => props.handleImageSelection(image, event)}
              height="100%"
              width="100%"
            />
          </StyledImageGrid.Overlay>
          {image.title && (
            <StyledImageGrid.Title>{image.title}</StyledImageGrid.Title>
          )}
          {image.description && (
            <StyledImageGrid.Description>
              {image.description}
            </StyledImageGrid.Description>
          )}
        </FlexGrid.Item>
      ))}
    </FlexGrid>
  ) : (
    props.emptyMessage && (
      <BlankSlateMessage textAlign={props.emptyMessageAlignment}>
        {props.emptyMessage}
      </BlankSlateMessage>
    )
  )
}

SelectableImageGrid.propTypes = {
  handleImageSelection: PropTypes.func.isRequired,
  emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  emptyMessageAlignment: PropTypes.oneOf(['left', 'center', 'right']),
  images: PropTypes.arrayOf(PropTypes.object),
  isSelected: PropTypes.func,
  canAddImage: PropTypes.func,
  getImageNumber: PropTypes.func,
  setImageUrl: PropTypes.func,
}

SelectableImageGrid.defaultProps = {
  emptyMessage: 'No images available.',
  emptyMessageAlignment: 'left',
}

export default SelectableImageGrid
