import gql from 'graphql-tag'

export const AUTHORIZE_SALESFORCE_MUTATION = gql`
  mutation authorizeSalesforceClient($code: String!, $redirectUrl: String!) {
    authorizeSalesforceClient(code: $code, redirectUrl: $redirectUrl)
  }
`

export const CREATE_QUOTE_MUTATION = gql`
  mutation createQuote(
    $name: String!
    $versionId: ID
    $opportunityId: String
    $entryId: ID
    $facilityId: ID
    $bundles: [createQuoteBundleInput]
    $discountReasonId: ID
    $discountNotes: String
    $shippingPrice: Float
  ) {
    createQuote(
      name: $name
      versionId: $versionId
      opportunityId: $opportunityId
      entryId: $entryId
      facilityId: $facilityId
      bundles: $bundles
      discountReasonId: $discountReasonId
      discountNotes: $discountNotes
      shippingPrice: $shippingPrice
    ) {
      id
      name
    }
  }
`

export const UPDATE_QUOTE_MUTATION = gql`
  mutation updateQuote(
    $id: ID!
    $name: String
    $opportunityId: String
    $bundles: [createQuoteBundleInput]
    $comments: String
    $discountReasonId: ID
    $discountNotes: String
    $shippingPrice: Float
    $facilityId: ID
  ) {
    updateQuote(
      id: $id
      name: $name
      opportunityId: $opportunityId
      bundles: $bundles
      comments: $comments
      discountReasonId: $discountReasonId
      discountNotes: $discountNotes
      shippingPrice: $shippingPrice
      facilityId: $facilityId
    ) {
      id
      name
    }
  }
`

export const CREATE_NOTE_MUTATION = gql`
  mutation createNote(
    $title: String!
    $parentId: String!
    $body: String!
    $ownerId: String!
  ) {
    createNote(
      title: $title
      parentId: $parentId
      body: $body
      ownerId: $ownerId
    ) {
      message
      data {
        id
        success
        errors
      }
      error {
        code
        text
        status
        message
      }
    }
  }
`
