import * as Sentry from '@sentry/browser'

import { version } from '../../package.json'

function initializeSentry() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV || 'development',
    release: version,
  })
}

export default initializeSentry
