import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'

import Box from 'styled/Box'
import ElegantImage from 'styled/ElegantImage'

function Image(props = {}) {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)
  const node = useRef(null)
  const { src, alt, height, width, bg, ...rest } = props
  const formattedHeight = `${height}px`
  const formattedWidth = `${width}px`
  const placeholder = (
    <Box
      bg={bg}
      height={formattedHeight}
      position="absolute"
      width={formattedWidth}
      data-testid="image-placeholder"
      {...omit(rest, ['onLoaded'])}
    />
  )

  if (error) return placeholder

  return (
    <Box
      position="relative"
      height={props.boxHeight ? props.boxHeight : '100%'}
    >
      {loaded ? null : placeholder}
      <ElegantImage
        ref={node}
        src={src}
        alt={alt}
        height={height}
        width={width}
        onLoad={() => setLoaded(true)}
        onError={() => setError(true)}
        loaded={loaded}
        {...omit(rest, ['borderRadius', 'onLoaded'])}
      />
    </Box>
  )
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  bg: PropTypes.string,
  height: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  boxHeight: PropTypes.string,
}

Image.defaultProps = {
  bg: 'neutrals.6',
}

export default Image
