import gql from 'graphql-tag'

// This is from Salesforce, not our database!
export const LIST_USERS_QUERY = gql`
  query listUsers($queryString: String!) {
    users: query(queryString: $queryString)
  }
`

export const CURRENT_USER_QUERY = gql`
  query currentUser {
    user: currentUser {
      id
      email
      phone
      profile {
        id
        name
        nickname
        picture
      }
      features {
        name
      }
    }
  }
`

export const CURRENT_USER_FEATURES_QUERY = gql`
  query currentUser {
    user: currentUser {
      id
      features {
        name
      }
    }
  }
`

export const GET_USER_QUERY = gql`
  query GetUser($auth0UserId: String, $id: ID) {
    User(auth0UserId: $auth0UserId, id: $id) {
      id
    }
  }
`
