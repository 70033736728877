import styled from 'styled-components/macro'

import { all, propTypes } from 'styled/lib/system'

const Box = styled.div`
  ${all}
`

Box.propTypes = {
  ...propTypes,
}

export default Box
