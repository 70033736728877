import React from 'react'

import StyledButton from 'styled/Button'
import Loader from 'components/Loader'

function Button(props = {}) {
  if (props.loading) {
    return (
      <StyledButton {...props}>
        <Loader name="ball-beat" aria-label="Loading..." color="currentColor" />
      </StyledButton>
    )
  }
  return <StyledButton {...props} />
}

Button.propTypes = {
  ...StyledButton.propTypes,
}

Button.defaultProps = {
  ...StyledButton.defaultProps,
}

export default Button
