import React from 'react'
import PropTypes from 'prop-types'

import cloudinary from 'config/cloudinary'
import { SnapshotsProvider } from 'providers'
import ImageGrid from 'components/ImageGrid'
import Loader from 'components/Loader'

export const getSnapshotUrl = (
  { cropData, cloudinaryId },
  defaultSizes = {
    width: 200,
    height: 200,
  }
) => {
  if (cropData) {
    return cloudinary.url(cloudinaryId, {
      transformation: [
        {
          width: cropData.width,
          height: cropData.height,
          x: cropData.x,
          y: cropData.y,
          crop: 'crop',
          secure: true,
        },
        {
          height: defaultSizes.height * 2,
          crop: 'limit',
          secure: true,
        },
      ],
    })
  }

  return cloudinary.url(cloudinaryId, {
    width: defaultSizes.width * 2,
    height: defaultSizes.height * 2,
    crop: 'fill',
    secure: true,
  })
}

function Snapshots(props = {}) {
  const versionId = props.versionId
  return (
    <SnapshotsProvider
      LoadingComponent={() => <Loader />}
      variables={{ versionId }}
    >
      {({ data: { snapshots } }) => (
        <ImageGrid
          // TODO: clean up and connect
          // canAddImage={props.canAddImage}
          emptyMessage={props.emptyMessage}
          // getImageNumber={props.getImageNumber}
          handleImageSelection={props.handleSnapshotSelection}
          images={snapshots}
          isSelected={props.isSnapshotSelected}
          setImageUrl={image => getSnapshotUrl(image)} // do we need this?
        />
      )}
    </SnapshotsProvider>
  )
}

Snapshots.propTypes = {
  emptyMessage: PropTypes.string,
  handleSnapshotSelection: PropTypes.func.isRequired,
  isSnapshotSelected: PropTypes.func,
  versionId: PropTypes.string,
}

export default Snapshots
