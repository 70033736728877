import gql from 'graphql-tag'

export const UPDATE_SITE_VISIT_MUTATION = gql`
  mutation updateSiteVisit($id: ID!, $status: String) {
    updateSiteVisit(id: $id, status: $status) {
      message
      time
      data {
        id
        code
      }
      error {
        code
        text
        status
        message
        full
      }
    }
  }
`
