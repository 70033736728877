import gql from 'graphql-tag'

export const LIST_CFD_AND_SNAPSHOTS_QUERY = gql`
  query Version($versionId: ID!) {
    Version(id: $versionId) {
      cfd {
        id
        type
        URLs {
          id
          url
          fileExtension
          fileProps
          lastModified
          timestamp
        }
      }
    }
    snapshots: getSnapshots(versionId: $versionId) {
      id
      cloudinaryId
      cropData
      data
      title
      createdAt
    }
  }
`
