import { get, groupBy, isEmpty } from 'lodash'

/*
  (qty of like fan config) [Diameter in ft] [Product]; [Voltage]
  ; [Drop Tube in ft]
  [z purlin kit if structure = z-purlins, else leave blank]

  [Install Level]
  If Lift Needed = True, "BAF Provided Lift" - [Type of Lift] 
  Any install adders (fire relay, structural, permit, out of scope, etc)
*/
function getStandardizedRFPNotes(versionData) {
  if (isEmpty(versionData.products)) return ''

  const isLikeConfig = product => {
    const KEYS = [
      'product.model',
      'size',
      'voltage',
      'tubeLength',
      'structure',
      'adders',
      'level',
      'liftType',
      'fireRelay',
      'fireRelayType',
      'installAdders',
    ]
    return KEYS.map(key => get(product, key))
      .join('-')
      .replace(/\s/g, '-')
  }

  const inchesToFeet = int => {
    const _int = parseInt(int)
    return Math.round(_int / 12)
  }

  const getLiftType = object => {
    const liftType = get(object, 'liftType')
    let lift = ''
    if (liftType) {
      lift += 'BAF Provided Lift - '
      if (liftType.match(/up to 30'/i)) lift += 'Standard Scissor Lift'
      if (liftType.match(/30' to 45'/i)) lift += 'High Scissor Lift'
      if (liftType.match(/special/i)) lift += 'Specialty Lift'
      if (liftType.match(/none/i)) lift = 'Customer Provided Lift'
    }
    return lift
  }

  const getFireRelay = object => {
    if (!get(object, 'fireRelay')) return ''
    if (!get(object, 'fireRelayType')) return ''
    const scope =
      get(object, 'fireRelayType') === 'Within Scope'
        ? 'In Scope'
        : 'Out of Scope'
    return `Fire Relay (${scope});`
  }

  const getInstallAdders = object => {
    if (!get(object, 'installAdders')) return ''
    const installAdders = get(object, 'installAdders')
    return `${installAdders.join(', ')}`
  }

  const getLevel = object => {
    if (!get(object, 'level')) return ''
    const level = get(object, 'level')
    return `${level} Install`
  }

  const getController = object => {
    const controllerType = get(object, 'controllerType')
    const controllerQty = get(object, 'controllerQty')
    if (!controllerType || !controllerQty) return ''
    else if (controllerType === 'Other') {
      const controllerType = get(object, 'controllerType')
      const controllerOther = get(object, 'controllerOther', '')
      return `Controller ${controllerType}: ${controllerOther} QTY: ${controllerQty}`
    }

    return `${controllerType} QTY: ${controllerQty}`
  }

  const getInstallInfo = () => {
    const installObj = get(versionData, 'install')
    const objectToUse = isEmpty(installObj)
      ? get(versionData, 'products[0]')
      : installObj

    let installInfo = ''

    installInfo += `${getLevel(objectToUse)}\n`
    installInfo += `${getInstallAdders(objectToUse)}\n`
    installInfo += `${getLiftType(objectToUse)}\n`
    installInfo += `${getFireRelay(objectToUse)}\n`
    installInfo += `${getController(objectToUse)}\n`

    return installInfo
  }

  const installInfo = getInstallInfo()

  const getDiameter = product => {
    const noDiameter = [/yellow/i, /black/i, /airgo/i, /pivot/i]
    const diameterInInches = [/sweat/i, /aireye/i]
    const productModel = get(product, 'product.model')
    if (noDiameter.some(model => productModel.match(model))) return false
    if (diameterInInches.some(model => productModel.match(model))) {
      return ` ${get(product, 'size')}" `
    }
    return ` ${inchesToFeet(get(product, 'size'))}' `
  }

  const getDropTube = product => {
    const tubeLength = get(product, 'tubeLength')
    const mountingOption = get(product, 'mountingOption')
      ? `Mount: ${get(product, 'mountingOption', '')} `
      : ''

    // Evap coolers don't have drop tube
    // Plus certain floor-only directionals (black jack, airgo, sidek)
    if ([6, 7, 14, 15, 16, 17, 18, 19].includes(get(product, 'productId'))) {
      return ''
    }
    // Directional fans not mounted overhead don't have drop tube
    if (
      get(product, 'isDirectional') &&
      !get(product, 'isDirectionalOverhead', false)
    ) {
      if (!get(product, 'isForcedWallMount', false) && tubeLength > 0) {
        return `; ${tubeLength}" ${mountingOption}`
      }
      return `; ${mountingOption}`
    }
    return `; ${mountingOption}${inchesToFeet(tubeLength)}' tube`
  }

  const getSmallMount = product => {
    const noMount = [/haiku/i, /i6/i, /essence/i]
    const productModel = get(product, 'product.model')
    const directionNotOverhead =
      get(product, 'product.type') === 'DIRECTIONAL' &&
      !get(product, 'isDirectionalOverhead')
    if (noMount.some(model => productModel.match(model))) return false
    if (directionNotOverhead) return false
    if (versionData.beamWidth > 10) return 'Large Mount'
    return 'Small Mount'
  }

  const groupedProducts = groupBy(versionData.products, isLikeConfig)

  const formattedData = Object.keys(groupedProducts).map(product => {
    const _product = groupedProducts[product]
    const firstProductInGroup = _product[0]

    return {
      quantity: _product.length,
      diameter: getDiameter(firstProductInGroup),
      model: get(firstProductInGroup, 'product.model'),
      voltage: get(firstProductInGroup, 'voltage'),
      dropTube: getDropTube(firstProductInGroup),

      adders:
        get(firstProductInGroup, 'adders', []).length > 0
          ? `${get(firstProductInGroup, 'adders').join(', ')}\n`
          : false,
      smallMount: getSmallMount(firstProductInGroup),
    }
  })

  const text = formattedData.map((fd, idx) => {
    let productText = `(${fd.quantity})`
    productText += `${get(fd, 'diameter') || ' '}${fd.model}; ${fd.voltage}\n`
    productText +=
      get(fd, 'smallMount') || get(fd, 'dropTube')
        ? `${get(fd, 'smallMount') || ''}${get(fd, 'dropTube') || ''}\n`
        : ''
    productText += get(fd, 'adders') ? `${get(fd, 'adders')}\n` : ''

    return productText.trim()
  })
  const formattedText = `${text
    .join('\n\n')
    .trim()}\n\n${installInfo.trim()}`.trim()

  return formattedText
}

export default getStandardizedRFPNotes
