import { get, isEmpty } from 'lodash'

/**
 * Parses a facility's versionData object and returns
 * true if all required install info is provided,
 * or false otherwise.
 */
function hasInstallInfo(versionData) {
  // Version data obviously required
  const data = get(versionData, 'Version.data')
  if (!data || isEmpty(data)) return false
  // Must have some number of products
  const products = get(data, 'products')
  if (!products || isEmpty(products)) return false
  // Must have real install specs
  const install = get(data, 'install')
  if (!install || isEmpty(install)) return false
  return true
}

export default hasInstallInfo
