import React from 'react'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { GET_VERSION_DATA_QUERY } from 'lib/queries'
import DefaultProvider from 'providers/DefaultProvider'

function VersionData(props = {}) {
  return (
    <DefaultProvider
      query={GET_VERSION_DATA_QUERY}
      ErrorComponent={props.ErrorComponent}
      LoadingComponent={props.LoadingComponent}
      {...pick(props, ['passThrough', 'variables'])}
    >
      {result => props.children(result)}
    </DefaultProvider>
  )
}

VersionData.propTypes = {
  ...omit(DefaultProvider.propTypes, ['query']),
}

export default VersionData
