import React, { Suspense } from 'react'
import { Router, Route, Switch } from 'react-router-dom'

import routerHistory from 'config/routerHistory'
import routes from 'config/routes'
import * as screens from 'screens'
import Loader from 'components/Loader'
import ProtectedRoute from 'components/ProtectedRoute'

function Routes() {
  return (
    <Router history={routerHistory}>
      <Suspense fallback={<Loader fullscreen />}>
        <Switch>
          <Route path={routes.login} exact component={screens.Login} />
          <Route path={routes.logout} exact component={screens.Logout} />
          <Route path={routes.callback} exact component={screens.Callback} />
          <Route
            path={routes.salesforceAuthentication}
            component={screens.SalesforceAuthenticationScreen}
          />
          <Route
            path={routes.salesforceCallback}
            component={screens.SalesforceCallbackScreen}
          />
          <ProtectedRoute
            path={routes.home}
            exact
            component={screens.Dashboard}
          />
          <ProtectedRoute
            path={routes.calendar}
            exact
            component={screens.Calendar}
          />
          <ProtectedRoute
            path={routes.entries.new}
            exact
            component={screens.NewEntry}
          />
          <ProtectedRoute
            path={routes.entries.show()}
            exact
            component={screens.EntryManager}
          />
          <ProtectedRoute
            path={routes.entries.edit()}
            exact
            component={screens.EditEntry}
          />
          <ProtectedRoute
            path={routes.entries.contacts().edit}
            exact
            component={screens.EditContacts}
          />
          <ProtectedRoute
            path={routes.entries.facilities().new}
            exact
            component={screens.NewFacility}
          />
          <ProtectedRoute
            path={routes.entries.facilities().add}
            exact
            component={screens.AddFacility}
          />
          <ProtectedRoute
            path={routes.entries.quotes().new}
            exact
            component={screens.NewQuote}
          />
          <ProtectedRoute
            path={routes.entries.quotes().edit}
            exact
            component={screens.EditQuote}
          />
          <ProtectedRoute
            path={routes.entries.quotes().success}
            exact
            component={screens.SubmittedQuote}
          />
          <ProtectedRoute
            path={routes.entries.quotes().build}
            component={screens.BuildQuote}
          />
          <ProtectedRoute
            path={routes.entries.closeout().root}
            component={screens.Closeout}
          />
          <Route component={screens.NotFound} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Routes
