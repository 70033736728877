import gql from 'graphql-tag'

export const RELEASE_NOTES_QUERY = gql`
  query tsrReleaseNotes {
    releaseNotes: tsrReleaseNotes {
      id
      htmlContents
      type
      version
      effectiveDate
    }
  }
`
