import { useEffect, useState } from 'react'
import qs from 'qs'

import { isAccountId, isOpportunityId } from 'config/salesforce'

function useEventData(searchProp) {
  const [data, setData] = useState(null)

  useEffect(() => {
    const queryParams = qs.parse(searchProp, {
      ignoreQueryPrefix: true,
    })
    const eventId = queryParams.eventId
    const whatId = queryParams.whatId
    let _data = {}
    if (eventId) {
      _data = { event: { id: eventId } }
    }
    if (whatId) {
      if (isOpportunityId(whatId)) {
        _data = { ..._data, opportunity: { id: whatId } }
      }
      if (isAccountId(whatId)) {
        _data = { ..._data, account: { id: whatId } }
      }
    }
    setData(_data)
  }, [searchProp])

  return { data }
}

export default useEventData
