import { subDays, isAfter } from 'date-fns'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'

import { isOpportunityId } from 'config/salesforce'

function listUpcomingEvents({
  events,
  max = 2,
  opportunityId = null,
  futureOnly = false,
}) {
  if (isEmpty(events) || events.error) return []

  const relevantEvents = events.filter(e => {
    const startFromDate = futureOnly ? new Date() : subDays(new Date(), 21)
    // If we have an opportunity, filter out everything outside that opp
    const oppFilter = isOpportunityId(opportunityId)
      ? opportunityId === e.whatId
      : true
    return oppFilter && isAfter(e.startDateTime, startFromDate)
  })

  const sortedEvents = sortBy(relevantEvents, ['startDateTime'])
  return sortedEvents.splice(0, max)
}

export default listUpcomingEvents
