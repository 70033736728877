import styled from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'

import { all, propTypes } from 'styled/lib/system'

const CodeBlock = styled.pre`
  background-color: ${themeGet('colors.neutrals.8')};
  border-radius: ${themeGet('radii.base')};
  font-family: monospace;
  overflow-x: auto;
  padding: ${themeGet('space.base')};

  ${all}
`

CodeBlock.propTypes = {
  ...propTypes,
}

export default CodeBlock
