import gql from 'graphql-tag'

export const GET_PRIMARY_USES_QUERY = gql`
  query {
    __type(name: "PrimaryUse") {
      primaryUses: enumValues {
        name
      }
    }
  }
`

export const GET_VERSION_DATA_QUERY = gql`
  query Version($versionId: ID!) {
    Version(id: $versionId) {
      data
    }
  }
`

export const GET_PRODUCT_VARIATIONS_QUERY = gql`
  query ProductVariation($ids: [ID]!) {
    ProductVariations(ids: $ids) {
      id
      voltages {
        id
        inputPower
        price {
          id
          price
        }
        mountingOptions {
          id
          tubeLength
          price {
            id
            price
          }
        }
      }
      mountingOptionAdders {
        id
        price {
          id
          price
        }
      }
    }
  }
`

export const GET_MOUNTING_OPTION_ADDERS_QUERY = gql`
  query ProductVariation($ids: [ID]!) {
    ProductVariations(ids: $ids) {
      id
      mountingOptionAdders {
        id
        mountingType
      }
    }
  }
`

export const LIST_USER_FACILITIES_QUERY = gql`
  query listUserFacilities(
    $orderBy: String
    $filter: FacilityFilter
    $limit: Int
    $page: Int
  ) {
    allUserFacilities(
      orderBy: $orderBy
      filter: $filter
      limit: $limit
      page: $page
    ) {
      facilities {
        id
        name
        createdAt
        entryId
      }
      count
    }
  }
`
