import React from 'react'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { LIST_EVENTS_QUERY } from 'lib/queries'
import DefaultProvider from 'providers/DefaultProvider'
import SalesforceDataProtection from 'components/SalesforceDataProtection'

function EventsProvider(props = {}) {
  return (
    <DefaultProvider
      query={LIST_EVENTS_QUERY}
      ErrorComponent={props.ErrorComponent}
      LoadingComponent={props.LoadingComponent}
      {...pick(props, ['passThrough', 'variables'])}
    >
      {result => (
        <SalesforceDataProtection type="events" result={result}>
          {props.children(result)}
        </SalesforceDataProtection>
      )}
    </DefaultProvider>
  )
}

EventsProvider.propTypes = {
  ...omit(DefaultProvider.propTypes, ['query']),
}

export default EventsProvider
