import gql from 'graphql-tag'

export const LIST_SNAPSHOTS_QUERY = gql`
  query getSnapshots($versionId: ID) {
    snapshots: getSnapshots(versionId: $versionId) {
      id
      cloudinaryId
      cropData
      data
      title
      createdAt
    }
  }
`
