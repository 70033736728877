import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

const FlagsProviderContext = createContext()

function FlagsProvider(props = {}) {
  const { flags } = props
  const state = useMemo(() => ({ ...flags }), [flags])
  return (
    <FlagsProviderContext.Provider value={state}>
      {props.children}
    </FlagsProviderContext.Provider>
  )
}

// const state = useFlags();
function useFlags() {
  const context = useContext(FlagsProviderContext)
  if (context === undefined) {
    throw new Error(`useFlags must be used within a FlagsProvider`)
  }
  return context
}

FlagsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  flags: PropTypes.object,
}

export { FlagsProvider as default, useFlags }
