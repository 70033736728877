import React, { useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Modal from './Modal'
import Box from 'styled/Box'
import TypedCFDImages from './TypedCFDImages'
import Button from './Button'
import LinkButton from 'styled/LinkButton'

const TypedImagesModal = props => {
  const { handleImagesSelected } = props

  const type = get(props, 'type')
  const typeLabel = type.charAt(0).toUpperCase() + type.slice(1) + ' Images'

  const [images, setImages] = useState([])

  const findImage = image => images.includes(image)

  const handleImageSelection = (image, event) => {
    const foundImage = findImage(image)
    if (foundImage) {
      setImages([...images.filter(i => i !== image)])
    } else {
      setImages([...images, image])
    }
  }

  return (
    <Modal
      title={typeLabel}
      variant="docked"
      footer={close => (
        <>
          <Button
            onClick={e => {
              handleImagesSelected(images, e, type)
              close(e)
            }}
            variant="primary"
          >
            Select images
          </Button>
          <LinkButton onClick={close}>Cancel</LinkButton>
        </>
      )}
    >
      <Box mt="m" mb="l">
        <TypedCFDImages
          versionId={props.versionId}
          handleImageSelection={handleImageSelection}
          type={type}
          selectedIds={images}
          typeLabel={typeLabel}
        />
      </Box>
    </Modal>
  )
}

TypedImagesModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  units: PropTypes.oneOf(['IMPERIAL', 'METRIC']),
  versionId: PropTypes.string,
  handleImagesSelected: PropTypes.func.isRequired,
  handleImagesPerPage: PropTypes.func.isRequired,
  imagesPerPage: PropTypes.number.isRequired,
  limit: PropTypes.number,
  selectedImages: PropTypes.array,
}

export default TypedImagesModal
