import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import upperCase from 'lodash/upperCase'

import { useForm, useFormField } from 'lib/hooks'
import { CREATE_CONTACT_MUTATION } from 'lib/mutations'
import { GET_ENTRY_QUERY } from 'lib/queries'
import { useModalDispatch, hideModal } from 'providers/ModalProvider'
import Alert from 'components/Alert'
import Box from 'styled/Box'
import Button from 'components/Button'
import FlexGrid from 'styled/FlexGrid'
import LinkButton from 'styled/LinkButton'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import TextField from 'components/TextField'

function CreateContactModal(props = {}) {
  const modalDispatch = useModalDispatch()
  const entry = useQuery(GET_ENTRY_QUERY, {
    variables: {
      id: get(props, 'match.params.id'),
    },
  })
  const [createContact, createdContact] = useMutation(CREATE_CONTACT_MUTATION)
  // Will only work for "FirstName LastName" format.
  const [_firstName, _lastName] = props.contact.split(' ')
  const [firstName, handleFirstNameChange] = useFormField(_firstName || '')
  const [lastName, handleLastNameChange] = useFormField(_lastName || '')
  const [email, handleEmailChange] = useFormField('')
  const [mailingStreet, handleMailingStreetChange] = useFormField('')
  const [mailingPostalCode, handleMailingPostalCodeChange] = useFormField('')
  const [mailingState, handleMailingStateChange] = useFormField('')
  const [mailingCountry, handleMailingCountryChange] = useFormField('')
  const [title, handleTitleChange] = useFormField('')
  const [phone, handlePhoneChange] = useFormField('')
  const [errors, setErrors] = useState([])
  const [defaulted, setDefaulted] = useState(false)
  const id = 'create-contact-form'
  let contact = {}
  let newContact = {}
  const { submitting, handleSubmit } = useForm(id, async data => {
    let errors = []
    const mailingState = get(data, 'mailingState')
    const mailingCountry = get(data, 'mailingCountry')

    if (mailingState.length !== 2)
      errors.push(`The Mailing State needs to be a two-letter code.`)
    if (mailingCountry.length !== 2)
      errors.push(`The Mailing Country needs to be a two-letter code.`)

    if (errors.length !== 0) return setErrors(errors)

    const _data = {
      ...data,
      mailingState: upperCase(mailingState),
      mailingCountry: upperCase(mailingCountry),
      accountId: get(entry, 'data.entry.account.data.id'),
    }
    contact = await createContact({
      variables: {
        ..._data,
      },
    })
    props.onCreatedContact &&
      props.onCreatedContact({
        id: get(contact, 'data.contact.data.id'),
        name: `${get(_data, 'firstName')} ${get(_data, 'lastName')}`,
        ..._data,
      })
    modalDispatch(hideModal())
  })

  useEffect(() => {
    if (!entry.loading && entry.data && !defaulted) {
      const _data = get(entry, 'data.entry')
      const account = get(_data, 'account')
      const {
        shippingStreet,
        shippingPostalCode,
        shippingState,
        shippingCountry,
      } = get(account, 'data')

      if (shippingStreet && mailingStreet === '') {
        handleMailingStreetChange({ target: { value: shippingStreet } })
      }
      if (shippingPostalCode && mailingPostalCode === '') {
        handleMailingPostalCodeChange({
          target: { value: shippingPostalCode },
        })
      }
      if (shippingState && mailingState === '') {
        handleMailingStateChange({ target: { value: shippingState } })
      }
      if (shippingCountry && mailingCountry === '') {
        handleMailingCountryChange({ target: { value: shippingCountry } })
      }

      setDefaulted(true)
    }
  }, [
    entry,
    defaulted,
    mailingStreet,
    handleMailingStreetChange,
    mailingPostalCode,
    handleMailingPostalCodeChange,
    mailingState,
    handleMailingStateChange,
    mailingCountry,
    handleMailingCountryChange,
  ])

  const isProcessing =
    contact.loading ||
    entry.loading ||
    newContact.loading ||
    createdContact.loading ||
    submitting

  return (
    <Box as="form" action="" onSubmit={handleSubmit} id={id}>
      <Modal
        title="Create Contact"
        footer={close => (
          <>
            <Button type="submit" variant="primary" disabled={isProcessing}>
              Creat
              {isProcessing ? 'ing' : 'e'} Contact
            </Button>
            <LinkButton onClick={close}>Close</LinkButton>
          </>
        )}
      >
        {entry.loading ? (
          <Loader />
        ) : (
          <>
            {errors.length !== 0 &&
              errors.map((error, idx) => (
                <Alert
                  key={idx}
                  text={error}
                  type="error"
                  autohide={false}
                  fontSize="s"
                  mb="base"
                />
              ))}
            <FlexGrid>
              <FlexGrid.Item flexBasis="50%">
                <TextField
                  label="First Name"
                  name="firstName"
                  id="firstName"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  required
                  mb="base"
                />
              </FlexGrid.Item>
              <FlexGrid.Item flexBasis="50%">
                <TextField
                  label="Last Name"
                  name="lastName"
                  id="lastName"
                  value={lastName}
                  required
                  onChange={handleLastNameChange}
                  mb="base"
                />
              </FlexGrid.Item>
            </FlexGrid>
            <TextField
              label="Email"
              name="email"
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              mb="base"
              required
            />
            <FlexGrid mb="base">
              <FlexGrid.Item flexBasis="50%">
                <TextField
                  label="Mailing Street"
                  name="mailingStreet"
                  type="text"
                  id="mailingStreet"
                  value={mailingStreet}
                  onChange={handleMailingStreetChange}
                  required
                />
              </FlexGrid.Item>
              <FlexGrid.Item flexBasis="50%">
                <TextField
                  label="Mailing Postal Code"
                  name="mailingPostalCode"
                  type="text"
                  id="mailingPostalCode"
                  value={mailingPostalCode}
                  onChange={handleMailingPostalCodeChange}
                  required
                />
              </FlexGrid.Item>
            </FlexGrid>
            <FlexGrid mb="base">
              <FlexGrid.Item flexBasis="50%">
                <TextField
                  label="Mailing State"
                  name="mailingState"
                  type="text"
                  id="mailingState"
                  value={mailingState}
                  onChange={handleMailingStateChange}
                  mb="xs"
                  required
                />
                <Box as="p" color="neutrals.4" fontSize="s">
                  The two-letter state code (e.g. KY).
                </Box>
              </FlexGrid.Item>
              <FlexGrid.Item flexBasis="50%">
                <TextField
                  label="Mailing Country"
                  name="mailingCountry"
                  type="text"
                  id="mailingCountry"
                  value={mailingCountry}
                  onChange={handleMailingCountryChange}
                  mb="xs"
                  required
                />
                <Box as="p" color="neutrals.4" fontSize="s">
                  The two-letter country code (e.g. US).
                </Box>
              </FlexGrid.Item>
            </FlexGrid>
            <TextField
              label="Title"
              name="title"
              id="title"
              value={title}
              onChange={handleTitleChange}
              mb="base"
            />
            <TextField
              label="Phone Number"
              name="phone"
              id="phone"
              type="tel"
              value={phone}
              onChange={handlePhoneChange}
            />
          </>
        )}
      </Modal>
    </Box>
  )
}

CreateContactModal.propTypes = {
  contact: PropTypes.string,
  onCreatedContact: PropTypes.func,
}

export default withRouter(CreateContactModal)
