import { useState } from 'react'

function useForm(formKey, callback) {
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const form = document.forms[formKey]

  function handleSubmit(event) {
    event.preventDefault()
    const formData = new FormData(form)
    const formDataObject = {}

    setSubmitting(true)

    for (let [key, value] of formData.entries()) {
      formDataObject[key] = value
    }

    if (!submitting || !submitted) {
      callback && callback(formDataObject)
      setSubmitted(true)
      setSubmitting(false)
    }
  }

  return { handleSubmit, submitting, submitted }
}

export default useForm
