import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Mutation } from '@apollo/react-components'
import get from 'lodash/get'

import cloudinary from 'config/cloudinary'
import { UPDATE_VERSION_MUTATION } from 'lib/mutations'
import {
  useAlertDispatch,
  showError,
  showSuccess,
} from 'providers/AlertProvider'
import Box from 'styled/Box'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import FlexGrid from 'styled/FlexGrid'
import FormLabel from 'styled/FormLabel'
import Image from 'components/Image'
import LinkButton from 'styled/LinkButton'
import Modal from 'components/Modal'
import Select from 'components/Select'
import TextField from 'components/TextField'

const adderOptions = [
  'BAFWorks™',
  'Controls Options',
  'Condensation Abatement',
  'Z-Purlin Kit',
  'L-Bracket Kit',
  'Wood Frame Kit',
  'LED Light Kit',
  'Miscellaneous Options',
  'No Ass Packaging',
  'SenseME',
  'Smart Sense 365',
  'Static tube',
  'Other',
]

const levels = ['Mechanical', 'Standard']

const liftTypes = [
  `Up to 30' Scissor Lift`,
  `30' to 45' Scissor Lift / High Lift Adder`,
  'Special Lift (Any type of Boom, All Terrain, Atrium, or Scaffolding)',
]

const selectWidth = '200px'

function InstallInfoModal(props = {}) {
  const alertDispatch = useAlertDispatch()
  const [installInfo, setInstallInfo] = useState({})

  const handleInstallSelection = info => {
    const install = {
      adders: get(info, 'adders', []),
      adderOther: get(info, 'adderOther'),
      level: get(info, 'level', ''),
      liftNeeded: get(info, 'liftNeeded', false),
      liftType: get(info, 'liftType', ''),
    }
    setInstallInfo(install)
  }

  return (
    <Mutation
      mutation={UPDATE_VERSION_MUTATION}
      onCompleted={() => {
        alertDispatch(showSuccess('Product updated'))
      }}
      onError={error => alertDispatch(showError(error))}
      refetchQueries={['Version']}
    >
      {updateVersion => {
        function updateProductData() {
          const v = get(props, 'versionData')
          if (!v) return

          // Update all products within that bundle with new install info
          let updatedProducts = {}
          props.products.forEach(p => {
            updatedProducts[p.id] = {
              ...p,
              ...installInfo,
            }
          })

          updateVersion({
            variables: {
              id: get(props, 'versionId'),
              data: {
                ...v,
                products: {
                  ...v.products,
                  ...updatedProducts,
                },
              },
            },
          })
        }

        return (
          <Modal
            title={`Add Install Information – ${props.bundle}`}
            width="800px"
            footer={close => (
              <>
                <LinkButton onClick={close}>Cancel</LinkButton>
                <Button
                  onClick={event => {
                    updateProductData()
                    close(event)
                  }}
                  variant="primary"
                >
                  Save
                </Button>
              </>
            )}
          >
            <InstallInfo
              product={get(props, 'products[0]')}
              handleInstallSelection={handleInstallSelection}
            />
          </Modal>
        )
      }}
    </Mutation>
  )
}

function InstallInfo(props = {}) {
  const { product, handleInstallSelection } = props
  const cloudinaryId = get(product, 'product.cloudinaryId')
  const imageSrc = cloudinary.url(`products/${cloudinaryId}`, {
    width: 60,
    height: 60,
    secure: true,
  })
  const model = get(product, 'product.model')

  const [formValues, setFormValues] = useState({
    adders: get(product, 'adders', []),
    adderOther: get(product, 'adderOther'),
    level: get(product, 'level', ''),
    liftNeeded: get(product, 'liftNeeded', false),
    liftType: get(product, 'liftType') || liftTypes[0],
  })

  useEffect(() => {
    handleInstallSelection(formValues)
  }, [handleInstallSelection, formValues])

  return (
    <FlexGrid gutter="xl">
      <FlexGrid.Item>
        <Box
          as="div"
          border="1px solid"
          borderColor="border"
          borderRadius="base"
          p="s"
        >
          <Image src={imageSrc} alt={model} width="100" />
        </Box>
        <Box as="p" fontSize="l" mt="base">
          {`${Math.round(product.size / 12)}' ${model}`}
        </Box>
        <Box as="p" color="neutrals.4" fontSize="l">
          {product.voltage}
        </Box>
        <Box as="p" color="neutrals.4" fontSize="l">
          {`${Math.round(product.tubeLength / 12)}' Tube`}
        </Box>
      </FlexGrid.Item>
      <FlexGrid.Item>
        <FormLabel mb="s">Install Level</FormLabel>
        <Select
          id="level"
          name="level"
          value={formValues.level}
          width={selectWidth}
          onChange={evt =>
            setFormValues({
              ...formValues,
              level: evt.target.value,
            })
          }
        >
          {levels.map(level => (
            <Select.Option key={level} value={level}>
              {level}
            </Select.Option>
          ))}
        </Select>

        {formValues.level && (
          <Box mb="xs" mt="base">
            <Checkbox
              name="liftNeeded"
              label="Lift Needed"
              value={formValues.liftNeeded}
              checked={formValues.liftNeeded}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  liftNeeded: !formValues.liftNeeded,
                })
              }
            />
          </Box>
        )}

        {formValues.liftNeeded && (
          <>
            <FormLabel mb="s" mt="base">
              Type of Lift
            </FormLabel>
            <Select
              id="liftType"
              name="liftType"
              width={selectWidth}
              value={formValues.liftType}
              onChange={evt =>
                setFormValues({
                  ...formValues,
                  liftType: evt.target.value,
                })
              }
            >
              {liftTypes.map(liftType => (
                <Select.Option key={liftType} value={liftType}>
                  {liftType}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
      </FlexGrid.Item>

      <FlexGrid.Item>
        <FormLabel mb="s">Fan Adders</FormLabel>
        {adderOptions.map((option, idx) => (
          <Box key={option} mb="xs">
            <Checkbox
              name={`${option}-${idx}`}
              label={option}
              value={option}
              checked={formValues.adders.includes(option)}
              onChange={evt => {
                const value = evt.target.value
                const adders = formValues.adders
                const isChecked = adders.includes(value)
                if (isChecked) {
                  setFormValues({
                    ...formValues,
                    adders: adders.filter(adder => adder !== value),
                  })
                } else {
                  adders.push(value)
                  setFormValues({
                    ...formValues,
                    adders,
                  })
                }
              }}
            />
          </Box>
        ))}

        {formValues.adders.includes('Other') && (
          <Box mt="base">
            <TextField
              label="Please specify:"
              id="adderOther"
              name="adderOther"
              value={formValues.adderOther}
              onChange={evt =>
                setFormValues({
                  ...formValues,
                  adderOther: evt.target.value,
                })
              }
            />
          </Box>
        )}
      </FlexGrid.Item>
    </FlexGrid>
  )
}

InstallInfo.propTypes = {
  handleInstallSelection: PropTypes.func,
  product: PropTypes.object.isRequired,
}

InstallInfoModal.propTypes = {
  bundle: PropTypes.string,
  products: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  versionId: PropTypes.string.isRequired,
  versionData: PropTypes.object,
}

export default InstallInfoModal
