const ACCOUNT_PREFIX = '001'
const OPPORTUNITY_PREFIX = '006'

function isAccountId(accId) {
  return accId ? accId.startsWith(ACCOUNT_PREFIX) : false
}

function isOpportunityId(oppId) {
  return oppId ? oppId.startsWith(OPPORTUNITY_PREFIX) : false
}

const activityResultOptions = {
  'Sales Visit': [
    'Visit Canceled',
    'Met with customer',
    `Didn't meet with customer`,
  ],
  Other: [
    'Met with customer',
    'Visit Canceled',
    'Did not meet with customer',
    'Did Not Show',
  ],
}

const visitTypeOptions = {
  'Sales Visit': [
    'Lunch & Learn',
    'Account Nuturing',
    'Facility Survey',
    'Opportunity Assist',
    'No Database Cold Call',
  ],
  'Ride Along': ['Training', 'Partner Support'],
}

export { isAccountId, isOpportunityId, activityResultOptions, visitTypeOptions }
