import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import Button from 'components/Button'
import Box from 'styled/Box'
import LinkButton from 'styled/LinkButton'
import Modal from 'components/Modal'
import MetadataImages from './MetadataImages'
import SuggestedImages from './SuggestedImages'

function ImagesModal(props) {
  const { limit = Infinity } = props
  const [images, setImages] = useState([])
  const findImage = image => images.find(i => i.id === image.id)
  const isImageSelected = image => !!findImage(image)
  const canAddImage = () => images.length < limit
  const getImageNumber = img => images.findIndex(({ id }) => img.id === id) + 1

  const handleImageSelection = (image, event) => {
    const { naturalWidth, naturalHeight } = event.currentTarget
    const url = get(image, 'url') || get(image, 'data.url')
    const isCFDImage = get(image, '__typename', '') === 'CFDUrl'
    const aspectRatio = isCFDImage ? 1.4 : naturalWidth / naturalHeight

    const imageSelection = {
      id: image.id,
      cloudinaryId: image.cloudinaryId,
      url,
      aspectRatio,
    }
    const foundImage = findImage(imageSelection)
    if (foundImage) {
      setImages([...images.filter(i => i.id !== foundImage.id)])
    } else {
      if (canAddImage()) {
        setImages([...images, imageSelection])
      }
    }
  }

  useEffect(
    () =>
      setImages(
        props.selectedImages instanceof Array ? props.selectedImages : []
      ),
    [props.selectedImages]
  )

  useEffect(() => {
    props.handleImagesSelected(images)
  }, [props, images])

  return (
    <Modal
      title="Images"
      variant="docked"
      footer={close => (
        <>
          <Button onClick={close} variant="primary">
            Select images
          </Button>
          <LinkButton onClick={close}>Cancel</LinkButton>
        </>
      )}
    >
      <SuggestedImages
        versionId={props.versionId}
        handleImageSelection={handleImageSelection}
        handleImagesPerPage={props.handleImagesPerPage}
        imagesPerPage={props.imagesPerPage}
        isImageSelected={isImageSelected}
        canAddImage={canAddImage}
        getImageNumber={getImageNumber}
        emptyMessage="You have no images in this facility."
        units={props.units}
      />
      {/* TODO: add Snapshots component here and remove from suggested images "suggested images" box */}
      {/* TODO: add CFDImages component here and remove from suggested images "additional images" box */}
      <Box mb="l">
        <Box as="h2" fontSize="l" fontWeight="semibold" mb="base">
          Metadata Images
        </Box>
        <MetadataImages
          versionId={props.versionId}
          handleImageSelection={handleImageSelection}
          isImageSelected={isImageSelected}
          canAddImage={canAddImage}
          getImageNumber={getImageNumber}
          emptyMessage="You have no metadata images in this facility."
        />
      </Box>
    </Modal>
  )
}

ImagesModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  units: PropTypes.oneOf(['IMPERIAL', 'METRIC']),
  versionId: PropTypes.string,
  handleImagesSelected: PropTypes.func.isRequired,
  handleImagesPerPage: PropTypes.func.isRequired,
  imagesPerPage: PropTypes.number.isRequired,
  limit: PropTypes.number,
  selectedImages: PropTypes.array,
}

export default ImagesModal
