import gql from 'graphql-tag'

export const GET_SALESFORCE_QUERY = gql`
  query GetSalesforceQuery($redirectUrl: String!) {
    Salesforce(redirectUrl: $redirectUrl) {
      authorizationUrl
      salesforceUserId
      updatedAt
    }
  }
`

export const LIST_QUOTES_FOR_FACILITY_QUERY = gql`
  query listQuotesForFacility($facilityId: ID!) {
    quotes: listQuotesForFacility(facilityId: $facilityId) {
      id
      name
      salesforceQuoteId
    }
  }
`

export const GET_ZONE_PRICING_QUERY = gql`
  query getZonePricing($quoteId: ID!, $products: [InstallInfo!]) {
    zonePrices: getZonePricing(quoteId: $quoteId, products: $products) {
      bundleName
      unitPrice
    }
  }
`
