import gql from 'graphql-tag'

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($idToken: String!, $name: String!, $email: String!) {
    createUser(
      authProvider: { auth0: { idToken: $idToken } }
      name: $name
      email: $email
    ) {
      id
    }
  }
`
