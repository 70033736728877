import styled from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'

import { all, propTypes } from 'styled/lib/system'

const Markdown = styled.div`
  ul {
    margin-left: ${themeGet('space.l')};
  }

  li:not(:last-child) {
    margin-bottom: ${themeGet('space.xs')};
  }

  ${all}
`

Markdown.propTypes = {
  ...propTypes,
}

export default Markdown
