import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import flatten from 'lodash/flatten'

import { getMetadataImages } from 'lib/utils'
import { VersionDataProvider } from 'providers'
import SelectableImageGrid from 'components/SelectableImageGrid'
import Loader from 'components/Loader'

function MetadataImages(props = {}) {
  const versionId = props.versionId
  return (
    <VersionDataProvider
      LoadingComponent={() => <Loader />}
      variables={{ versionId }}
    >
      {({ data }) => {
        let images = []
        const allData = get(data, 'Version.data', [])

        // Take out images array from objects in version data
        Object.keys(allData).forEach(layer => {
          const _layer = allData[layer]
          Object.keys(_layer).forEach(object => {
            const _object = _layer[object]
            const _images = getMetadataImages(_object)
            if (_images) images.push(_images)
          })
        })

        images = flatten(images)
        const cleanImages = imgs =>
          imgs.map(img => ({
            ...img,
            url: img.url.replace(/c_.*,h_.*,w_.*\//g, ''),
          }))
        const _images = cleanImages(images)

        return (
          <SelectableImageGrid
            images={_images}
            handleImageSelection={props.handleImageSelection}
            isSelected={props.isImageSelected}
            emptyMessage={props.emptyMessage}
            canAddImage={props.canAddImage}
            getImageNumber={props.getImageNumber}
          />
        )
      }}
    </VersionDataProvider>
  )
}

MetadataImages.propTypes = {
  emptyMessage: PropTypes.string,
  handleImageSelection: PropTypes.func.isRequired,
  isImageSelected: PropTypes.func,
  canAddImage: PropTypes.func,
  getImageNumber: PropTypes.func,
  showLoader: PropTypes.bool,
  versionId: PropTypes.string,
}

MetadataImages.defaultProps = {
  showLoader: true,
}

export default MetadataImages
