import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'

import { all, propTypes } from 'styled/lib/system'

const position =
  props =>
  ({ name }) => {
    // Damn you, 'ball-scale-ripple-multiple'!
    if (name === 'ball-scale-ripple-multiple') {
      return css`
        transform: translateY(0) !important;
        > div {
          left: 0 !important;
          top: 0 !important;
        }
      `
    }
  }

const size =
  props =>
  ({ name, size }) => {
    if (size) {
      return css`
        > div {
          /*
          react-spinkit has styles that are going to override *these*
          overrides unless we use !important #csscascadeyall
        */
          height: ${size} !important;
          width: ${size} !important;
        }
      `
    }
  }

const Loader = styled.div`
  ${position}
  ${size}
  ${all}
`

Loader.propTypes = {
  ...propTypes,
  name: PropTypes.string,
}

export default Loader
