import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

const CurrentSalesforceUserContext = createContext()

function CurrentSalesforceUserProvider(props = {}) {
  const userId = get(props, 'value.userId')
  const state = useMemo(() => ({ userId }), [userId])

  return (
    <CurrentSalesforceUserContext.Provider value={state}>
      {props.children}
    </CurrentSalesforceUserContext.Provider>
  )
}

export function useCurrentSalesforceUser() {
  const context = useContext(CurrentSalesforceUserContext)
  if (context === undefined) {
    throw new Error(
      `useCurrentSalesforceUser must be used within a CurrentSalesforceUserProvider`
    )
  }
  return context
}

CurrentSalesforceUserProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  value: PropTypes.shape({
    userId: PropTypes.string,
  }),
}

export default CurrentSalesforceUserProvider
