/**
 * Builds and returns a facility's SpecLab URL
 */
function getFacilityUrl({ facilityId, floorId, versionId } = {}) {
  let url = process.env.REACT_APP_FLT_URL
  if (facilityId) url += `/facility/${facilityId}`
  if (floorId) url += `/floor/${floorId}`
  if (versionId) url += `/version/${versionId}`
  return url
}

export default getFacilityUrl
