import gql from 'graphql-tag'

export const LIST_CFD_URLS_QUERY = gql`
  query Version($versionId: ID!) {
    Version(id: $versionId) {
      cfd {
        id
        type
        URLs {
          id
          url
          fileExtension
          fileProps
          lastModified
          timestamp
        }
      }
    }
  }
`
