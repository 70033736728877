import { createGlobalStyle } from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'
import Color from 'color'

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body {
    height: 100%;
  }

  body {
    background-color: ${props =>
      props.bg
        ? themeGet(`colors.${props.bg}`)(props)
        : themeGet('colors.bg')(props)};
    color: ${themeGet('colors.fg')};
    font-family: ${themeGet('fontFamily')};
    font-size: ${themeGet('fontSizes.base')};
    line-height: ${themeGet('lineHeights.base')};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${themeGet('fontFamily')};
    line-height: ${themeGet('lineHeights.heading')};
    margin: 0;
    margin-bottom: ${themeGet('space.s')};
  }

  h1 {
    font-size: ${themeGet('fontSizes.h1')};
    font-weight: ${themeGet('fontWeights.normal')};
  }
  h2 {
    font-size: ${themeGet('fontSizes.h2')};
    font-weight: ${themeGet('fontWeights.semibold')};
  }
  h3 {
    font-size: ${themeGet('fontSizes.h3')};
  }
  h4 {
    font-size: ${themeGet('fontSizes.h4')};
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  a {
    color: ${themeGet('colors.fg')};
  }

  a:focus,
  a:hover,
  a:active {
    text-decoration: none;
  }

  ::-moz-selection { background-color: ${props =>
    Color(themeGet('colors.primary')(props)).fade(0.75).toString()}; }
  ::selection { background: ${props =>
    Color(themeGet('colors.primary')(props)).fade(0.75).toString()}; }
`

export default GlobalStyles
