import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

import { all, propTypes } from 'styled/lib/system'

const ElegantImage = styled.img`
  opacity: ${props => (props.loaded ? '1' : '0')};
  overflow: hidden;
  transition: opacity 1s ease-in-out;
  visibility: ${props => (props.loaded ? 'visible' : 'hidden')};

  ${all}
`

ElegantImage.propTypes = {
  ...propTypes,
  loaded: PropTypes.bool,
}

export default ElegantImage
