import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Box from 'styled/Box'
import FlexGrid from 'styled/FlexGrid'
import FormLabel from 'styled/FormLabel'

function Checkbox(props = {}) {
  const [checked, setChecked] = useState(props.checked)

  function handleChange(event) {
    setChecked(!checked)
    props.onChange && props.onChange(event)
  }

  const getInputProps = () => ({
    ...props,
    as: 'input',
    type: 'checkbox',
    id: props.name,
    checked,
    onChange: handleChange,
  })

  if (props.label) {
    return (
      <FlexGrid alignItems="center" gutter="xs">
        <FlexGrid.Item display="flex">
          <Box {...getInputProps()} />
        </FlexGrid.Item>
        <FlexGrid.Item>
          <FormLabel htmlFor={props.name}>{props.label}</FormLabel>
        </FlexGrid.Item>
      </FlexGrid>
    )
  }

  return <Box {...getInputProps()} />
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
}

Checkbox.defaultProps = {
  checked: false,
}

export default Checkbox
