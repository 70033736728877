import React from 'react'
import * as Sentry from '@sentry/browser'

import Button from 'components/Button'
import Icon from 'components/Icon'

function ReportErrorButton(props = {}) {
  function handleClick(event) {
    event.preventDefault()
    Sentry.showReportDialog()
  }

  return (
    <Button variant="danger" onClick={handleClick}>
      <Icon name="chat" mr="s" size="16" />
      Report Feedback
    </Button>
  )
}

export default ReportErrorButton
