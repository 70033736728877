import styled, { css } from 'styled-components/macro'
import PropTypes from 'prop-types'
import { themeGet } from '@styled-system/theme-get'

import { all, propTypes } from 'styled/lib/system'
import Card from 'styled/Card'

const variantModal =
  props =>
  ({ variant }) => {
    if (variant === 'docked') {
      return css`
        border-radius: 0;
        bottom: 0;
        right: 0;
        top: 0;
        width: 50%;
      `
    }

    return css`
      max-height: 90vh;
      top: 5%;
      transform: translateX(-50%);
    `
  }

const variantContent =
  props =>
  ({ variant }) => {
    if (variant === 'docked') {
      return css`
        flex: 1;
      `
    }

    return css`
      max-height: 750px;
    `
  }

const Modal = styled(Card)`
  display: flex;
  flex-direction: column;
  left: 50%;
  padding: 0;
  position: fixed;
  z-index: ${themeGet('zIndices.modal')};

  ${all}
  ${variantModal}
`

const ModalOverlay = styled.div`
  background-color: ${themeGet('colors.black')};
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${themeGet('zIndices.modalOverlay')};

  ${all}
`

const ModalHeader = styled.header`
  border-bottom: 1px solid ${themeGet('colors.border')};
  padding: ${themeGet('space.base')};

  ${all}
`

const ModalContent = styled.div`
  overflow-y: auto;
  padding: ${themeGet('space.l')} ${themeGet('space.base')};

  ${variantContent}
  ${all}
`

const ModalFooter = styled.footer`
  border-top: 1px solid ${themeGet('colors.border')};
  display: flex;
  justify-content: flex-end;
  padding: ${themeGet('space.base')};

  ${all}
`

Modal.propTypes = {
  ...propTypes,
  width: PropTypes.string,
}

ModalOverlay.propTypes = {
  ...propTypes,
}

ModalHeader.propTypes = {
  ...propTypes,
}

ModalContent.propTypes = {
  ...propTypes,
}

ModalFooter.propTypes = {
  ...propTypes,
}

Modal.Overlay = ModalOverlay
Modal.Header = ModalHeader
Modal.Content = ModalContent
Modal.Footer = ModalFooter

export default Modal
