import { useState } from 'react'
import get from 'lodash/get'

function useCloseoutSalesforceItems(items) {
  const [activeItem, setActiveItem] = useState(get(items, '[0]', null))
  const activeItemProps = item =>
    activeItem.title === item.title
      ? {
          borderColor: 'primary',
          boxShadow: 'l',
        }
      : {
          borderColor: 'border',
          opacity: '0.75',
          variant: 'flat',
          disabled: item.disabled,
        }

  const handleItemClick = item => event => {
    event.preventDefault()
    setActiveItem(item)
  }

  return { activeItem, setActiveItem, activeItemProps, handleItemClick }
}

export default useCloseoutSalesforceItems
