import gql from 'graphql-tag'

export const CREATE_OPPORTUNITY_MUTATION = gql`
  mutation createOpportunity(
    $name: String
    $purchaser: String
    $type: String
    $verticalMarket: String
    $segment: String
    $closeDate: String
    $recordType: String
    $stageName: String
    $channel: String
    $accountId: ID
  ) {
    createOpportunity(
      name: $name
      purchaser: $purchaser
      type: $type
      verticalMarket: $verticalMarket
      segment: $segment
      closeDate: $closeDate
      recordType: $recordType
      stageName: $stageName
      channel: $channel
      accountId: $accountId
    ) {
      data {
        id
      }
      error {
        code
        text
        status
        message
      }
    }
  }
`

export const CREATE_OPPORTUNITY_TEAM_MEMBER_MUTATION = gql`
  mutation createOpportunityTeamMember($userId: ID!, $opportunityId: ID!) {
    createOpportunityTeamMember(
      userId: $userId
      opportunityId: $opportunityId
    ) {
      data {
        id
      }
      error {
        code
        text
        status
        message
      }
    }
  }
`

export const UPDATE_OPPORTUNITY_MUTATION = gql`
  mutation updateOpportunity($id: ID!, $campaignId: ID, $layouts: String) {
    updateOpportunity(id: $id, campaignId: $campaignId, layouts: $layouts) {
      data {
        id
      }
      error {
        code
        text
        status
        message
      }
    }
  }
`
