import React, { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import uniq from 'lodash/uniq'

const QuoteProviderStateContext = createContext()
const QuoteProviderDispatchContext = createContext()

function initialState({ entry, quote }) {
  const layout = findLayout(entry, quote)
  return {
    id: get(quote, 'id', null),
    groupedVariations: [],
    bundles: [],
    comments: get(quote, 'comments', ''),
    discountNotes: null,
    discountReasonId: null,
    entryId: get(entry, 'id'),
    fans: [],
    layout: layout,
    name: get(quote, 'name', ''),
    opportunityId: get(entry, 'opportunity.data.id', null),
    shippingPrice: null,
    type: null,
    versionId: get(getVersion(layout, quote), 'id', null),
  }
}

const findLayout = (entry, quote) => {
  const facilities = get(entry, 'facilities')
  if (!facilities) return null

  let foundFacility = null
  for (const facility of facilities) {
    const foundFacilityVersion = getVersion(facility, quote)
    if (foundFacilityVersion) foundFacility = facility
  }

  return foundFacility
}

const getVersion = (layout, quote) => {
  const versions = get(layout, 'floor.versions', [])
  const foundLayoutVersion = versions.find(
    version => get(version, 'quote.id') === get(quote, 'id', null)
  )
  return foundLayoutVersion
}

const actionTypes = {
  update: 'update',
}

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.update: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function QuoteProvider(props = {}) {
  const [state, dispatch] = useReducer(reducer, initialState(props))
  return (
    <QuoteProviderStateContext.Provider value={state}>
      <QuoteProviderDispatchContext.Provider value={dispatch}>
        {props.children}
      </QuoteProviderDispatchContext.Provider>
    </QuoteProviderStateContext.Provider>
  )
}

// const state = useQuoteState();
function useQuoteState() {
  const context = useContext(QuoteProviderStateContext)
  if (context === undefined) {
    throw new Error(`useProviderState must be used within a Provider`)
  }
  return context
}

// const dispatch = useQuoteDispatch();
function useQuoteDispatch() {
  const context = useContext(QuoteProviderDispatchContext)
  if (context === undefined) {
    throw new Error(`useProviderDispatch must be used within a Provider`)
  }
  return context
}

// const [state, dispatch] = useQuote();
function useQuote() {
  const context = [useQuoteState(), useQuoteDispatch()]
  if (context === undefined) {
    throw new Error(`useProvider must be used within a Provider`)
  }
  return context
}

// NOTE: Taken from speclab/quote/selectors.js
const selectors = {
  getGroupedProductVariations: productVariations => {
    if (!productVariations) {
      return {}
    }
    const variations = Object.keys(productVariations).map(
      id => productVariations[id]
    )
    const groupedVariations = groupBy(
      variations,
      v => `${v.size}-${v.tubeLength}-${v.voltage}`
    )
    return groupedVariations
  },

  extractBundles: productVariations => {
    const bundles = {}
    const groupedVariations =
      selectors.getGroupedProductVariations(productVariations)

    Object.keys(groupedVariations).forEach(groupId => {
      const variations = groupedVariations[groupId]
      const bundleNames = uniq(variations.map(v => v.quoteBundle)).filter(
        name => name !== undefined
      )
      if (bundleNames.length > 0) {
        bundles[groupId] = bundleNames.map(b => ({
          name: b,
        }))
      }
    })

    return bundles
  },

  generateLineItems: (
    productVariation,
    quoteBundle = { lineItems: [] },
    updatedBundle = { lineItems: [] }
  ) => {
    const lineItems = []

    // voltage + size
    const voltage = productVariation.voltages.find(
      v => v.id === productVariation.voltageId
    )
    // mountingOption
    const mountingOption = voltage.mountingOptions.find(
      o => o.id === productVariation.mountingOptionId
    )

    if (
      !(
        productVariation.product.model === 'Haiku' &&
        mountingOption.tubeLength <= 32
      )
    ) {
      const voltageLI =
        quoteBundle.lineItems.find(li => li.priceId === voltage.price.id) || {}
      const updatedVoltageLI =
        updatedBundle.lineItems.find(li => li.priceId === voltage.price.id) ||
        {}
      lineItems.push({
        priceId: voltage.price.id,
        salesPrice:
          updatedVoltageLI.salesPrice ||
          voltageLI.salesPrice ||
          voltage.price.price,
        quantity: productVariation.quantity,
      })
    }

    if (mountingOption) {
      const mountingOptionLI =
        quoteBundle.lineItems.find(
          li => li.priceId === mountingOption.price.id
        ) || {}
      const updatedMountingOptionLI =
        updatedBundle.lineItems.find(
          li => li.priceId === mountingOption.price.id
        ) || {}
      lineItems.push({
        priceId: mountingOption.price.id,
        salesPrice:
          updatedMountingOptionLI.salesPrice ||
          mountingOptionLI.salesPrice ||
          mountingOption.price.price,
        quantity: productVariation.quantity,
      })
    }

    // mountingOptionAdder
    const mountingOptionAdder = productVariation.mountingOptionAdders.find(
      o => o.id === productVariation.mountingOptionAdderId
    )
    if (mountingOptionAdder) {
      // NOTE: this is optional
      const mountingOptionAdderLI =
        quoteBundle.lineItems.find(
          li => li.priceId === mountingOptionAdder.price.id
        ) || {}
      const updatedMountingOptionAdderLI =
        updatedBundle.lineItems.find(
          li => li.priceId === mountingOptionAdder.price.id
        ) || {}
      lineItems.push({
        priceId: mountingOptionAdder.price.id,
        salesPrice:
          updatedMountingOptionAdderLI.salesPrice ||
          mountingOptionAdderLI.salesPrice ||
          mountingOptionAdder.price.price,
        quantity: productVariation.quantity,
      })
    }

    return lineItems
  },

  generateQuoteBundles: (
    productVariations,
    quoteBundles = [],
    updatedBundles = []
  ) => {
    const bundles = []

    const includedVariations = Object.keys(productVariations)
      .map(k => productVariations[k])
      .filter(v => v.includedInQuote)
      .filter(v => 'quoteBundle' in v && v.quoteBundle !== '')

    const groupedVariations =
      selectors.getGroupedProductVariations(includedVariations)

    Object.keys(groupedVariations).forEach(groupId => {
      const variations = groupedVariations[groupId]
      const bundledVariations = groupBy(variations, v => v.quoteBundle)

      const mergedVariations = Object.keys(bundledVariations).map(
        bundleName => {
          const bundledProducts = bundledVariations[bundleName]

          return {
            ...bundledProducts[0],
            quantity: bundledProducts.length,
          }
        }
      )

      mergedVariations.forEach(v => {
        const bundle = {
          name: v.quoteBundle,
          lineItems: selectors.generateLineItems(
            v,
            quoteBundles.find(b => v.quoteBundle === b.name),
            updatedBundles.find(b => v.quoteBundle === b.name)
          ),
        }
        bundles.push(bundle)
      })
    })

    return bundles
  },
}

QuoteProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  entry: PropTypes.object,
  quote: PropTypes.object,
}

export {
  QuoteProvider as default,
  useQuote,
  useQuoteState,
  useQuoteDispatch,
  actionTypes,
  selectors,
}
