import React, { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'

const EntriesFilterStateContext = createContext()
const EntriesFilterDispatchContext = createContext()

const orderByOptions = {
  'Updated at': 'updatedAt_DESC',
  'Created at': 'createdAt_DESC',
  'Submitted at': 'submittedAt_DESC',
}

const initialState = {
  orderBy: 'updatedAt_DESC',
  limit: 12,
}

const actionTypes = {
  set: 'set',
}

function entriesFilterReducer(state, action) {
  switch (action.type) {
    case actionTypes.set: {
      const hasStatusKey = action.payload.status || action.payload.status === ''
      if (hasStatusKey) {
        let filter = action.payload
        const newVariables = { ...state, filter }
        if (hasStatusKey && filter.status === '') {
          delete newVariables.filter
        }
        return newVariables
      }

      return {
        ...state,
        ...action.payload,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function EntriesFilterProvider(props = {}) {
  const [state, dispatch] = useReducer(entriesFilterReducer, initialState)
  return (
    <EntriesFilterStateContext.Provider value={state}>
      <EntriesFilterDispatchContext.Provider value={dispatch}>
        {props.children}
      </EntriesFilterDispatchContext.Provider>
    </EntriesFilterStateContext.Provider>
  )
}

const setFilter = ({ key = 'status', value }) => ({
  type: actionTypes.set,
  payload: {
    [key]: value,
  },
})

const setOrderBy = value => ({
  type: actionTypes.set,
  payload: {
    orderBy: value,
  },
})

const setLimit = value => ({
  type: actionTypes.set,
  payload: {
    limit: Number(value),
  },
})

function useEntriesFilterState() {
  const context = useContext(EntriesFilterStateContext)
  if (context === undefined) {
    throw new Error(
      `useEntriesFilterState must be used within a EntriesFilterProvider`
    )
  }
  return context
}

function useEntriesFilterDispatch() {
  const context = useContext(EntriesFilterDispatchContext)
  if (context === undefined) {
    throw new Error(
      `useEntriesFilterDispatch must be used within a EntriesFilterProvider`
    )
  }
  return context
}

function useEntriesFilter() {
  const context = [useEntriesFilterState(), useEntriesFilterDispatch()]
  if (context === undefined) {
    throw new Error(
      `useEntriesFilter must be used within a EntriesFilterProvider`
    )
  }
  return context
}

EntriesFilterProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
}

export {
  EntriesFilterProvider as default,
  useEntriesFilter,
  useEntriesFilterState,
  useEntriesFilterDispatch,
  actionTypes,
  setFilter,
  setOrderBy,
  setLimit,
  orderByOptions,
}
