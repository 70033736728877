import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

import Box from 'styled/Box'
import Card from 'styled/Card'
import FlexGrid from 'styled/FlexGrid'
import Icon from 'components/Icon'
import LinkButton from 'styled/LinkButton'
import ReportErrorButton from 'components/ReportErrorButton'

class ErrorBoundary extends Component {
  state = {
    error: null,
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })

    // We want to automatically send the error and related
    // context to Sentry for bug-tracking.
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })

    Sentry.captureException(error)
  }

  refreshPage = event => {
    event.preventDefault()
    window.location.reload()
  }

  render() {
    const { error } = this.state
    const { children, fallbackComponent } = this.props

    if (error) {
      return !fallbackComponent ? (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          height="100vh"
          width="100vw"
        >
          <Card maxWidth="750px" p="xl">
            <Box as="h1" mb="s">
              <Icon
                name="error"
                color="error"
                size="24"
                mr="s"
                position="relative"
                top="-2px"
              />
              An error occurred
            </Box>
            <Box as="p" fontSize="l" mb="base">
              We apologize for the inconvenience, but an error occurred that
              resulted in the application crashing. We automatically send the
              error you encountered to our error-reporting software for futher
              investigation, but if you have additional details about what you
              were doing when this happened, it will help us out a lot.
            </Box>
            <FlexGrid>
              <FlexGrid.Item>
                <ReportErrorButton />
              </FlexGrid.Item>
              <FlexGrid.Item>
                <LinkButton onClick={this.refreshPage}>
                  Try refreshing the page
                </LinkButton>
              </FlexGrid.Item>
            </FlexGrid>
          </Card>
        </Box>
      ) : (
        fallbackComponent
      )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element,
  fallbackComponent: PropTypes.element,
}

export default ErrorBoundary
