import React from 'react'
import PropTypes from 'prop-types'

import Box from 'styled/Box'
import Card from 'styled/Card'
import FlexGrid from 'styled/FlexGrid'
import Icon from 'components/Icon'

function SelectListCard(props = {}) {
  const last = idx => idx === props.items.length - 1

  const handleItemClick = item => event => {
    event.preventDefault()
    if (item.disabled) return
    item.onClick && item.onClick(event)
  }

  return (
    <Card variant="flat" p="0">
      {props.items.map((item, idx) => (
        <Box
          key={idx}
          as="a"
          href="#0"
          onClick={handleItemClick(item)}
          borderBottom="1px solid"
          borderColor={last(idx) ? 'transparent' : 'border'}
          display="block"
          opacity={item.disabled ? '0.5' : '1'}
          p="base"
          textDecoration="none"
        >
          <FlexGrid alignItems="center">
            <FlexGrid.Item>
              <Icon name={item.active ? 'checkCircle' : 'circle'} size="24" />
            </FlexGrid.Item>
            <FlexGrid.Item flex="1 0 auto">
              <Box as="b" display="block">
                {item.title}
              </Box>
              <Box as="span" color="neutrals.4" fontSize="s">
                {item.description}
              </Box>
            </FlexGrid.Item>
            {item.action && <FlexGrid.Item>{item.action}</FlexGrid.Item>}
          </FlexGrid>
        </Box>
      ))}
    </Card>
  )
}

SelectListCard.propTypes = {
  action: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      // The PDF `additionalImages` returns an array of images once they
      // are selected, so we need `PropTypes.array`, too.
      active: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
      ]),
      description: PropTypes.string,
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      title: PropTypes.string,
    })
  ),
}

export default SelectListCard
