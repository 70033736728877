import styled from 'styled-components/macro'

import { all, propTypes } from 'styled/lib/system'

const Icon = styled.div`
  align-self: center;
  display: inline-flex;
  vertical-align: middle;

  ${all}
`

Icon.propTypes = {
  ...propTypes,
}

export default Icon
