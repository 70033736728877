import cloudinary from 'config/cloudinary'
import get from 'lodash/get'

function getMetadataImages(object) {
  const images = get(object, 'metadata.images')
  if (!images) return false
  const round = val => {
    if (!val) return
    const number = parseInt(val)
    const rounded = Math.floor(number)
    return rounded.toString()
  }
  return images.map(image => ({
    id: image.cloudinaryId,
    url: cloudinary.url(image.cloudinaryId, {
      width: round(image.width),
      height: round(image.height),
      crop: 'fill',
      secure: true,
    }),
    data: {
      ...image,
      width: round(image.width),
      height: round(image.height),
    },
  }))
}

export default getMetadataImages
