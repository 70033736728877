import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'
import find from 'lodash/find'
import get from 'lodash/get'
import pick from 'lodash/pick'

import { getMetadataImages, truncateText } from 'lib/utils'
import Button from 'components/Button'
import Box from 'styled/Box'
import FlexGrid from 'styled/FlexGrid'
import Image from 'components/Image'
import LinkButton from 'styled/LinkButton'
import Modal from 'components/Modal'
import SelectListCard from 'components/SelectListCard'

function ObjectInventoryModal(props = {}) {
  const [objects, setObjects] = useState(props.objects || {})
  const _objects = pick(get(props, 'data.Version.data'), [
    'products',
    'obstructions',
    'utilityBoxes',
  ])
  const products = get(_objects, 'products', {})
  const obstructions = get(_objects, 'obstructions', {})
  const utilityBoxes = get(_objects, 'utilityBoxes', {})

  return (
    <Modal
      title="Object Inventory"
      variant="docked"
      footer={close => (
        <Fragment>
          <Button
            onClick={event => {
              props.handleSave(objects)
              close(event)
            }}
            variant="primary"
          >
            Save objects
          </Button>
          <LinkButton onClick={close}>Cancel</LinkButton>
        </Fragment>
      )}
    >
      <Block
        title="Fans"
        objects={objects}
        setObjects={setObjects}
        items={products}
        keys={{
          title: 'product.model',
          notesTitle: 'metadata.title',
          notesDescription: 'metadata.notes',
        }}
      />
      <Block
        title="Other"
        objects={objects}
        setObjects={setObjects}
        items={obstructions}
        keys={{
          title: 'obstructionType',
          notesTitle: 'metadata.title',
          notesDescription: 'metadata.notes',
        }}
      />
      <Block
        objects={objects}
        setObjects={setObjects}
        items={utilityBoxes}
        keys={{
          title: 'label',
          notesTitle: 'metadata.title',
          notesDescription: 'metadata.notes',
        }}
      />
    </Modal>
  )
}

function Block(props = {}) {
  const items = Object.values(props.items)
  if (items.length === 0) return null

  const title = item => startCase(get(item, `${props.keys.title}`))
  const description = item => {
    const { keys } = props
    const noteTitle = get(item, `${keys.notesTitle}`, 'No title')
    const noteDesc = get(item, `${keys.notesDescription}`, 'No description')
    return `${noteTitle} - ${truncateText(noteDesc, 50)}`
  }
  const findItem = item => Boolean(find(props.objects, { id: item.id }))

  const _items = items.map(item => ({
    title: title(item),
    description: description(item),
    active: findItem(item),
    onClick: event => {
      event.preventDefault()
      const foundItem = findItem(item)
      if (foundItem) {
        props.setObjects({
          ...Object.values(props.objects).filter(o => o.id !== item.id),
        })
      } else {
        props.setObjects({ ...props.objects, [item.id]: item })
      }
    },
    action: <BlockImages item={item} />,
  }))

  return (
    <Box mb="base">
      {props.title ? (
        <Box as="p" fontWeight="semibold" mb="base">
          {props.title}
        </Box>
      ) : null}
      <SelectListCard items={_items} />
    </Box>
  )
}

function BlockImages(props = {}) {
  const MAX_IMAGES_TO_SHOW = 2
  const images = getMetadataImages(props.item)
  if (!images) return null
  const overflowImagesLength = images.length - MAX_IMAGES_TO_SHOW
  const overflowLabel = `+${overflowImagesLength}`
  return (
    <FlexGrid alignItems="center" gutter="xs">
      {images.splice(0, MAX_IMAGES_TO_SHOW).map((image, idx) => (
        <FlexGrid.Item key={image.id}>
          <Image src={image.url} alt="" height="50px" />
        </FlexGrid.Item>
      ))}
      {overflowImagesLength > 0 && (
        <FlexGrid.Item>
          <Box as="p" fontWeight="semibold">
            {overflowLabel}
          </Box>
        </FlexGrid.Item>
      )}
    </FlexGrid>
  )
}

BlockImages.propTypes = {
  item: PropTypes.object,
}

Block.propTypes = {
  children: PropTypes.func,
  items: PropTypes.object,
  keys: PropTypes.shape({
    title: PropTypes.string,
    notesDescription: PropTypes.string,
    notesTitle: PropTypes.string,
  }),
  objects: PropTypes.object,
  setObjects: PropTypes.func,
  title: PropTypes.string,
}

ObjectInventoryModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  data: PropTypes.object,
  handleSave: PropTypes.func,
  objects: PropTypes.object,
}

export default ObjectInventoryModal
