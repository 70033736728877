import { useEffect, useState } from 'react'

const ENDPOINT = '/changelog.json'

async function get() {
  const response = await fetch(ENDPOINT)
  const json = await response.json()
  return json
}

function useChangelog() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  async function fetchData() {
    try {
      const data = await get()
      setData(data)
      setLoading(false)
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return { loading, error, data }
}

export default useChangelog
