import gql from 'graphql-tag'

export const CREATE_TASK_MUTATION = gql`
  mutation createTask(
    $assignedTo: ID!
    $subject: String!
    $whatId: ID
    $dueDate: String
    $description: String
  ) {
    createTask(
      assignedTo: $assignedTo
      subject: $subject
      whatId: $whatId
      dueDate: $dueDate
      description: $description
    ) {
      message
      data {
        id
        success
        errors
      }
      error {
        code
        text
        status
        message
      }
    }
  }
`
