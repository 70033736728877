import styled from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'

import { all, propTypes } from 'styled/lib/system'

const ErrorMessage = styled.div`
  ${all}
`

const ErrorMessageButton = styled.button`
  border: 0;
  font-size: ${themeGet('fontSizes.s')};
  text-decoration: underline;

  ${all}
`

ErrorMessage.propTypes = {
  ...propTypes,
}

ErrorMessageButton.propTypes = {
  ...propTypes,
}

ErrorMessage.Button = ErrorMessageButton

export default ErrorMessage
