const routes = {
  home: '/',
  login: '/login',
  logout: '/logout',
  callback: '/callback',
  calendar: '/calendar',
  entries: {
    new: '/entries/new',
    show: (id = ':id') => `/entries/${id}`,
    edit: (id = ':id') => `/entries/${id}/edit`,
    contacts: ({ entryId = ':id' } = {}) => ({
      edit: `/entries/${entryId}/contacts/edit`,
    }),
    facilities: ({ entryId = ':id', facilityId = ':id' } = {}) => ({
      new: `/entries/${entryId}/facilities/new`,
      edit: `/entries/${entryId}/facilities/${facilityId}/edit`,
      add: `/entries/${entryId}/facilities/add`,
    }),
    quotes: ({
      entryId = ':id',
      quoteId = ':quoteId',
      facilityId = ':facilityId',
    } = {}) => ({
      new: `/entries/${entryId}/quotes/${facilityId}/new`,
      edit: `/entries/${entryId}/quotes/${quoteId}/edit`,
      build: `/entries/${entryId}/quotes/${quoteId}/build`,
      select: `/entries/${entryId}/quotes/${quoteId}/build/select-fans`,
      review: `/entries/${entryId}/quotes/${quoteId}/build/review`,
      success: `/entries/${entryId}/quotes/${quoteId}/build/success`,
    }),
    closeout: (id = ':id') => ({
      root: `/entries/${id}/closeout`,
      salesforce: `/entries/${id}/closeout/salesforce`,
      notes: `/entries/${id}/closeout/notes`,
      pdf: `/entries/${id}/closeout/pdf`,
      review: `/entries/${id}/closeout/review`,
    }),
  },
  salesforceAuthentication: '/salesforce/login',
  salesforceCallback: '/api/callbacks/salesforce/auth',
}

export default routes
