import get from 'lodash/get'

function getAuthData() {
  // TODO: This key should be stored somewhere globally.
  const ls = JSON.parse(localStorage.getItem('BAF:UI:auth'))
  const auth0UserId = get(ls, 'data.idTokenPayload.sub')
  const idToken = get(ls, 'data.idToken')
  const email = get(ls, 'data.idTokenPayload.email')
  const name = get(ls, 'data.idTokenPayload.name') || email
  return {
    auth0UserId,
    idToken,
    name,
    email,
  }
}

export default getAuthData
