import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'
import omit from 'lodash/omit'

import theme from 'config/theme'
import StyledLoader from 'styled/Loader'

function DefaultLoader(props = {}) {
  return (
    <StyledLoader
      as={Spinner}
      name={props.name}
      color={props.color}
      fadeIn={props.fadeIn}
      data-testid="loader"
      {...props}
    />
  )
}

function Loader(props = {}) {
  if (props.fullscreen) {
    return (
      <StyledLoader
        alignItems="center"
        display="flex"
        justifyContent="center"
        height="100vh"
        width="100vw"
        {...omit(props, ['fullscreen'])}
      >
        <DefaultLoader color={props.color} />
      </StyledLoader>
    )
  }

  return <DefaultLoader {...omit(props, ['fullscreen'])} />
}

DefaultLoader.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  fadeIn: PropTypes.string,
}

Loader.propTypes = {
  ...DefaultLoader.propTypes,
  fullscreen: PropTypes.bool,
}

DefaultLoader.defaultProps = {
  color: theme.colors.primary,
  fadeIn: 'quarter',
  name: 'three-bounce',
}

Loader.defaultProps = {
  ...DefaultLoader.defaultProps,
  fullscreen: false,
}

export default Loader
