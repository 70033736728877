import gql from 'graphql-tag'

export const LIST_OPPORTUNITIES_QUERY = gql`
  query listOpportunities($queryString: String!) {
    opportunities: query(queryString: $queryString)
  }
`

export const LIST_SITE_VISTITS_QUERY = gql`
  query listSiteVisits($queryString: String!) {
    siteVisit: query(queryString: $queryString)
  }
`

export const GET_ACCOUNT_QUERY = gql`
  query getAccount($id: ID!) {
    getAccount(id: $id) {
      data {
        id
        name
      }
    }
  }
`

export const GET_OPPORTUNITY_QUERY = gql`
  query getOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      data {
        id
        name
        accountId
      }
    }
  }
`
