import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'

import FlexGrid from 'styled/FlexGrid'

import Button from 'components/Button'
import LinkButton from 'styled/LinkButton'
import Modal from 'components/Modal'
import Box from 'styled/Box'

const DetailedItem = styled.div`
  border: 1px solid;

  border-color: ${props =>
    props.active
      ? themeGet('colors.primary')(props)
      : themeGet('border')(props)};

  border-radius: ${themeGet('radii.base')};
  color: ${themeGet('colors.fg')};
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  padding: ${themeGet('space.base')};
  text-align: center;
  text-decoration: none;
  margin: auto;

  ${props => props.disabled && `opacity: 0.2;`};

  &:active,
  &:hover {
    cursor: pointer;
  }
`

function DetailedViewModal(props = {}) {
  const [currentState, setCurrentState] = useState('')

  useEffect(() => {
    props.handleCurrentState(currentState)
  }, [currentState, props])

  useEffect(() => {
    setCurrentState(props.currentState)
  }, [props.currentState])

  return (
    <Modal
      title={props.title}
      variant="docked"
      footer={close => (
        <>
          <Button onClick={close} variant="primary">
            Save
          </Button>
          <LinkButton onClick={close}>Cancel</LinkButton>
        </>
      )}
    >
      <FlexGrid flexDirection="column">
        <FlexGrid.Item>
          <Box mb="base">
            <p>
              <strong>{props.modalHeader}</strong>
            </p>
          </Box>
        </FlexGrid.Item>
        <FlexGrid.Item>
          <FlexGrid justifyContent="space-evenly">
            <FlexGrid.Item width="320px">
              <DetailedItem
                onClick={() => setCurrentState(props.dataToggle[0])}
                active={currentState === props.dataToggle[0]}
              >
                <h1>{props.leftBoxTitle}</h1>
                <Box fontSize="s">{props.leftBoxContent}</Box>
              </DetailedItem>
            </FlexGrid.Item>
            <FlexGrid.Item width="320px">
              <DetailedItem
                onClick={() => setCurrentState(props.dataToggle[1])}
                active={currentState === props.dataToggle[1]}
              >
                <h1>{props.rightBoxTitle}</h1>
                <Box fontSize="s">{props.rightBoxContent}</Box>
              </DetailedItem>
            </FlexGrid.Item>
          </FlexGrid>
        </FlexGrid.Item>
      </FlexGrid>
    </Modal>
  )
}

DetailedViewModal.propTypes = {
  title: PropTypes.string.isRequired,
  currentState: PropTypes.string.isRequired,
  handleCurrentState: PropTypes.func.isRequired,
  dataToggle: PropTypes.arrayOf(PropTypes.string).isRequired,
  modalHeader: PropTypes.string.isRequired,
  leftBoxTitle: PropTypes.string.isRequired,
  leftBoxContent: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
    .isRequired,
  rightBoxTitle: PropTypes.string.isRequired,
  rightBoxContent: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
    .isRequired,
}

export default DetailedViewModal
