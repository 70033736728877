import gql from 'graphql-tag'

export const LIST_EVENTS_QUERY = gql`
  query listEvents($queryString: String!) {
    events: query(queryString: $queryString)
  }
`

export const GET_COMMUNICATIONS_TYPE = gql`
  query getEvent($eventId: ID!) {
    getEvent(id: $eventId) {
      data {
        id
        callType
        callResult
      }
    }
  }
`
