import { format, isEqual } from 'date-fns'
import filter from 'lodash/filter'

function listEventsForDay(events, day) {
  const formatted = date => format(date, 'MM/DD/YYYY')
  const daysEvents = filter(events, e =>
    isEqual(formatted(new Date(e.startDateTime)), formatted(day))
  )

  if (daysEvents.length === 0) return undefined

  return daysEvents
}

export default listEventsForDay
