import React, { useState } from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import get from 'lodash/get'

import { getValidComfortZones } from 'lib/utils'
import Button from 'components/Button'
import Box from 'styled/Box'
import Checkbox from 'components/Checkbox'
import FlexGrid from 'styled/FlexGrid'
import LinkButton from 'styled/LinkButton'
import Modal from 'components/Modal'
import Select from 'components/Select'

function ComfortZonesModal(props = {}) {
  const { allZones, zonesByHeight } = getValidComfortZones(props.versionData)

  const [metrics, setMetrics] = useState(zonesByHeight)
  const getMetric = id => find(metrics, m => m.id === id)

  const handleCheck = zone => event => {
    const found = getMetric(zone.id)
    if (found) setMetrics(metrics.filter(m => m.id !== zone.id))
    else {
      const _zone = find(zonesByHeight, m => m.id === zone.id)
      setMetrics([
        ...metrics,
        { id: zone.id, height: _zone.height, goal: _zone.goal },
      ])
    }
  }

  const handleSelect = (zoneId, key) => event => {
    const value = event.target.value
    const _zone = getMetric(zoneId)
    const height = key === 'height' ? value : _zone.height
    const goal = key === 'goal' ? value : _zone.goal

    setMetrics([
      ...metrics.filter(m => m.id !== zoneId),
      { id: zoneId, height, goal },
    ])
  }

  const handleCheckMetricsToInclude = (zoneId, key) => event => {
    const zone = getMetric(zoneId)
    setMetrics(
      metrics.map(metric => {
        if (metric.id === zoneId) {
          return { ...zone, [key]: !zone[key] }
        }
        return metric
      })
    )
  }

  const showPerformanceMetricsInstructions = allZones.some(z => !z.metrics)
  return (
    <Modal
      title={props.title}
      variant="docked"
      footer={close => (
        <>
          <Button
            onClick={event => {
              props.handleComfortZoneMetrics(metrics)
              close(event)
            }}
            variant="primary"
          >
            Save
          </Button>
          <LinkButton onClick={close}>Cancel</LinkButton>
        </>
      )}
    >
      <>
        {showPerformanceMetricsInstructions && (
          <Box mb="l" px="l">
            <Box as="h3" fontSize="base" mb="xs">
              Not all comfort zones have performance data
            </Box>
            <Box as="p">
              In order to include a given comfort zone in the PDF, you need to
              run performance metrics in your SpecLab layout. Go to your SpecLab
              layout, click the Performance tab (bottom right), wait for the
              metrics to load, and refresh this page.
            </Box>
          </Box>
        )}
        {allZones ? (
          allZones.map((zone, idx) => {
            const controlledZone = getMetric(zone.id)
            return (
              <>
                <FlexGrid key={zone.id} alignItems="center" mb="base" px="l">
                  <FlexGrid.Item>
                    <Box
                      bg={zone.color}
                      borderRadius="base"
                      height="50px"
                      width="50px"
                    />
                  </FlexGrid.Item>
                  <FlexGrid.Item flexBasis="40%">
                    <Box as="h3" mb="xs">
                      {get(zone, 'name')}
                    </Box>
                    {get(zone, 'metrics') ? (
                      <Checkbox
                        name={`${zone.id}-included`}
                        label="Include in PDF"
                        onChange={handleCheck(zone)}
                        checked
                      />
                    ) : (
                      <Box
                        as="p"
                        color="neutrals.4"
                        fontSize="s"
                        fontStyle="italic"
                      >
                        You need to run performance metrics.
                      </Box>
                    )}
                  </FlexGrid.Item>
                  {get(zone, 'metrics') && (
                    <>
                      <FlexGrid.Item>
                        <Select
                          name={zone.id}
                          id={zone.id}
                          placeholder=""
                          value={get(getMetric(zone.id), 'height') || ''}
                          onChange={handleSelect(zone.id, 'height')}
                        >
                          <Select.Option value="standing">
                            Standing
                          </Select.Option>
                          <Select.Option value="seated">Seated</Select.Option>
                        </Select>
                      </FlexGrid.Item>
                      <FlexGrid.Item>
                        <Select
                          name={zone.id}
                          id={zone.id}
                          placeholder=""
                          value={get(getMetric(zone.id), 'goal') || ''}
                          onChange={handleSelect(zone.id, 'goal')}
                        >
                          <Select.Option value="cooling">Cooling</Select.Option>
                          <Select.Option value="destrat">Destrat</Select.Option>
                        </Select>
                      </FlexGrid.Item>
                    </>
                  )}
                </FlexGrid>
                <FlexGrid>
                  <FlexGrid.Item
                    mb="base"
                    marginLeft="l"
                    alignItems="center"
                    px="1"
                  >
                    {controlledZone && (
                      <>
                        <Checkbox
                          name={`${zone.id}-coolingCoverage`}
                          label="Cooling Coverage"
                          onChange={handleCheckMetricsToInclude(
                            zone.id,
                            'coolingCoverage'
                          )}
                          checked={controlledZone.coolingCoverage}
                        />
                        <Checkbox
                          name={`${zone.id}-includeSpeed`}
                          label="Air Velocity"
                          onChange={handleCheckMetricsToInclude(
                            zone.id,
                            'includeSpeed'
                          )}
                          checked={controlledZone.includeSpeed}
                        />
                        <Checkbox
                          name={`${zone.id}-coolingEffect`}
                          label="Cooling Effect"
                          onChange={handleCheckMetricsToInclude(
                            zone.id,
                            'coolingEffect'
                          )}
                          checked={controlledZone.coolingEffect}
                        />
                        <Checkbox
                          name={`${zone.id}-includeAirTemp`}
                          label="Air Temperature"
                          onChange={handleCheckMetricsToInclude(
                            zone.id,
                            'includeAirTemp'
                          )}
                          checked={controlledZone.includeAirTemp}
                        />
                        <Checkbox
                          name={`${zone.id}-includePmvScale`}
                          label="PMV Scale"
                          onChange={handleCheckMetricsToInclude(
                            zone.id,
                            'pmvScale'
                          )}
                          checked={controlledZone.pmvScale}
                        />
                      </>
                    )}
                  </FlexGrid.Item>
                </FlexGrid>
                <FlexGrid.Item mb="base">
                  <hr />
                </FlexGrid.Item>
              </>
            )
          })
        ) : (
          <p>You do not have zones.</p>
        )}
      </>
    </Modal>
  )
}

ComfortZonesModal.propTypes = {
  handleComfortZoneMetrics: PropTypes.func,
  title: PropTypes.string,
  versionData: PropTypes.object,
}

export default ComfortZonesModal
