import gql from 'graphql-tag'

export const LIST_ENTRIES_QUERY = gql`
  query listEntries(
    $orderBy: String
    $filter: EntryFilter
    $limit: Int
    $page: Int
  ) {
    listEntries(
      orderBy: $orderBy
      filter: $filter
      limit: $limit
      page: $page
    ) {
      entries {
        id
        name
        type
        status
        updatedAt
        submittedAt
      }
      count
    }
  }
`

export const GET_ENTRY_QUERY = gql`
  query getEntry($id: ID!) {
    entry: getEntry(id: $id) {
      id
      name
      type
      status
      updatedAt
      submittedAt
      notes
      opportunity {
        data {
          id
          name
          accountId
          stageName
        }
      }
      event {
        data {
          id
          subject
          startDateTime
          endDateTime
          whoId
          callType
          callResult
          description
          siteVisitType
        }
      }
      account {
        data {
          id
          name
          shippingStreet
          shippingPostalCode
          shippingState
          shippingCountry
        }
      }
      contacts {
        data {
          id
          name
          title
          phone
          email
        }
      }
      facilities {
        id
        name
        createdAt
        units
        floors {
          id
          name
          version {
            id
          }
          versions {
            id
            name
            quote {
              id
            }
          }
        }
        floor {
          id
          version {
            id
          }
          versions {
            id
            name
            quote {
              id
            }
          }
        }
      }
      quotes {
        id
        name
        salesforceQuoteId
      }
    }
  }
`
