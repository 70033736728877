import React from 'react'

import logo from 'assets/logo-dark.svg'
import Box from 'styled/Box'
import Card from 'styled/Card'
import Image from 'components/Image'
import Loader from 'components/Loader'

function MaintenanceScreen() {
  return (
    <Box
      alignItems="center"
      display="flex"
      height="100vh"
      width="100vh"
      maxWidth="800px"
      mx="auto"
      p="xl"
    >
      <Card p="xl" width="100%">
        <Image src={logo} width="200" height="48" alt="Big Ass Fans" mb="l" />
        <Loader name="folding-cube" fadeIn="none" mb="base" />
        <Box as="h1">We are doing some maintenance&hellip;</Box>
        <Box as="p" fontSize="l" mb="base">
          We apologize for the inconvenience, but we are currently working on a
          little bit of maintenance so the application runs nice and smooth.
        </Box>
        <Box as="p" fontSize="l" mb="l">
          Please check back.
        </Box>
      </Card>
    </Box>
  )
}

export default MaintenanceScreen
