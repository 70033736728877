import gql from 'graphql-tag'

export const CREATE_ENTRY_MUTATION = gql`
  mutation createEntry(
    $name: String!
    $type: EntryTypes!
    $opportunityId: ID
    $accountId: ID
    $eventId: ID
  ) {
    entry: createEntry(
      name: $name
      type: $type
      opportunityId: $opportunityId
      accountId: $accountId
      eventId: $eventId
    ) {
      id
      name
      type
      status
      updatedAt
      submittedAt
      opportunity {
        data {
          id
          name
        }
      }
      event {
        data {
          id
          subject
          startDateTime
        }
      }
    }
  }
`

export const UPDATE_ENTRY_MUTATION = gql`
  mutation UpdateEntry(
    $id: ID!
    $name: String
    $type: EntryTypes
    $status: EntryStatuses
    $opportunityId: ID
    $notes: String
    $submittedAt: DateTime
    $eventId: ID
    $accountId: ID
    $facilityId: ID
  ) {
    entry: updateEntry(
      id: $id
      name: $name
      type: $type
      status: $status
      opportunityId: $opportunityId
      notes: $notes
      submittedAt: $submittedAt
      eventId: $eventId
      accountId: $accountId
      facilityId: $facilityId
    ) {
      id
      name
      type
      status
      updatedAt
      submittedAt
      notes
      opportunity {
        data {
          id
          name
          accountId
          stageName
        }
      }
      event {
        data {
          id
          subject
          startDateTime
          endDateTime
          whoId
          callType
          callResult
          description
        }
      }
      account {
        data {
          id
          name
        }
      }
      contacts {
        data {
          id
          name
          title
          phone
          email
        }
      }
      facilities {
        id
        name
        createdAt
        floors {
          id
          name
          version {
            id
          }
          versions {
            id
            name
            quote {
              id
            }
          }
        }
        floor {
          id
          version {
            id
          }
          versions {
            id
            name
            quote {
              id
            }
          }
        }
      }
      quotes {
        id
        name
        salesforceQuoteId
      }
    }
  }
`

export const DUPLICATE_ENTRY_MUTATION = gql`
  mutation duplicateEntry($id: ID!, $name: String!) {
    duplicateEntry(id: $id, name: $name) {
      id
      name
      status
      updatedAt
    }
  }
`

export const DELETE_ENTRY_MUTATION = gql`
  mutation deleteEntry($id: ID!) {
    deleteEntry(id: $id) {
      id
    }
  }
`
