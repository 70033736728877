import React from 'react'
import PropTypes from 'prop-types'

import Box from 'styled/Box'
import Icon from 'components/Icon'

function ViewInSalesforceLink(props = {}) {
  return (
    <Box
      as="a"
      href={`${process.env.REACT_APP_SALESFORCE_URL}/${props.id}`}
      target="_blank"
      rel="noopener noreferrer"
      fontSize="s"
      fontStyle="normal"
    >
      <Icon name="cloud" color="salesforceBlue" size="16" mr="xs" />
      View on Salesforce
    </Box>
  )
}

ViewInSalesforceLink.propTypes = {
  id: PropTypes.string.isRequired,
}

export default ViewInSalesforceLink
