import get from 'lodash/get'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'

const FILENAME_OPTIONS = {
  prefixes: {
    customer: 'BAF_Solution_Summary',
    internal: 'Facility_Survey',
  },
  separator: '_',
}

const TITLE_OPTIONS = {
  separator: ' - ',
}

const format = str => str.replace(/\s/g, FILENAME_OPTIONS.separator) || ''

function getPDFNames(options) {
  const { area, numberOfAreas } = options
  const areaName = get(area, 'layout.name', '')
  const hasOneArea = numberOfAreas === 1
  const hasDefaultName = areaName.match(/area 1/i) || areaName.match(/floor 1/i)
  const ignoreArea = hasOneArea && hasDefaultName

  return {
    filename: getFilename({ ...options, areaName, ignoreArea }),
    title: getTitle({ ...options, areaName, ignoreArea }),
  }
}

function getFilename(options) {
  const { accountName, areaName, ignoreArea, type } = options
  const prefix = FILENAME_OPTIONS.prefixes[type]
  const separator = FILENAME_OPTIONS.separator
  const _accountName = startCase(accountName) || ''
  const defaultFilename = `${prefix}${separator}${format(_accountName)}`
  if (ignoreArea) return `${defaultFilename}.pdf`
  const _areaName = toLower(format(areaName))
  return `${defaultFilename}${separator}${_areaName}.pdf`
}

function getTitle(options) {
  const { areaName, facilityName, ignoreArea } = options
  if (ignoreArea) return facilityName
  const separator = TITLE_OPTIONS.separator
  return `${facilityName}${separator}${areaName}`
}

export default getPDFNames
