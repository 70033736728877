import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import omit from 'lodash/omit'

import { useAlertDispatch, actionTypes } from 'providers/AlertProvider'
import Box from 'styled/Box'
import Icon from 'components/Icon'
import StyledAlert from 'styled/Alert'
import Button from 'components/Button'

function Alert(props = {}) {
  const dispatch = useAlertDispatch()
  const [visible, setVisible] = useState(true)
  const icons = {
    info: 'info',
    warning: 'info',
    error: 'error',
    success: 'checkCircle',
  }

  useEffect(() => {
    let timer

    if (props.autohide) {
      timer = setTimeout(() => {
        setVisible(false)
        dispatch({ type: actionTypes.hide })
      }, props.interval)
    }
    return () => clearTimeout(timer)
  }, [dispatch, props.autohide, props.interval])

  return visible ? (
    <StyledAlert
      fixed={props.fixed}
      type={props.type}
      {...omit(props, ['fixed', 'type'])}
    >
      <Box display="flex">
        <Icon
          name={icons[props.type]}
          alignSelf="start"
          mr="s"
          position="relative"
          top="2px"
        />
        <Box flex="1">
          {/* We're doing this so that a block of markup (JSX) can be passed in. */}
          {typeof props.text === 'function' ? (
            props.text()
          ) : (
            <Box as="p">{props.text}</Box>
          )}
          {!isEmpty(props.action) && (
            <Button size="s" mt="s" {...omit(props.action, ['text'])}>
              {get(props, 'action.text')}
            </Button>
          )}
        </Box>
      </Box>
    </StyledAlert>
  ) : null
}

Alert.propTypes = {
  action: PropTypes.shape({
    onClick: PropTypes.func,
    mt: PropTypes.string,
    size: PropTypes.string,
    text: PropTypes.string,
    variant: PropTypes.oneOf(['primary', 'danger', 'active']),
  }),
  autohide: PropTypes.bool,
  fixed: PropTypes.bool,
  interval: PropTypes.number,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  type: PropTypes.oneOf(['info', 'warning', 'success', 'error']),
}

Alert.defaultProps = {
  autohide: true,
  fixed: false,
  interval: 2000,
  type: 'info',
}

export default Alert
