import React, { createContext, useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { flatten, get, isEmpty, isNil } from 'lodash'

import { getPDFNames } from 'lib/utils'

const CloseoutStateContext = createContext()
const CloseoutDispatchContext = createContext()

const actionTypes = {
  update: 'update',
  submitting: 'submitting',
  submissionError: 'submitting error',
  submissionSuccess: 'submitting success',
}
const keys = {
  rfp: 'rfp',
  engApps: 'engApps',
  ipq: 'ipq',
  updateEvent: 'updateEvent',
  newEvent: 'newEvent',
  task: 'task',
  notes: 'notes',
  note: 'note',
  updateOpp: 'updateOpp',
  newOpp: 'newOpp',
  oppTeamMember: 'oppTeamMember',
  siteVisit: 'siteVisit',
  pdf: {
    customer: 'customerPDF',
    internal: 'internalPDF',
  },
  images: 'images',
}
const STORAGE_KEY = 'BAF:UI:CloseoutData'

const isValid = value => Boolean(value && value !== '' && value !== {})

const loadInitialValue = ({
  key,
  value,
  initialValue = null,
} = {}) => entryId => {
  if (!entryId) return initialValue
  const ls = JSON.parse(
    window.localStorage.getItem(`${STORAGE_KEY}:${entryId}`)
  )
  if (!ls) return initialValue
  const lsValue = get(ls[key], value)
  return !isNil(lsValue) ? lsValue : initialValue
}

function initialState({
  id: entryId,
  facilities,
  opportunity,
  account,
  event,
  siteVisit,
}) {
  const areas = flatten(facilities.map(facility => facility.floors))
  return {
    entryId,
    facilities,
    opportunity,
    account,
    event,
    [keys.rfp]: {
      id: null,
      data: {
        status: loadInitialValue({ key: keys.rfp, value: 'data.status' })(
          entryId
        ),
        comments: loadInitialValue({
          key: keys.rfp,
          value: 'data.comments',
          initialValue: '',
        })(entryId),
      },
      completed: loadInitialValue({
        key: keys.rfp,
        value: 'completed',
        initialValue: false,
      })(entryId),
      submitting: false,
      submitted: false,
      error: null,
    },
    [keys.engApps]: {
      id: null,
      data: {
        recordTypeId: loadInitialValue({
          key: keys.engApps,
          value: 'data.recordTypeId',
        })(entryId),
        fanGoal: loadInitialValue({
          key: keys.engApps,
          value: 'data.fanGoal',
        })(entryId),
        salesPoC: loadInitialValue({
          key: keys.engApps,
          value: 'data.salesPoC',
        })(entryId),
        notesFromSubmitter: loadInitialValue({
          key: keys.engApps,
          value: 'data.notesFromSubmitter',
          initialValue: '',
        })(entryId),
      },
      completed: loadInitialValue({
        key: keys.engApps,
        value: 'completed',
        initialValue: false,
      })(entryId),
      submitting: false,
      submitted: false,
      error: null,
    },
    [keys.ipq]: {
      id: null,
      data: {
        description: loadInitialValue({
          key: keys.ipq,
          value: 'data.description',
          initialValue: '',
        })(entryId),
      },
      completed: loadInitialValue({
        key: keys.ipq,
        value: 'completed',
        initialValue: false,
      })(entryId),
      submitting: false,
      submitted: false,
      error: null,
    },
    [keys.newEvent]: {
      id: null,
      data: {
        subject: loadInitialValue({
          key: keys.newEvent,
          value: 'data.subject',
          initialValue: '',
        })(entryId),
        contact: loadInitialValue({
          key: keys.newEvent,
          value: 'data.contact',
        })(entryId),
        start: loadInitialValue({
          key: keys.newEvent,
          value: 'data.start',
          initialValue: format(new Date(), 'YYYY-MM-DDTHH:mm'),
        })(entryId),
        end: loadInitialValue({
          key: keys.newEvent,
          value: 'data.end',
          initialValue: format(new Date(), 'YYYY-MM-DDTHH:mm'),
        })(entryId),
        communicationType: loadInitialValue({
          key: keys.newEvent,
          value: 'data.communicationType',
        })(entryId),
        activityResult: loadInitialValue({
          key: keys.newEvent,
          value: 'data.activityResult',
        })(entryId),
        visitType: loadInitialValue({
          key: keys.newEvent,
          value: 'data.visitType',
        })(entryId),
        confirmationStatus: loadInitialValue({
          key: keys.newEvent,
          value: 'data.confirmationStatus',
        })(entryId),
        description: loadInitialValue({
          key: keys.newEvent,
          value: 'data.description',
          initialValue: '',
        })(entryId),
      },
      requiredFields: [
        'subject',
        'start',
        'end',
        'communicationType',
        'activityResult',
        'visitType',
        'confirmationStatus',
        'description',
      ],
      completed: loadInitialValue({
        key: keys.newEvent,
        value: 'completed',
        initialValue: false,
      })(entryId),
      submitting: false,
      submitted: false,
      error: null,
    },
    [keys.updateEvent]: {
      id: null,
      data: {
        subject: loadInitialValue({
          key: keys.updateEvent,
          value: 'data.subject',
          initialValue: get(event, 'data.subject'),
        })(entryId),
        contact: loadInitialValue({
          key: keys.newEvent,
          value: 'data.contact',
          initialValue: get(event, 'data.contact'),
        })(entryId),
        start: loadInitialValue({
          key: keys.updateEvent,
          value: 'data.start',
          initialValue: format(
            new Date(get(event, 'data.startDateTime')),
            'YYYY-MM-DDTHH:mm'
          ),
        })(entryId),
        end: loadInitialValue({
          key: keys.updateEvent,
          value: 'data.end',
          initialValue: format(
            new Date(get(event, 'data.endDateTime')),
            'YYYY-MM-DDTHH:mm'
          ),
        })(entryId),
        communicationType: loadInitialValue({
          key: keys.updateEvent,
          value: 'data.communicationType',
          initialValue: get(event, 'data.callType'),
        })(entryId),
        activityResult: loadInitialValue({
          key: keys.updateEvent,
          value: 'data.activityResult',
          initialValue: get(event, 'data.callResult'),
        })(entryId),
        visitType: loadInitialValue({
          key: keys.updateEvent,
          value: 'data.visitType',
          initialValue: get(event, 'data.siteVisitType'),
        })(entryId),
        confirmationStatus: loadInitialValue({
          key: keys.updateEvent,
          value: 'data.confirmationStatus',
          // TODO: Set the initial value from the event data.
        })(entryId),
        description: loadInitialValue({
          key: keys.updateEvent,
          value: 'data.description',
          initialValue: get(event, 'data.description') || '',
        })(entryId),
      },
      requiredFields: [
        'subject',
        'start',
        'end',
        'communicationType',
        'activityResult',
        'visitType',
        'confirmationStatus',
        'description',
      ],
      completed: loadInitialValue({
        key: keys.updateEvent,
        value: 'completed',
        initialValue: false,
      })(entryId),
      submitting: false,
      submitted: false,
      error: null,
    },
    [keys.task]: {
      id: null,
      data: {
        subject: loadInitialValue({
          key: keys.task,
          value: 'data.subject',
          initialValue: '',
        })(entryId),
        description: loadInitialValue({
          key: keys.task,
          value: 'data.description',
          initialValue: '',
        })(entryId),
        dueDate: loadInitialValue({
          key: keys.task,
          value: 'data.dueDate',
          initialValue: '',
        })(entryId),
        assignedTo: loadInitialValue({
          key: keys.task,
          value: 'data.assignedTo',
          initialValue: '',
        })(entryId),
      },
      requiredFields: ['subject', 'assignedTo', 'dueDate'],
      completed: loadInitialValue({
        key: keys.task,
        value: 'completed',
        initialValue: false,
      })(entryId),
      submitting: false,
      submitted: false,
      error: null,
    },
    [keys.notes]: {
      id: null,
      data: {
        text: loadInitialValue({
          key: keys.notes,
          value: 'data.text',
          initialValue: '',
        })(entryId),
      },
      completed: loadInitialValue({
        key: keys.notes,
        value: 'completed',
        initialValue: false,
      })(entryId),
      submitting: false,
      submitted: false,
      error: null,
    },
    [keys.note]: {
      id: null,
      data: {
        text: loadInitialValue({
          key: keys.notes,
          value: 'data.text',
          initialValue: '',
        })(entryId),
      },
      completed: loadInitialValue({
        key: keys.notes,
        value: 'completed',
        initialValue: false,
      })(entryId),
      submitting: false,
      submitted: false,
      error: null,
    },
    [keys.newOpp]: {
      id: null,
      data: {
        name: loadInitialValue({
          key: keys.newOpp,
          value: 'data.name',
          initialValue: '',
        })(entryId),
        purchaser: loadInitialValue({
          key: keys.newOpp,
          value: 'data.purchaser',
          initialValue: 'End User',
        })(entryId),
        type: loadInitialValue({
          key: keys.newOpp,
          value: 'data.type',
          initialValue: '',
        })(entryId),
        verticalMarket: loadInitialValue({
          key: keys.newOpp,
          value: 'data.verticalMarket',
          initialValue: '',
        })(entryId),
        segment: loadInitialValue({
          key: keys.newOpp,
          value: 'data.segment',
          initialValue: '',
        })(entryId),
        closeDate: loadInitialValue({
          key: keys.newOpp,
          value: 'data.closeDate',
          initialValue: format(new Date(), 'YYYY-MM-DD'),
        })(entryId),
      },
      // TODO: Remove this once we have `segment` values wired up.
      requiredFields: [
        'name',
        'purchaser',
        'type',
        'verticalMarket',
        'closeDate',
      ],
      completed: loadInitialValue({
        key: keys.newOpp,
        value: 'completed',
        initialValue: false,
      })(entryId),
      submitting: false,
      submitted: false,
      error: null,
    },
    [keys.updateOpp]: {
      id: null,
      completed: get(opportunity, 'data.id') && Boolean(facilities.length),
      submitting: false,
      submitted: false,
      error: null,
    },
    [keys.siteVisit]: {
      id: null,
      completed: get(siteVisit, 'siteVisit', []).length !== 0,
      submitting: false,
      submitted: false,
      error: null,
    },
    [keys.oppTeamMember]: {
      id: null,
      completed: get(opportunity, 'data.id'),
      submitting: false,
      submitted: false,
      error: null,
    },
    [keys.pdf.customer]: {
      id: null,
      areas: areas.map((area, i) => ({
        addedToPdf: loadInitialValue({
          key: keys.pdf.customer,
          value: `areas[${i}].addedToPdf`,
          initialValue: true,
        })(entryId),
        id: area.id,
        type: 'SOLUTION',
        layout: loadInitialValue({
          key: keys.pdf.customer,
          value: `areas[${i}].layout`,
          initialValue: area,
        })(entryId),
        notes: false,
        user: {},
        mainImage: {
          type: loadInitialValue({
            key: keys.pdf.customer,
            value: `areas[${i}].options.showDetailedView`,
            initialValue: false,
          })(entryId)
            ? 'DETAILED_VIEW'
            : 'PLAN_VIEW',
          selection: null,
        },
        options: {
          fanSchedule: true,
          additionalImages: loadInitialValue({
            key: keys.pdf.customer,
            value: `areas[${i}].options.additionalImages`,
          })(entryId),
          selectedCoolingImages: loadInitialValue({
            key: keys.pdf.customer,
            value: `areas[${i}].options.selectedCoolingImages`,
          })(entryId),
          selectedDestratImages: loadInitialValue({
            key: keys.pdf.customer,
            value: `areas[${i}].options.selectedDestratImages`,
          })(entryId),
          comments: loadInitialValue({
            key: keys.pdf.customer,
            value: `areas[${i}].options.comments`,
            initialValue: '',
          })(entryId),
          comfortZoneMetrics: loadInitialValue({
            key: keys.pdf.customer,
            value: `areas[${i}].options.comfortZoneMetrics`,
          })(entryId),
          detailedType: loadInitialValue({
            key: keys.pdf.customer,
            value: `areas[${i}].options.detailedType`,
            initialValue: 'ITEMIZED',
          })(entryId),
          includeDimensions: loadInitialValue({
            key: keys.pdf.customer,
            value: `areas[${i}].options.includeDimensions`,
            initialValue: false,
          })(entryId),
          showAdditionalImages: loadInitialValue({
            key: keys.pdf.customer,
            value: `areas[${i}].options.showAdditionalImages`,
            initialValue: false,
          })(entryId),
          showComfortZoneMetrics: loadInitialValue({
            key: keys.pdf.customer,
            value: `areas[${i}].options.showComfortZoneMetrics`,
            initialValue: false,
          })(entryId),
          showComments: loadInitialValue({
            key: keys.pdf.customer,
            value: `areas[${i}].options.showComments`,
            initialValue: false,
          })(entryId),
          showDetailedView: loadInitialValue({
            key: keys.pdf.customer,
            value: `areas[${i}].options.showDetailedView`,
            initialValue: false,
          })(entryId),
          imagesPerPage: loadInitialValue({
            key: keys.pdf.customer,
            value: `areas[${i}].options.imagesPerPage`,
            initialValue: 4,
          })(entryId),
        },
      })),
      data: {
        type: 'SOLUTION',
        layout: loadInitialValue({
          key: keys.pdf.customer,
          value: 'data.layout.floor',
          initialValue: get(areas, '[0]'),
        })(entryId),
        notes: false,
        user: {},
        mainImage: {
          type: loadInitialValue({
            key: keys.pdf.customer,
            value: `data.options.showDetailedView`,
            initialValue: false,
          })(entryId)
            ? 'DETAILED_VIEW'
            : 'PLAN_VIEW',
          selection: null,
        },
        options: {
          fanSchedule: true,
          additionalImages: loadInitialValue({
            key: keys.pdf.customer,
            value: `data.options.additionalImages`,
          })(entryId),
          selectedCoolingImages: loadInitialValue({
            key: keys.pdf.customer,
            value: `data.options.selectedCoolingImages`,
          })(entryId),
          selectedDestratImages: loadInitialValue({
            key: keys.pdf.customer,
            value: `data.options.selectedDestratImages`,
          })(entryId),
          comments: loadInitialValue({
            key: keys.pdf.customer,
            value: 'data.options.comments',
            initialValue: '',
          })(entryId),
          comfortZoneMetrics: loadInitialValue({
            key: keys.pdf.customer,
            value: 'data.options.comfortZoneMetrics',
          })(entryId),
          detailedType: loadInitialValue({
            key: keys.pdf.customer,
            value: `data.options.detailedType`,
            initialValue: 'ITEMIZED',
          })(entryId),
          includeDimensions: loadInitialValue({
            key: keys.pdf.customer,
            value: `data.options.includeDimensions`,
            initialValue: false,
          })(entryId),
          showAdditionalImages: loadInitialValue({
            key: keys.pdf.customer,
            value: `data.options.showAdditionalImages`,
            initialValue: false,
          })(entryId),
          showComfortZoneMetrics: loadInitialValue({
            key: keys.pdf.customer,
            value: `data.options.showComfortZoneMetrics`,
            initialValue: false,
          })(entryId),
          showComments: loadInitialValue({
            key: keys.pdf.customer,
            value: `data.options.showComments`,
            initialValue: false,
          })(entryId),
          showDetailedView: loadInitialValue({
            key: keys.pdf.customer,
            value: `data.options.showDetailedView`,
            initialValue: false,
          })(entryId),
          imagesPerPage: loadInitialValue({
            key: keys.pdf.customer,
            value: `data.options.imagesPerPage`,
            initialValue: 4,
          })(entryId),
        },
      },
      requiredFields: ['layout', 'mainImage'],
      completed: loadInitialValue({
        key: keys.pdf.customer,
        value: 'completed',
        initialValue: !isEmpty(get(facilities, '[0]')),
      })(entryId),
      submitting: false,
      submitted: false,
      error: null,
    },
    [keys.pdf.internal]: {
      id: null,
      areas: areas.map((area, i) => ({
        addedToPdf: loadInitialValue({
          key: keys.pdf.internal,
          value: `areas[${i}].addedToPdf`,
          initialValue: true,
        })(entryId),
        id: area.id,
        type: 'INTERNAL',
        layout: loadInitialValue({
          key: keys.pdf.internal,
          value: `areas[${i}].layout`,
          initialValue: area,
        })(entryId),
        notes: false,
        user: {},
        mainImage: {
          type: loadInitialValue({
            key: keys.pdf.internal,
            value: `areas[${i}].options.showDetailedView`,
            initialValue: true,
          })(entryId)
            ? 'DETAILED_VIEW'
            : 'PLAN_VIEW',
          selection: null,
        },
        options: {
          additionalImages: null,
          fanSchedule: true,
          objectInventory: true,
          detailedType: loadInitialValue({
            key: keys.pdf.internal,
            value: `areas[${i}].options.detailedType`,
            initialValue: 'ITEMIZED',
          })(entryId),
          showDetailedView: loadInitialValue({
            key: keys.pdf.internal,
            value: `areas[${i}].options.showDetailedView`,
            initialValue: true,
          })(entryId),
        },
      })),
      data: {
        type: 'INTERNAL',
        layout: loadInitialValue({
          key: keys.pdf.internal,
          value: 'data.layout.floor',
          initialValue: get(areas, '[0]'),
        })(entryId),
        mainImage: {
          type: loadInitialValue({
            key: keys.pdf.internal,
            value: `data.options.showDetailedView`,
            initialValue: true,
          })(entryId)
            ? 'DETAILED_VIEW'
            : 'PLAN_VIEW',
          selection: null,
        },
        options: {
          additionalImages: null,
          fanSchedule: true,
          objectInventory: true,
          detailedType: loadInitialValue({
            key: keys.pdf.internal,
            value: `data.options.detailedType`,
            initialValue: 'ITEMIZED',
          })(entryId),
          showDetailedView: loadInitialValue({
            key: keys.pdf.internal,
            value: `data.options.showDetailedView`,
            initialValue: true,
          })(entryId),
        },
      },
      requiredFields: ['layout', 'mainImage'],
      completed: loadInitialValue({
        key: keys.pdf.internal,
        value: 'completed',
        initialValue: !isEmpty(get(facilities, '[0]')),
      })(entryId),
      submitting: false,
      submitted: false,
      error: null,
    },
    [keys.images]: {
      id: null,
      completed: !isEmpty(
        loadInitialValue({
          key: keys.images,
          value: 'data.images',
          initialValue: [],
        })(entryId)
      ),
      submitting: false,
      submitted: false,
      error: null,
      data: {
        images: loadInitialValue({
          key: keys.images,
          value: 'data.images',
          initialValue: [],
        })(entryId),
      },
    },
  }
}

function closeoutReducer(state, action) {
  switch (action.type) {
    case actionTypes.update: {
      const copiedState = {
        ...state,
        [action.key]: {
          ...action.payload,
        },
      }
      const copiedItem = copiedState[action.key]
      const completed = Object.values(copiedItem.data).every(value => {
        const requiredFields = get(
          action,
          'payload.requiredFields',
          copiedItem.requiredFields
        )
        const isValidWithRequiredFields = () =>
          requiredFields.every(field => {
            const requiredValue = copiedItem.data[field]
            return isValid(requiredValue)
          })
        if (requiredFields) return isValidWithRequiredFields()
        return isValid(value)
      })

      if (completed) {
        return {
          ...state,
          [action.key]: {
            ...action.payload,
            completed: true,
          },
        }
      }

      return {
        ...state,
        [action.key]: {
          ...action.payload,
          completed: false,
        },
      }
    }
    case actionTypes.submitting: {
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          submitting: true,
          submitted: false,
          error: null,
        },
      }
    }
    case actionTypes.submissionError: {
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          submitted: false,
          submitting: false,
          error: action.payload,
        },
      }
    }
    case actionTypes.submissionSuccess: {
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          id: action.payload,
          submitted: true,
          submitting: false,
          error: null,
        },
      }
    }
    case actionTypes.reset: {
      return {
        ...state,
        [action.key]: {
          // `facilities: []` will cause a problem *IF* we decide to clear
          // out the PDF data because it relies on having `facilities` to set
          // the initial value in state. So if we did that, a good approach
          // might be passing `facilities` in the `action.payload`.
          ...initialState({ id: null, facilities: [] })[action.key],
        },
      }
    }
    default: {
      throw new Error(`${action.type} not handled.`)
    }
  }
}

function CloseoutProvider(props = {}) {
  const [state, dispatch] = useReducer(
    closeoutReducer,
    initialState(props.entry),
    state => ({
      ...state,
      [keys.pdf.customer]: {
        ...state[keys.pdf.customer],
        data: {
          ...state[keys.pdf.customer].data,
          user: {
            name: get(props.user, 'profile.name'),
            email: get(props.user, 'email'),
            phone: get(props.user, 'phone'),
          },
        },
      },
      [keys.pdf.internal]: {
        ...state[keys.pdf.internal],
        data: {
          ...state[keys.pdf.internal].data,
          user: {
            name: get(props.user, 'profile.name'),
            email: get(props.user, 'email'),
            phone: get(props.user, 'phone'),
          },
        },
      },
    })
  )
  const entryId = get(props, 'entry.id')

  useEffect(() => {
    window.localStorage.setItem(
      `${STORAGE_KEY}:${entryId}`,
      JSON.stringify(state)
    )
    return () => {
      if (selectors.allSubmitted(state)) {
        window.localStorage.removeItem(`${STORAGE_KEY}:${entryId}`)
      }
    }
  }, [state, entryId])

  return (
    <CloseoutStateContext.Provider value={state}>
      <CloseoutDispatchContext.Provider value={dispatch}>
        {props.children}
      </CloseoutDispatchContext.Provider>
    </CloseoutStateContext.Provider>
  )
}

const selectors = {
  submitting: state => Object.values(state).some(value => value.submitting),
  completed: state => Object.values(state).filter(value => value.completed),
  anyCompleted: state => Object.values(state).some(value => value.completed),
  anyError: state => selectors.completed(state).some(value => value.error),
  allError: state => selectors.completed(state).every(value => value.error),
  anySubmitted: state =>
    selectors.completed(state).some(value => value.submitted),
  allSubmitted: state =>
    selectors.completed(state).every(value => value.submitted),
  pdfData: (state, type, areaId) => {
    const pdf = get(state, keys.pdf[type])
    const isSolution = type === 'customer'
    const data =
      get(pdf, 'areas').find(area => area.id === areaId) || get(pdf, 'data')
    const versionId =
      get(data, 'layout.version.id') || get(data, 'layout.floor.version.id')
    const { title: name } = getPDFNames({
      area: data,
      facilityName: get(state, 'facilities[0].name'),
      numberOfAreas: get(pdf, 'areas').length,
    })
    return {
      pdfType: isSolution ? 'SOLUTION' : 'INTERNAL',
      logoSrc: `${window.location.origin}/logo-512x512.png`,
      mainImage: get(pdf, 'data.mainImage.type'),
      savedMainImageSnapshot: get(data, 'mainImage.selection'),
      showDocumentExplanation: isSolution,
      showFPMGrid: get(data, 'options.fpmGrid', false),
      showAdditionalImages: get(data, 'options.showAdditionalImages', false),
      showComfortZoneMetrics: get(
        data,
        'options.showComfortZoneMetrics',
        false
      ),
      showComments: get(data, 'options.showComments', false),
      showDetailedView: get(pdf, 'data.options.showDetailedView'),
      showDimensionLines: get(pdf, 'data.options.showDimensionLines'),
      showMountStructureLines: get(pdf, 'data.options.showMountStructureLines'),
      imagesPerPage: get(data, 'options.imagesPerPage', 4),
      objectsToShow: get(data, 'options.objectInventory'),
      additionalImages: get(data, 'options.additionalImages'),
      selectedCoolingImages: get(data, 'options.selectedCoolingImages'),
      selectedDestratImages: get(data, 'options.selectedDestratImages'),
      selectedHeatingImages: get(data, 'options.selectedHeatingImages'),
      customerName: `${get(data, 'layout.name', '')} - Version ${versionId}`,
      contactInfo: {
        name: get(pdf, 'data.user.name'),
        email: get(pdf, 'data.user.email'),
        phone: get(pdf, 'data.user.phone'),
      },
      salesRepName: get(data, 'user.name'),
      salesRepEmail: get(data, 'user.email'),
      versionId: parseInt(versionId, 10),
      showNotes: get(data, 'notes', false),
      notes: get(state, '[notes].data.text', ''),
      comments: get(data, 'options.comments', ''),
      comfortZones: get(data, 'options.comfortZoneMetrics'),
      detailedType: get(pdf, 'data.options.detailedType'),
      name,
      showSchedule: true,
    }
  },
  hasAddedAreas: state =>
    get(state, 'customerPDF.areas').some(area => area.addedToPdf),
  hasOpportunity: state => get(state, 'opportunity.data.id'),
  willHaveOpportunity: state => get(state, keys.newOpp).completed,
  hasAccount: state => get(state, 'account.data.id'),
  parentId: state =>
    get(state, 'opportunity.data.id') || get(state, 'account.data.id'),
  rfpIsMissingEngApp: state =>
    get(state, `${keys.rfp}.completed`) &&
    get(state, `${keys.rfp}.data.status`).match(/full lighting proposal/i) &&
    !get(state, `${keys.engApps}.completed`),
  versionId: (state, pdfType = 'internal') => {
    const key = keys.pdf[pdfType]
    const pdf = get(state, key)
    return (
      get(pdf, 'data.layout.floor.version.id') ||
      get(pdf, 'data.layout.version.id')
    )
  },
}

const reset = key => ({
  type: actionTypes.reset,
  key,
})

function useCloseoutState() {
  const context = useContext(CloseoutStateContext)
  if (context === undefined) {
    throw new Error(`useCloseoutState must be used within a CloseoutProvider`)
  }
  return context
}

function useCloseoutDispatch() {
  const context = useContext(CloseoutDispatchContext)
  if (context === undefined) {
    throw new Error(
      `useCloseoutDispatch must be used within a CloseoutProvider`
    )
  }
  return context
}

function useCloseout() {
  const context = [useCloseoutState(), useCloseoutDispatch()]
  if (context === undefined) {
    throw new Error(`useCloseout must be used within a CloseoutProvider`)
  }
  return context
}

CloseoutProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  entry: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

export {
  CloseoutProvider as default,
  useCloseout,
  useCloseoutState,
  useCloseoutDispatch,
  actionTypes,
  keys,
  selectors,
  reset,
}
