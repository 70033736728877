import styled, { css } from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'
import PropTypes from 'prop-types'

import { all, propTypes } from 'styled/lib/system'

const required =
  props =>
  ({ required }) => {
    if (required) {
      return css`
        &::after {
          color: ${themeGet('colors.error')};
          content: '*';
          font-weight: ${themeGet('fontWeights.bold')};
          margin-left: 2px;
        }
      `
    }
  }

const FormLabel = styled.label`
  display: block;
  font-size: ${themeGet('fontSizes.s')};
  font-weight: ${themeGet('fontWeights.semibold')};

  ${required}
  ${all}
`

FormLabel.propTypes = {
  ...propTypes,
  required: PropTypes.bool,
}

export default FormLabel
