import gql from 'graphql-tag'

export const CREATE_ATTACHMENT_MUTATION = gql`
  mutation createAttachment($parent: ID!, $name: String!, $fileData: PDFData!) {
    createAttachment(parent: $parent, name: $name, fileData: $fileData) {
      data {
        id
      }
      error {
        code
        text
        status
        message
      }
    }
  }
`

export const CREATE_IMAGE_ATTACHMENT_MUTATION = gql`
  mutation createImageAttachment($parent: ID!, $name: String!, $url: String!) {
    createImageAttachment(parent: $parent, name: $name, url: $url) {
      data {
        id
      }
      error {
        code
        text
        status
        message
      }
    }
  }
`
