import find from 'lodash/find'
import get from 'lodash/get'

function hasFeature(data, feature) {
  const features = get(data, 'user.features', [])
  if (features.length === 0) return false
  const match = find(features, f => f.name === feature)
  return Boolean(match)
}

export default hasFeature
