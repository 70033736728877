import React from 'react'
import PropTypes from 'prop-types'
import { ApolloProvider } from '@apollo/react-hooks'
import { ThemeProvider } from 'styled-components/macro'
import { Provider as Auth0Provider } from 'react-auth0rize'

import auth0Client from 'config/auth0Client'
import apolloClient from 'config/apolloClient'
import flags from 'config/flags'
import theme from 'config/theme'
import modals from 'config/modals'
import {
  AlertProvider,
  FlagsProvider,
  ModalProvider,
  EntriesFilterProvider,
} from 'providers'

// The <ProviderComposer> component is used to avoid the annoyance of
// having an endless nesting of Provider components wrapping the root-level
// <App> component, while still providing the flexibility of adding multiple
// Providers throughout the app for shared state management.
function ProviderComposer(props = {}) {
  return props.contexts.reduceRight(
    (kids, parent, idx) =>
      React.cloneElement(parent, {
        key: idx,
        children: kids,
      }),
    props.children
  )
}

function AppProvider(props = {}) {
  /* eslint-disable react/jsx-key */
  /* Disabling this here because the `key` is passed in the ProviderComposer. */
  const contexts = [
    <Auth0Provider client={auth0Client} />,
    <ApolloProvider client={apolloClient} />,
    <FlagsProvider flags={flags} />,
    <AlertProvider />,
    <ModalProvider modals={modals} />,
    <ThemeProvider theme={theme} />,
    <EntriesFilterProvider />,
  ]

  return (
    <ProviderComposer contexts={contexts}>{props.children}</ProviderComposer>
  )
}

ProviderComposer.propTypes = {
  contexts: PropTypes.arrayOf(PropTypes.element),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
}

AppProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
}

// We're doing this because the `ApolloProvider` component has the
// `children` prop type as required, which we don't want here.
ApolloProvider.propTypes = {
  ...ApolloProvider.propTypes,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
}

export default AppProvider
