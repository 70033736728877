import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import get from 'lodash/get'

import routes from 'config/routes'

function SalesforceDataProtection(props = {}) {
  const error = get(props, `result.data.${props.type}.error`)
  if (error) return <Redirect to={routes.salesforceAuthentication} />
  return props.children
}

SalesforceDataProtection.propTypes = {
  result: PropTypes.object,
  type: PropTypes.string,
  children: PropTypes.any,
}

export default SalesforceDataProtection
