import get from 'lodash/get'

function parseGraphQLErrorObject(error) {
  if (typeof error !== 'object') return false
  return {
    graphQLError: get(error, 'graphQLError') || [],
    networkError: get(error, 'networkError') || [],
    errors: get(error, 'result.errors') || [],
    message: get(error, 'message'),
  }
}

export default parseGraphQLErrorObject
