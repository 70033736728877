import React from 'react'
import { Query } from '@apollo/react-components'
import compose from 'lodash/flowRight'
import { withRouter } from 'react-router-dom'
import ReactQuill from 'react-quill'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { RELEASE_NOTES_QUERY } from 'lib/queries'

import Modal from 'components/Modal'

import 'react-quill/dist/quill.bubble.css'

const ReleaseNoteWrapper = styled.div`
  margin: 20px 0;
  padding: 0 10px;
`

const ReleaseNotesModal = props => (
  <Modal title="SalesHub Release Notes" {...props}>
    <Query
      query={RELEASE_NOTES_QUERY}
      notifyOnNetworkStatusChange
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data }) => {
        if (loading) return <p>Loading release notes...</p>
        if (error) return <p>There was an error loading release notes.</p>
        const releaseNotes = data.releaseNotes
          .sort((a, b) => {
            const aDate = new Date(a.effectiveDate)
            const bDate = new Date(b.effectiveDate)
            if (aDate < bDate) return 1
            return -1
          })
          .map((releaseNote, index) => {
            return (
              <ReleaseNoteWrapper key={index}>
                <h4>{`Version ${releaseNote.version} - ${releaseNote.effectiveDate}`}</h4>
                <ReactQuill
                  theme="bubble"
                  value={decodeURI(releaseNote.htmlContents)}
                  readOnly={true}
                />
              </ReleaseNoteWrapper>
            )
          })
        return releaseNotes
      }}
    </Query>
  </Modal>
)

ReleaseNotesModal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  loading: PropTypes.bool,
  parentRoute: PropTypes.string,
}

export default compose(withRouter)(ReleaseNotesModal)
