import find from 'lodash/find'
import get from 'lodash/get'

function getSalesforceRecords(items, entry) {
  const type = get(entry, 'type')
  const hasOpportunity = Boolean(get(entry, 'opportunity.data'))
  const hasEvent = Boolean(get(entry, 'event.data'))

  const getItem = title => find(items, { title })
  const filteredItems = items.filter(
    i =>
      i.title !== 'Create Opportunity' &&
      i.title !== 'Update Event' &&
      i.title !== 'Create Event'
  )

  // 'Create Opportunity' can be added IF...
  if (!hasOpportunity && type !== 'SITE_VISIT')
    filteredItems.unshift(getItem('Create Opportunity'))
  // 'Create Event' can be added IF...
  if (!hasEvent) filteredItems.unshift(getItem('Create Event'))
  // 'Update Event' can be added IF...
  if (hasEvent) filteredItems.unshift(getItem('Update Event'))

  return filteredItems
}

export default getSalesforceRecords
