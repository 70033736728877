import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { find } from 'lodash'

import { getCloudinaryImageUrl } from 'lib/utils'

import SuggestedImagesProvider from 'providers/SuggestedImagesProvider'

import Box from 'styled/Box'

import Loader from 'components/Loader'
import Select from 'components/Select'
import SelectableImageGrid from 'components/SelectableImageGrid'

function SuggestedImages(props = {}) {
  const versionId = props.versionId
  const [maxImagesPerPage, setMaxImagesPerPage] = useState(4)
  useEffect(() => {
    setMaxImagesPerPage(props.imagesPerPage)
  }, [props.imagesPerPage])

  useEffect(() => {
    props.handleImagesPerPage(maxImagesPerPage)
  }, [maxImagesPerPage, props])

  return (
    <SuggestedImagesProvider
      LoadingComponent={() => <Loader />}
      variables={{ versionId }}
    >
      {({ data }) => {
        const images = {
          suggested: [...data.snapshots],
        }

        const imagesPerPageOptions = [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 4, label: '4' },
        ]

        return (
          <>
            <Box mb="l">
              <Select
                inline
                id="selectImagesPerPage"
                name="selectImagesPerPage"
                label="Images per page: "
                placeholder=""
                onChange={e => {
                  const imagesPerPage = find(imagesPerPageOptions, [
                    'label',
                    e.target.value,
                  ]).value
                  setMaxImagesPerPage(imagesPerPage)
                }}
                value={
                  find(imagesPerPageOptions, ['value', maxImagesPerPage]).label
                }
              >
                {imagesPerPageOptions.map((option, idx) => (
                  <Select.Option key={idx} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
              <Box as="h2" fontSize="l" fontWeight="semibold" mb="base">
                Snapshot Images
              </Box>
              <SelectableImageGrid
                canAddImage={props.canAddImage}
                emptyMessage={props.emptyMessage}
                getImageNumber={props.getImageNumber}
                handleImageSelection={props.handleImageSelection}
                images={images.suggested}
                isSelected={props.isImageSelected}
                setImageUrl={image => getCloudinaryImageUrl(image)}
              />
            </Box>
          </>
        )
      }}
    </SuggestedImagesProvider>
  )
}

SuggestedImages.propTypes = {
  emptyMessage: PropTypes.string,
  handleImageSelection: PropTypes.func.isRequired,
  handleImagesPerPage: PropTypes.func.isRequired,
  imagesPerPage: PropTypes.number.isRequired,
  isImageSelected: PropTypes.func,
  canAddImage: PropTypes.func,
  getImageNumber: PropTypes.func,
  showLoader: PropTypes.bool,
  units: PropTypes.oneOf(['IMPERIAL', 'METRIC']),
  versionId: PropTypes.string,
}

export default SuggestedImages
