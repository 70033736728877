import gql from 'graphql-tag'

export const LIST_CONTACTS_QUERY = gql`
  query listContacts($queryString: String!) {
    contacts: query(queryString: $queryString)
  }
`

export const GET_CONTACT_QUERY = gql`
  query getContact($id: ID!) {
    contact: getContact(id: $id) {
      data {
        id
        name
      }
    }
  }
`
