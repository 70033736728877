import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import ReactMarkdown from 'react-markdown'

import { useChangelog } from 'lib/hooks'
import Alert from 'components/Alert'
import Box from 'styled/Box'
import Markdown from 'styled/Markdown'
import Modal from 'components/Modal'
import Loader from 'components/Loader'

function WhatsNewModal(props = {}) {
  const { loading, error, data } = useChangelog()
  return (
    <Modal title={`What's new?`}>
      {loading && <Loader />}
      {error && <Alert type="error" message={error} />}
      {data && data.length !== 0
        ? data.map((item, idx) => <Version key={idx} {...item} />)
        : null}
    </Modal>
  )
}

function Version(props = {}) {
  return (
    <Box mb="base">
      <Box
        as="b"
        color="neutrals.4"
        fontSize="xs"
        fontWeight="semibold"
        textTransform="uppercase"
        letterSpacing="1px"
      >
        {format(props.date, 'MMMM DD, YYYY')}
      </Box>
      <Box as="h2">{props.version}</Box>
      <Markdown>
        <ReactMarkdown source={props.content} />
      </Markdown>
    </Box>
  )
}

Version.propTypes = {
  content: PropTypes.string,
  date: PropTypes.string,
  version: PropTypes.string,
}

WhatsNewModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
}

export default WhatsNewModal
