import gql from 'graphql-tag'

export const CREATE_FACILITY_MUTATION = gql`
  mutation createFacility(
    $name: String!
    $primaryUse: PrimaryUse!
    $primaryType: PrimaryType
    $primaryObjective: [PrimaryObjective!]!
    $entryId: ID
  ) {
    facility: createFacility(
      name: $name
      primaryUse: $primaryUse
      primaryType: $primaryType
      primaryObjective: $primaryObjective
      entryId: $entryId
    ) {
      id
      name
      createdAt
    }
  }
`

export const UPDATE_FACILITY_MUTATION = gql`
  mutation updateFacility($id: ID!, $entryId: ID) {
    facility: updateFacility(id: $id, entryId: $entryId) {
      id
      name
      createdAt
      floor {
        id
        version {
          id
        }
        versions {
          id
          name
          quote {
            id
          }
        }
      }
    }
  }
`

export const REMOVE_FACILITY_FROM_ENTRY_MUTATION = gql`
  mutation removeEntryFromFacility($id: ID!) {
    facility: removeEntryFromFacility(id: $id) {
      id
      name
      createdAt
      floor {
        id
        version {
          id
        }
        versions {
          id
          name
          quote {
            id
          }
        }
      }
    }
  }
`

export const UPDATE_VERSION_MUTATION = gql`
  mutation updateVersion($id: ID!, $name: String, $data: Json) {
    updateVersion(id: $id, name: $name, data: $data) {
      data
    }
  }
`
