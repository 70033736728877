import gql from 'graphql-tag'

export const CREATE_WORK_ORDER_MUTATION = gql`
  mutation CreateWorkOrder(
    $opportunity: ID!
    $account: ID!
    $description: String
    $subject: String
    $userId: ID
  ) {
    createWorkOrder(
      opportunity: $opportunity
      account: $account
      subject: $subject
      description: $description
      userId: $userId
    ) {
      message
      data {
        id
        success
        errors
      }
      error {
        code
        text
        status
        message
      }
    }
  }
`
