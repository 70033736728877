import React, { useState } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'

import Box from 'styled/Box'
import FormLabel from 'styled/FormLabel'
import StyledSelect from 'styled/Select'

function Select(props = {}) {
  const [value, setValue] = useState(props.placeholder)
  const selectProps = ['name', 'onChange']
  const rootProps =
    props.label && props.labelPosition === 'inline'
      ? {
          alignItems: 'center',
          display: 'flex',
        }
      : null
  const labelProps =
    props.label && props.labelPosition === 'inline'
      ? {
          mr: 'base',
        }
      : {
          mb: 's',
        }

  function handleChange(event) {
    // Only call setValue if props.onChange does not exist
    if (props.onChange) {
      props.onChange(event)
      return
    }
    setValue(event.target.value)
  }

  return (
    <Box {...rootProps}>
      {props.label ? (
        <FormLabel required={props.required} htmlFor={props.id} {...labelProps}>
          {props.label}
        </FormLabel>
      ) : null}
      <StyledSelect.Container {...omit(props, selectProps)} width={props.width}>
        <StyledSelect
          onChange={handleChange}
          width={props.width}
          name={props.name}
          id={props.id}
          value={props.value || value}
          required={props.required}
          data-testid="select-input"
        >
          {props.placeholder ? (
            <StyledSelect.Option disabled>
              {props.placeholder}
            </StyledSelect.Option>
          ) : null}
          {props.children}
        </StyledSelect>
      </StyledSelect.Container>
    </Box>
  )
}

Select.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['inline', 'top']),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  width: PropTypes.string,
}

Select.defaultProps = {
  labelPosition: 'inline',
  placeholder: 'Select...',
  required: false,
}

Select.Option = StyledSelect.Option

export default Select
