import qs from 'qs'

import routes from 'config/routes'
import { isAccountId, isOpportunityId } from 'config/salesforce'

function getNewEntryUrl({ name, eventId, whatId }) {
  const params = {
    name,
    eventId,
    whatId: isAccountId(whatId) || isOpportunityId(whatId) ? whatId : null,
  }
  return `${routes.entries.new}${qs.stringify(params, {
    addQueryPrefix: true,
    skipNulls: true,
  })}`
}

export default getNewEntryUrl
