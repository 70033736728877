import React from 'react'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { GET_APP_SETTINGS_QUERY } from 'lib/queries'
import { isEnv } from 'lib/utils'
import DefaultProvider from 'providers/DefaultProvider'

// 5 minutes
const DEFAULT_POLL_INTERVAL = 300000

function AppSettingsProvider(props = {}) {
  return (
    <DefaultProvider
      query={GET_APP_SETTINGS_QUERY}
      ErrorComponent={props.ErrorComponent}
      LoadingComponent={props.LoadingComponent}
      // This `pollInterval` is set up because the `isUnderMaintenance` flag in
      // `AppSettings` needs to be checked regularly in order to properly
      // redirect users that are currently using the app.
      pollInterval={isEnv.prod ? DEFAULT_POLL_INTERVAL : null}
      {...pick(props, ['passThrough', 'variables'])}
    >
      {result => props.children(result)}
    </DefaultProvider>
  )
}

AppSettingsProvider.propTypes = {
  ...omit(DefaultProvider.propTypes, ['query']),
}

export default AppSettingsProvider
