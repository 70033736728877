import { useState, useEffect } from 'react'
import { isFuture } from 'date-fns'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { useCloseout, actionTypes } from 'providers/CloseoutProvider'

function useCommunicationTypeFields(key) {
  const [state, dispatch] = useCloseout()
  const [isUpdated, setIsUpdated] = useState(false)
  const [initialRequiredFields, setInitialRequiredFields] = useState(
    get(state, `${key}.requiredFields`)
  )
  const activityResult = 'activityResult'
  const visitType = 'visitType'
  const confirmationStatus = 'confirmationStatus'
  const conditionalRequiredFields = {
    'Sales Visit': [activityResult, visitType, confirmationStatus],
    'Trade Show': [],
    'Ride Along': [visitType],
  }
  const shouldShowField = field => {
    const type = get(state, `${key}.data.communicationType`, '')
    if (!conditionalRequiredFields[type]) return false
    return conditionalRequiredFields[type].includes(field)
  }

  useEffect(() => {
    let requiredFields = get(state, `${key}.requiredFields`)
    // Creating a copy of the `requiredFields` to make sure they changed.
    const requiredFieldsCopy = [...requiredFields]
    const startTime = get(state, `${key}.data.start`)
    const endTime = get(state, `${key}.data.end`)
    const isFutureEvent = isFuture(startTime) && isFuture(endTime)
    const type = get(state, `${key}.data.communicationType`)
    // Tracking this show we can handle the future event.
    let activityResultDisplayed = false
    const addFields = fields => {
      const newFields = []
      fields.forEach(field => {
        const hasField = requiredFields.includes(field)
        if (!hasField) newFields.push(field)
      })
      requiredFields = [...requiredFields, ...newFields]
    }
    const removeFields = fields => {
      const excludedFields = [...fields]
      requiredFields = requiredFields.filter(
        field => !excludedFields.includes(field)
      )
    }

    switch (type) {
      case 'Sales Call/On-Site Presentation':
      case 'Doorknock':
      case 'Facility Survey': {
        activityResultDisplayed = true
        removeFields([visitType, confirmationStatus])
        break
      }
      case 'Sales Visit': {
        activityResultDisplayed = true
        addFields([activityResult, visitType, confirmationStatus])
        break
      }
      case 'Trade Show':
      case 'Survey / Quote Presentation': {
        activityResultDisplayed = false
        removeFields([activityResult, visitType, confirmationStatus])
        break
      }
      case 'Ride Along': {
        activityResultDisplayed = false
        removeFields([activityResult, confirmationStatus])
        addFields([visitType])
        break
      }
      default: {
        break
      }
    }

    // If the 'Activity Result' field is showing.
    if (activityResultDisplayed) {
      // If it's a future event, remove the requirement.
      if (isFutureEvent) {
        removeFields([activityResult])
        // If it's *NOT* a future event, add the requirement.
      } else {
        addFields([activityResult])
      }
      // If the 'Activity Result' field is *NOT* showing, remove the requirement.
    } else {
      removeFields([activityResult])
    }

    // We're tracking this so the `isRequired` function can properly trigger
    // the required state on the field.
    setInitialRequiredFields(requiredFields)

    // We're doing this to avoid unnecessary `dispatch` calls :)
    const requiredFieldsChanged = !isEqual(requiredFields, requiredFieldsCopy)

    if (isUpdated && requiredFieldsChanged) {
      dispatch({
        type: actionTypes.update,
        key,
        payload: {
          ...state[key],
          requiredFields,
        },
      })
      setIsUpdated(false)
    }
  }, [state, dispatch, key, setIsUpdated, isUpdated, setInitialRequiredFields])

  return {
    isUpdated,
    setIsUpdated,
    initialRequiredFields,
    setInitialRequiredFields,
    conditionalRequiredFields,
    shouldShowField,
    activityResult,
    visitType,
    confirmationStatus,
  }
}

export default useCommunicationTypeFields
