import Color from 'color'

const COLOR_SCALE = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9]
const PRIMARY_BRAND_COLOR = '#ffc527'
const PRIMARY_NEUTRAL_COLOR = '#272727'

const theme = {
  breakpoints: {
    sm: '480px',
    md: '768px',
    lg: '1024px',
    xl: '1350px',
  },
  colors: {
    primary: PRIMARY_BRAND_COLOR,
    error: '#fe5f55',
    success: '#8acb88',
    warning: '#ffc527',
    bg: '#f7f7f7',
    fg: PRIMARY_NEUTRAL_COLOR,
    white: '#fff',
    black: PRIMARY_NEUTRAL_COLOR,
    border: '#cbcbcb',
    neutrals: COLOR_SCALE.map(s =>
      Color(PRIMARY_NEUTRAL_COLOR).fade(s).toString()
    ),
    salesforceBlue: '#1798c1',
  },
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
  fontSizes: {
    base: '14px',
    xxs: '8px',
    xs: '10px',
    s: '12px',
    l: '16px',
    xl: '24px',
    h1: '28px',
    h2: '22px',
    h3: '20px',
    h4: '18px',
  },
  fontWeights: {
    light: 200,
    normal: 400,
    semibold: 600,
    bold: 800,
  },
  lineHeights: {
    base: '1.5',
    reset: '1',
    heading: '1.2',
    button: {
      base: '3.5',
      s: '2.5',
    },
  },
  radii: {
    base: '4px',
    s: '2px',
  },
  shadows: {
    base: '0 2px 3px rgba(0, 0, 0, 0.15)',
    l: '0 6px 12px -2px rgba(0, 0, 0, 0.25), 0 3px 7px -3px rgba(0, 0, 0, 0.3)',
    dropdown: '0 0 1px rgba(0, 0, 0, 0.25), 0 2px 18px rgba(0, 0, 0, 0.32)',
    input: '0 0 3px rgba(0, 0, 0, 0.25)',
  },
  space: {
    base: '16px',
    xs: '5px',
    s: '10px',
    l: '32px',
    xl: '60px',
    xxl: '80px',
  },
  zIndices: {
    dropdown: '2',
    modalOverlay: '10',
    modal: '20',
    alert: '30',
  },
}

export default theme
