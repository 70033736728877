import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'

import Box from 'styled/Box'
import Icon from 'components/Icon'

function BlankSlateMessage(props = {}) {
  return (
    <Box
      as={props.as}
      textAlign={props.textAlign}
      {...omit(props, ['as', 'fontSize', 'textAlign'])}
    >
      {props.icon && (
        <Icon name={props.icon} size="30" color="neutrals.4" mb="base" />
      )}
      {props.title && (
        <Box as="h2" mb="base">
          {props.title}
        </Box>
      )}
      <Box
        as="p"
        color="neutrals.4"
        fontSize={props.fontSize}
        fontStyle="italic"
      >
        {props.children}
      </Box>
    </Box>
  )
}

BlankSlateMessage.propTypes = {
  as: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  fontSize: PropTypes.string,
  icon: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  title: PropTypes.string,
}

BlankSlateMessage.defaultProps = {
  as: 'div',
  fontSize: 'l',
  textAlign: 'center',
}

export default BlankSlateMessage
