import { useReducer } from 'react'
import find from 'lodash/find'
import get from 'lodash/get'

const initialState = {
  versions: [],
}

const actionTypes = {
  insert: 'insert',
  inserted: 'inserted',
}

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.insert: {
      const versionId = get(action, 'payload.versionId')
      if (selectors.version(state, versionId)) return state
      return {
        ...state,
        versions: [...state.versions, { id: versionId, inserting: true }],
      }
    }
    case actionTypes.inserted: {
      const versionId = get(action, 'payload.versionId')
      return {
        ...state,
        versions: [
          ...state.versions.filter(v => v.id !== versionId),
          { id: versionId, inserting: false, inserted: true },
        ],
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const selectors = {
  version: (state, id) => find(state.versions, v => v.id === id),
}

function useAreaDataSelector() {
  const [state, dispatch] = useReducer(reducer, initialState)
  return { state, dispatch, actionTypes, selectors }
}

export default useAreaDataSelector
