import { useState } from 'react'

function useFormField(initialValue = '') {
  const [field, setField] = useState(initialValue)

  function handleChange(event) {
    // TODO: Protect against undefined.
    setField(event.target.value)
  }

  return [field, handleChange]
}

export default useFormField
