import { lazy } from 'react'

const AddFacility = lazy(() => import('./AddFacilityScreen'))
const BuildQuote = lazy(() => import('./BuildQuoteScreen'))
const Callback = lazy(() => import('./CallbackScreen'))
const Calendar = lazy(() => import('./CalendarScreen'))
const Closeout = lazy(() => import('./CloseoutScreen'))
const Dashboard = lazy(() => import('./DashboardScreen'))
const EditContacts = lazy(() => import('./EditContactsScreen'))
const EditEntry = lazy(() => import('./EditEntryScreen'))
const EntryManager = lazy(() => import('./EntryManagerScreen'))
const EditQuote = lazy(() => import('./EditQuoteScreen'))
const Login = lazy(() => import('./LoginScreen'))
const Logout = lazy(() => import('./LogoutScreen'))
const NewEntry = lazy(() => import('./NewEntryScreen'))
const NewFacility = lazy(() => import('./NewFacilityScreen'))
const NewQuote = lazy(() => import('./NewQuoteScreen'))
const NotFound = lazy(() => import('./NotFoundScreen'))
const SalesforceAuthenticationScreen = lazy(() =>
  import('./SalesforceAuthenticationScreen')
)
const SalesforceCallbackScreen = lazy(() =>
  import('./SalesforceCallbackScreen')
)
const SubmittedQuote = lazy(() => import('./SubmittedQuoteScreen'))

export {
  AddFacility,
  BuildQuote,
  Callback,
  Calendar,
  Closeout,
  Dashboard,
  EditContacts,
  EditEntry,
  EntryManager,
  EditQuote,
  Login,
  Logout,
  NewEntry,
  NewFacility,
  NewQuote,
  NotFound,
  SalesforceAuthenticationScreen,
  SalesforceCallbackScreen,
  SubmittedQuote,
}
