import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import LinkButton from 'styled/LinkButton'
import Modal from 'components/Modal'
import TextField from 'components/TextField'

function CommentsModal(props = {}) {
  return (
    <Modal
      title={props.title}
      variant="docked"
      footer={close => (
        <>
          <Button onClick={close} variant="primary">
            Save
          </Button>
          <LinkButton onClick={close}>Cancel</LinkButton>
        </>
      )}
    >
      <TextField
        placeholder="Enter your comments here..."
        as="textarea"
        name="comments"
        id="comments"
        rows="20"
        defaultValue={props.comments}
        onChange={props.handleComments}
      />
    </Modal>
  )
}

CommentsModal.propTypes = {
  handleComments: PropTypes.func,
  comments: PropTypes.string,
  title: PropTypes.string,
}

export default CommentsModal
