import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'
import get from 'lodash/get'

import { all, propTypes } from 'styled/lib/system'

const size =
  props =>
  ({ size }) => {
    return css`
      line-height: ${themeGet(`lineHeights.button.${size}`)};
      padding-left: ${themeGet(`space.${size}`)};
      padding-right: ${themeGet(`space.${size}`)};
    `
  }

const variant =
  props =>
  ({
    color,
    hoverBackgroundColor,
    hoverBorderColor,
    hoverColor,
    theme,
    variant,
  }) => {
    // We're doing this since Styled Components forwards the props through all
    // the way to any custom elements (like `<Link />` from React Router), so
    // this accepts either the standard, camelCase prop *OR* a `data-` attribute
    // to avoid the unknown-prop warnings.
    const _hoverBackgroundColor =
      hoverBackgroundColor || get(props, 'data-hover-background-color')
    const _hoverBorderColor =
      hoverBorderColor || get(props, 'data-hover-border-color')
    const _hoverColor = hoverColor || get(props, 'data-hover-color')

    if (variant === 'active') {
      return css`
        background-color: ${themeGet('colors.black')};
        border-color: ${themeGet('colors.black')};
        color: ${themeGet('colors.white')};

        &:active,
        &:hover,
        &:focus {
          background-color: ${themeGet('colors.black')};
          border-color: ${themeGet('colors.black')};
          color: ${themeGet('colors.white')};
        }
      `
    }

    if (variant === 'primary') {
      return css`
        background-color: ${themeGet('colors.primary')};
        color: ${themeGet('colors.black')};

        &:active,
        &:hover,
        &:focus {
          background-color: ${get(theme, `colors[${_hoverBackgroundColor}]`) ||
          themeGet('colors.black')};
          color: ${get(theme, `colors[${_hoverColor}]`) ||
          themeGet('colors.white')};
        }
      `
    }

    if (variant === 'danger') {
      return css`
        background-color: ${themeGet('colors.error')};
        color: ${themeGet('colors.white')};

        &:active,
        &:hover,
        &:focus {
          background-color: ${themeGet('colors.black')};
          color: ${themeGet('colors.white')};
        }
      `
    }

    if (variant === 'dark') {
      return css`
        background-color: transparent;
        border-color: ${themeGet('colors.border')};
        color: ${themeGet('colors.border')};

        &:active,
        &:hover,
        &:focus {
          background-color: ${themeGet('colors.white')};
          border-color: ${themeGet('colors.white')};
          color: ${themeGet('colors.black')};
        }
      `
    }

    // The default <Button>
    return css`
      background-color: transparent;
      border-color: ${themeGet('colors.border')};
      color: ${themeGet('colors.neutrals.2')};

      &:active,
      &:hover,
      &:focus {
        border-color: ${get(theme, `colors[${_hoverBorderColor}]`) ||
        themeGet('colors.fg')};
        color: ${get(theme, `colors[${_hoverColor}]`) || themeGet('colors.fg')};
      }
    `
  }

const disabled =
  props =>
  ({ disabled }) => {
    if (disabled) {
      return css`
        /* TODO: Make this better and handle hover states! */
        opacity: 0.5;
      `
    }
  }

const loading =
  props =>
  ({ loading }) => {
    if (loading) {
      return css`
        /* Grabs the <Loader /> immediately inside the <Button>. */
        > * {
          position: relative;
          top: 5px; /* FIXME: Magic number! */
        }
      `
    }
  }

const Button = styled.button`
  border: 1px solid transparent;
  border-radius: ${themeGet('radii.base')};
  cursor: pointer;
  display: inline-block;
  font-size: ${themeGet('fontSizes.s')};
  font-weight: ${themeGet('fontWeights.bold')};
  text-decoration: none;
  transition: 0.3s ease-in-out;

  ${size}
  ${variant}
  ${disabled}
  ${loading}
  ${all}
`

Button.propTypes = {
  ...propTypes,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
  ]),
  hoverBackgroundColor: PropTypes.string,
  hoverBorderColor: PropTypes.string,
  hoverColor: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['base', 's']),
  variant: PropTypes.oneOf(['primary', 'danger', 'active', 'dark']),
}

Button.defaultProps = {
  size: 'base',
}

export default Button
