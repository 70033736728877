import React from 'react'
import PropTypes from 'prop-types'

import Modal from 'components/Modal'

function DefaultModal(props = {}) {
  return (
    <Modal title={props.title} footer={props.footer}>
      {props.children}
    </Modal>
  )
}

DefaultModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  title: PropTypes.string,
}

export default DefaultModal
