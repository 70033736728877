import React, { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'

import { sendToSentry } from 'lib/utils'

const AlertStateContext = createContext()
const AlertDispatchContext = createContext()

const initialState = {
  text: null,
  type: null,
}

const actionTypes = {
  show: 'show',
  hide: 'hide',
}

function alertReducer(state, action) {
  switch (action.type) {
    case actionTypes.show: {
      return {
        type: 'info',
        ...action.payload,
      }
    }
    case actionTypes.hide: {
      return initialState
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function AlertProvider(props = {}) {
  const [state, dispatch] = useReducer(alertReducer, initialState)
  return (
    <AlertStateContext.Provider value={state}>
      <AlertDispatchContext.Provider value={dispatch}>
        {props.children}
      </AlertDispatchContext.Provider>
    </AlertStateContext.Provider>
  )
}

function useAlertState() {
  const context = useContext(AlertStateContext)
  if (context === undefined) {
    throw new Error(`useAlertState must be used within a AlertProvider`)
  }
  return context
}

function useAlertDispatch() {
  const context = useContext(AlertDispatchContext)
  if (context === undefined) {
    throw new Error(`useAlertDispatch must be used within a AlertProvider`)
  }
  return context
}

function useAlert() {
  const context = [useAlertState(), useAlertDispatch()]
  if (context === undefined) {
    throw new Error(`useAlert must be used within a AlertProvider`)
  }
  return context
}

const showSuccess = text => ({
  type: actionTypes.show,
  payload: {
    type: 'success',
    text,
  },
})

const showError = text => {
  let _text = text
  // If we're getting an unexpected error object, we need to send
  // it to Sentry for further investigation. We'll also set a
  // generice error-message string to render properly.
  if (typeof text === 'object') {
    sendToSentry({ error: text, action: 'showError' })
    _text = 'An error occurred'
  }
  return {
    type: actionTypes.show,
    payload: {
      type: 'error',
      text: _text,
    },
  }
}

const showWarning = text => ({
  type: actionTypes.show,
  payload: {
    type: 'warning',
    text,
  },
})

AlertProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
}

export {
  AlertProvider as default,
  useAlert,
  useAlertState,
  useAlertDispatch,
  actionTypes,
  showSuccess,
  showError,
  showWarning,
}
