import * as Sentry from '@sentry/browser'

function sendToSentry({ prefix = 'Manual error report', error, action }) {
  Sentry.configureScope(scope => {
    Object.keys(error).forEach(key => {
      scope.setExtra(key, error[key])
    })
  })
  Sentry.captureMessage(`${prefix}: ${action}`)
}

export default sendToSentry
