import React from 'react'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { LIST_ENTRIES_QUERY } from 'lib/queries'
import DefaultProvider from 'providers/DefaultProvider'

function EntriesProvider(props = {}) {
  return (
    <DefaultProvider
      query={LIST_ENTRIES_QUERY}
      ErrorComponent={props.ErrorComponent}
      LoadingComponent={props.LoadingComponent}
      {...pick(props, ['passThrough', 'variables'])}
    >
      {result => props.children(result)}
    </DefaultProvider>
  )
}

EntriesProvider.propTypes = {
  ...omit(DefaultProvider.propTypes, ['query']),
}

export default EntriesProvider
