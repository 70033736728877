import React from 'react'
import ReactDOM from 'react-dom'

import { ServiceWorkerProvider } from 'serviceWorkerProvider'
import initializeSentry from 'config/sentryClient'
import * as serviceWorker from './serviceWorker'
import App from 'components/App'

initializeSentry()

ReactDOM.render(
  <ServiceWorkerProvider>
    <App />
  </ServiceWorkerProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
