import gql from 'graphql-tag'

export const CREATE_REQUEST_MUTATION = gql`
  mutation CreateRequest(
    $opportunity: ID!
    $comments: String!
    $salesPoC: ID!
    $status: String!
    $engineeredApp: ID
    $customerContact: ID
    $workOrder: Boolean
  ) {
    createRequest(
      opportunity: $opportunity
      comments: $comments
      salesPoC: $salesPoC
      status: $status
      engineeredApp: $engineeredApp
      customerContact: $customerContact
      workOrder: $workOrder
    ) {
      message
      data {
        id
        success
        errors
      }
      error {
        code
        text
        status
        message
      }
    }
  }
`
