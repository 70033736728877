import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'

import { all, propTypes } from 'styled/lib/system'

import Image from 'components/Image'

const BORDER_SIZE = '2px'
const NUMBER_SIZE = '20px'

const ImageGrid = styled.div`
  ${all}
`

const ImageGridImage = styled(Image)`
  transition: opacity 0.3s ease-in;
  ${props => props.onClick && 'cursor: pointer;'}

  ${all}
`

const ImageGridTitle = styled.h3`
  font-size: ${themeGet('fontSizes.base')};
  font-weight: ${themeGet('fontWeights.semibold')};
  margin-bottom: ${themeGet('space.xs')};
  margin-top: ${themeGet('space.s')};
`

const ImageGridDescription = styled.p`
  color: ${themeGet('colors.neutrals.4')};
  font-size: ${themeGet('fontSizes.s')};
  margin-bottom: ${themeGet('space.base')};
`

const number = props => {
  if (props.number) {
    return `
    &::after {
      background-color: ${themeGet('colors.primary')(props)};
      color: ${themeGet('colors.fg')(props)};
      content: '${props.number.toString()}';
      display: block;
      font-size: ${themeGet('fontSizes.s')(props)};
      font-weight: bold;
      height: ${NUMBER_SIZE};
      left: 0;
      line-height: ${NUMBER_SIZE};
      position: absolute;
      text-align: center;
      top: 0;
      width: ${NUMBER_SIZE};
      }`
  }
}

const ImageGridOverlay = styled.div`
  border: ${BORDER_SIZE} solid transparent;
  display: block;
  margin-bottom: ${themeGet('space.base')};
  position: relative;
  transition: all 0.2s ease-in-out;

  ${props =>
    props.active && `border-color: ${themeGet('colors.primary')(props)};`}
  ${props =>
    props.active && `background: ${themeGet('colors.primary')(props)};`}
  ${props => props.disabled && `opacity: 0.2;`}

  &:focus {
    outline: none;
  }

  &:active,
  &:focus,
  &:hover {
    background: ${themeGet('colors.primary')};
    border-color: ${themeGet('colors.primary')};
  }

  ${props => number(props)}
`

ImageGrid.propTypes = {
  ...propTypes,
}

ImageGridImage.propTypes = {
  onClick: PropTypes.func,
  ...propTypes,
}

ImageGridOverlay.propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  ...propTypes,
}

ImageGrid.Image = ImageGridImage
ImageGrid.Title = ImageGridTitle
ImageGrid.Description = ImageGridDescription
ImageGrid.Overlay = ImageGridOverlay

export default ImageGrid
