import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import { getNewEntryUrl } from 'lib/utils'
import { useModalDispatch, hideModal } from 'providers/ModalProvider'
import Box from 'styled/Box'
import Button from 'components/Button'
import Icon from 'components/Icon'
import LinkButton from 'styled/LinkButton'
import Modal from 'components/Modal'
import ViewInSalesforceLink from 'components/ViewInSalesforceLink'

function EventModal(props = {}) {
  const modalDispatch = useModalDispatch()
  const eventId = props.event.id
  const whatId = props.event.whatId
  const name = get(props, 'event.what.Name')
  const parseEventDescription = desc => desc.replace(/\r/g, '').split('\n')

  function handlePrimaryAction(event) {
    event.preventDefault()
    props.history.push(getNewEntryUrl({ name, eventId, whatId }))
    modalDispatch(hideModal())
  }

  return (
    <Modal
      title="Event"
      footer={close =>
        props.startEntryFromEvent && (
          <Fragment>
            <Button onClick={handlePrimaryAction} variant="primary">
              Start entry from this event
            </Button>
            <LinkButton onClick={close}>Close</LinkButton>
          </Fragment>
        )
      }
    >
      <Fragment>
        <Box as="h3">{props.event.subject}</Box>
        <Box as="p" fontWeight="semibold" mb="base">
          <Icon
            name="calendar"
            size="16"
            color="primary"
            mr="xs"
            position="relative"
            top="-2px"
          />
          {format(props.event.startDateTime, 'dddd MMMM D')} |{' '}
          {format(props.event.startDateTime, 'h:mma')}-
          {format(props.event.endDateTime, 'h:mma')}
        </Box>
        {props.event.description
          ? parseEventDescription(props.event.description).map((line, idx) => (
              <Box key={idx} as="p" mb="base">
                {line}
              </Box>
            ))
          : null}
        <ViewInSalesforceLink id={eventId} />
      </Fragment>
    </Modal>
  )
}

EventModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  event: PropTypes.object,
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  startEntryFromEvent: PropTypes.bool,
}

EventModal.defaultProps = {
  startEntryFromEvent: true,
}

export default withRouter(EventModal)
