import { useState } from 'react'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'

function useLayoutSections(props) {
  let [index, setIndex] = useState(0)
  const sectionPaths = props.sections.map(s => s.path)
  const lastSection = index === sectionPaths.length - 1
  const isCurrentSection = url => url === window.location.pathname

  const handleSectionClick = path => event => {
    event.preventDefault()
    setIndex(findIndex(props.sections, s => s.path === path))
    get(props, 'history').push(path)
  }

  function handleContinueClick(event) {
    event.preventDefault()
    index = index + 1
    if (!lastSection) {
      setIndex(index)
      get(props, 'history').push(sectionPaths[index])
    } else {
      props.onSubmit()
    }
  }

  return {
    index,
    setIndex,
    lastSection,
    isCurrentSection,
    handleContinueClick,
    handleSectionClick,
  }
}

export default useLayoutSections
